$compName: 'on-board-credit-label';

.#{$compName} {
    display: flex;
    position: relative;
    background: $primaryColor1;

    &--out-left {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translate(-50%, 100%);
        width: max-content;
        &:after {
            content: '';
            position: absolute;
            border-bottom: 7px solid $primaryColor1;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            left: 50%;
            margin-left: -3.5px;
            right: inherit;
            top: -7px;
        }
        @media only screen and (min-width: $screen-md) {
            left: 0;
            margin-left: -12px;
            transform: translate(-100%, -50%);
            white-space: nowrap;
            top: 50%;
            &:after {
                border-bottom: 7px solid transparent;
                border-left: 7px solid $primaryColor1;
                border-top: 7px solid transparent;
                border-right: unset;
                margin-top: -6px;
                margin-left: unset;
                left:unset;
                right: -6px;
                top: 50%;
            }
            .#{$compName} {
                &__right {
                    padding-right: 100px;
                }
            }
        }
    }

    &--out-bottom {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translate(-50%, 100%);
        width: 90%;

        &:after {
            content: '';
            position: absolute;
            border-bottom: 7px solid $primaryColor1;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            left: 50%;
            margin-left: -3.5px;
            right: inherit;
            top: -7px;
        }
    }

    &--in-bottom {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        .#{$compName} {
            &__left {
                position: relative;
                z-index: 2;
                padding: 0px 21px 0 11px;
                clip-path: polygon(
                    0 0,
                    calc(100% - 10px) 0,
                    100% 50%,
                    calc(100% - 10px) 100%,
                    0 100%,
                    0 0
                );
            }
            &__right {
                text-align: center;
                &__text {
                    font-size: 1.166rem;
                    font-weight: 700;
                }
            }
        }
    }

    &__left {
        background-color: $discountColor;
        color: $white;
        padding: 3px 6px;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        font-weight: 400;

        &__title {
            display: block;
            font-size: 12px;
            line-height: 100%;
            font-weight: 700;
            text-transform: lowercase;
            font-size: 11px;
        }

        &__text {
            display: block;
            margin-top: 3px;
            font-size: 20px;
            line-height: 100%;
        }
    }

    &__right {
        color: $white;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        font-size: 12px;
        line-height: 1.2rem;
        padding: 6px 10px;
        position: relative;
        flex: 1;

        &__title {
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
