.load-more-reviews-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 5px;
    margin-bottom: 25px;
    &__link {
        height: 12px;
        color: $primaryColor3;
        display: inline-block;
        cursor: pointer;
        font-weight: bold;
        padding-right: 20px;
        position: relative;
        &:before {
            position: absolute;
            right: 0;
            top: 4px;
        }
    }
}
