.reviews-list {
    margin-top: 20px;
    position: relative;
    @media only screen and (min-width: $screen-lg) {
        margin-top: 30px;
    }
    &__heading-row {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        flex-direction: column;
        @media only screen and (min-width: $screen-lg) {
            flex-direction: row;
        }
    }
    &__heading {
        flex: 1;
        font-weight: bold;
        font-size: $fontSize18;
        margin-bottom: 15px;
    }
    &__sorting {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        @media only screen and (min-width: $screen-lg) {
            justify-content: flex-end;
        }
    }
    &__sorting-header {
        align-self: center;
        margin-right: 15px;
        font-weight: bold;
    }
    &__sorting-wrapper {
        align-self: center;
        width: 200px;
    }
}
