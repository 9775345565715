.labelledArrow {
    position: relative;
    display: flex;
    align-items: center;

    .left,
    .right {
        content: '';
        // z-index: 9;
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
    }
}
