@import './Inclusives/Inclusives';
@import './CruiseStage/CruiseStage';

.cruisePageContainer {
    background-color: $grayF8;

    @media (min-width: $screen-md-min) {
        .hideDesktopAndUp {
            display: none !important;
        }
    }
    @media (min-width: 0) and (max-width: $screen-sm-max) {
        .hideMobile {
            display: none !important;
        }
    }

    @media (max-width: $screen-sm-max) {
        margin: 0;
        background-color: $white;
    }

    @media (min-width: $screen-md-min) {
        padding-bottom: 60px;
    }
}

.cruisePageSection {
    background: $white;
    padding: 15px 0;
    max-width: 1150px;
    margin: 0 auto;

    @media (min-width: $screen-md-min) {
        padding: 0;
    }

    // Overrides
    @media (min-width: $screen-md-min) {
        .inclusivesContainer {
            background: $white;
            svg {
                width: 40px;
                height: 40px;
            }
        }

        .cabinSectionContainer {
            background: $white;
        }

        .reviews {
            background: $white;
            padding: 15px;
        }
    }
}

.cruisePageRequestFormBtn {
    margin: 15px;
    &.disabled {
        > div {
            pointer-events: none;
            opacity: 0.5;
        }
    }
}

.cruisePageBreadcrumbs {
    background: $grayF8;
    max-width: 1150px;
    margin: 0 auto;
    @media screen and (min-width: 321px) and (max-width: 767px) {
        padding: 0 15px;
        background: $white;
    }
}

.lineSeparator {
    border-top: 1px solid $borderColor;
    margin: 0 15px;

    @media screen and (min-width: $screen-desktop) {
        display: none;
    }
}

.cruiseHeader {
    width: 100%;
    background-color: #f8f8f8;
    .cruise-stage {
        background-color: #f8f8f8;
        padding: 15px 0;
        max-width: 1150px;
        margin: 0 auto;
    }
}

.makeRequestSectionTitle,
.inclusivesWrapper {
    @media screen and (min-width: $screen-desktop) {
        display: none;
    }
}

@media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
    .collapsibleSection {
        padding: 10px 25px !important;
    }
}

@media (min-width: $screen-md-min) {
    .cruisePageSectionHeader {
        color: $white;
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        padding: 12px 24px;
        margin-top: 25px;
        display: inline-block;
        background-color: $primaryColor1;
        border-radius: 6px 6px 0 0;
    }

    .cruisePageSectionContainer {
        display: block;
        color: $gray2A;
        background: $white;
        font-family: Arial;
        padding: 10px 25px;
        border: 2px solid $grayF0;
        border-radius: 0 0 6px 6px;

        > div {
            margin-bottom: 20px;
        }
    }
}
