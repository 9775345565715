.requestFormContainer {
    position: relative;

    @media screen and (max-width: $screen-tablet) {
        display: none;
    }
    &__inner-wrapper {
        max-width: 1150px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }
    &__disclaimer {
        display: block;
        clear: both;
        padding: 8.5px 0;
        font-size: 1.166rem;
        line-height: 1.66rem;
    }

    #requestFormContainer__price {
        #requestFormContainer__price__discount {
            display: block;
            font-size: 17px;
            text-align: center;
        }
        #requestFormContainer__price__container {
            display: flex;
            align-items: center;
            justify-content: center;
            #requestFormContainer__price__discount {
                color: $white;
                font-size: 15px;
                font-weight: 700;
                padding: 4px 13px;
                background: $discountColor;
                clip-path: polygon(
                    0 0,
                    calc(100% - 8px) 0,
                    100% 50%,
                    calc(100% - 8px) 100%,
                    0 100%,
                    6px 50%,
                    0 0
                );
            }
            #requestFormContainer__price__container__main-price {
                font-size: 37px;
                font-weight: 700;
                margin: 0 24px 0 15px;
                .dual_currency {
                    font-size: 12px;
                    color: $price91;
                    margin-left: 10px;
                }
                &.has_dicount {
                    color: $discountColor;
                }
            }
            #requestFormContainer__price__container__label {
                text-align: left;
                font-size: 1.2rem;
            }
        }
        #requestFormContainer__price__container__hotel-service-charges {
            margin-top: 10px;
            font-size: 1.1rem;
            text-align: center;
        }
        #requestFormContainer__price__container__guarantee-offer {
            font-size: 15px;
            margin-top: 17px;
            text-align: center;
        }
        #requestFormContainer__price__soldout {
            #requestFormContainer__price__soldout-container {
                padding: 15px;
                margin: 15px 15px 0;
                color: $sold-out-text;
                background: $sold-out-background;
                * {
                    text-align: center;
                }
                #requestFormContainer__price__soldout-container__icon {
                    path {
                        fill: $sold-out-background;
                        &:first-of-type,
                        &:last-of-type {
                            fill: $sold-out-text;
                        }
                    }
                }
                #requestFormContainer__price__soldout-container__title {
                    font-size: 18px;
                    margin-bottom: 14px;
                }
                #requestFormContainer__price__soldout-container__paragraph {
                    font-size: 16px;
                    margin-bottom: 11px;
                }
            }
            #requestFormContainer__price__soldout-contacts {
                padding: 58px 30px 30px;
                #requestFormContainer__price__soldout-contacts__title {
                    margin: 0;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 1.9rem;
                }
                #requestFormContainer__price__soldout-contacts__container {
                    display: flex;
                    margin-top: 40px;
                    flex-wrap: wrap;
                    @media only screen and (min-width: $screen-md) {
                        justify-content: center;
                        flex-wrap: nowrap;
                    }
                    .requestFormContainer__price__soldout-contacts__container__link {
                        color: $black;
                        text-decoration: none;
                        @media only screen and (min-width: $screen-md) {
                            flex: 0 0 40%;
                        }
                        &:first-of-type {
                            @media only screen and (min-width: $screen-md) {
                                flex: 0 0 46%;
                                .requestFormContainer__price__soldout-contacts__container__unit {
                                    margin-right: 40px;
                                }
                            }
                        }
                        .requestFormContainer__price__soldout-contacts__container__unit {
                            display: flex;
                            margin-top: 10px;
                            @media only screen and (min-width: $screen-md) {
                                margin-top: unset;
                            }
                            svg {
                                min-width: 25px;
                            }
                            path,
                            rect {
                                fill: $fontColor;
                            }
                            #envelope {
                                fill: white;
                            }
                            .requestFormContainer__price__soldout-contacts__container__contact {
                                margin-left: 10px;
                                .requestFormContainer__price__soldout-contacts__container__contact-title {
                                    font-size: 14.5px;
                                    line-height: 20px;
                                    margin: 0;
                                }
                                .requestFormContainer__price__soldout-contacts__container__contact-content {
                                    color: $grey77;
                                    font-size: 15px;
                                    margin-top: 6px;
                                    &.email {
                                        color: $acqa;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #requestFormContainer__price__soldout-actions {
                margin: 0 15px;
                text-align: center;
                padding: 46px 15px 30px 15px;
                border-top: 1px solid $grayDA;
                margin-top: 20px;
                a {
                    text-decoration: none;
                }
            }
        }
    }
    #requestFormContainer__actions {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        @media only screen and (min-width: $screen-lg) {
            flex-direction: row;
            align-items: flex-start;
        }
        .requestFormContainer__actions__unit {
            a {
                text-decoration: none;
            }
            width: 100%;
            padding: 0 15px;
            max-width: 300px;
            @media only screen and (min-width: $screen-lg) {
                width: 50%;
                max-width: unset;
            }
            &:nth-child(2) {
                margin-top: 40px;
                @media only screen and (min-width: $screen-lg) {
                    margin-top: unset;
                    border-left: 1px solid $borderColor;
                }
            }
            .requestFormContainer__actions__unit__list {
                width: 250px;
                font-size: 13px;
                margin-top: 28px;
                line-height: 1.2;
                padding-inline-start: 23px;
                li {
                    margin-top: 5px;
                    position: relative;
                    &:first-of-type {
                        margin-top: 0px;
                    }
                    svg {
                        top: 0;
                        left: -20px;
                        position: absolute;
                    }
                }
            }
            .on-board-credit-label--out-bottom {
                position: relative;
                transform: translate(-50%, 10px);
            }
        }
    }
    #requestFormContainer__ssl-label {
        display: flex;
        margin-top: 33px;
        align-items: center;
        padding-bottom: 30px;
        justify-content: center;
        img {
            width: 42px;
        }
        p {
            margin: 0;
            font-size: 13px;
            margin-left: 6px;
        }
    }

    &__text {
        margin-top: 30px;
        text-align: center;
        font-size: 12px;
        color: $softBlack70;
    }

    &__disclaimer-text {
        margin-top: 30px;
        font-size: 12px;
    }
}

.requestFormContainer {
    font-size: $fontSize14;

    &__section {
        position: relative;
    }

    &__price {
        &__on-request {
            font-size: 32px;
            font-weight: 700;
        }
    }

    .autoAppearanceSelect {
        position: relative;
        font-size: $fontSize14;
        border: 1px solid $grayCC;
        background-color: transparent;
        cursor: pointer;
        text-align: left;
        appearance: auto;
        -webkit-appearance: auto;
        --webkit-appearance: auto;
    }

    &__inner-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__header {
        color: $white;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        padding: 12px 24px;
        display: inline-block;
        background-color: $primaryColor1;
        border-radius: 6px 6px 0 0;

        &--full {
            display: block;
            border-radius: 0;
        }
    }

    &__section-label {
        font-size: $fontSize16;
        font-weight: 700;
        line-height: 26px;
        color: $gray33;
        border-bottom: 1px solid $borderColor;
        margin-bottom: 24px;
    }

    &__box {
        border: 2px solid $grayF0;
        padding: 30px 22px;
        background-color: $white;
        position: relative;

        > [class^='requestFormContainer__'] {
            margin-bottom: 48px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__cabineType {
        padding-top: 20px;

        &__select {
            display: flex;
        }
        &__guarantee {
            margin: 16px 0; 
        }
    }

    &__finalStepWrapper {
        display: flex;

        &__leftSide {
            flex: 1;
        }

        &__rightSide {
            flex: 2;
            border-left: 1px solid $lightGray;
        }

        .submitError {
            color: $validatorError;
            padding: 0;
        }
    }

    #requestFormContainer__actions {
        padding: 0 32px;

        .requestFormContainer {
            &__actions {
                &__unit {
                    .requestForm__actionButtonIcon {
                        position: absolute;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    flex: 1;
                    max-width: 50%;
                    border-left: 0 !important;
                    padding: 0 !important;
                    width: auto !important;

                    + .requestFormContainer__actions__unit {
                        margin-left: 26px;

                        @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
                            margin-left: 0;
                        }
                    }

                    button,
                    a {
                        max-width: 100%;
                        margin: 0;
                    }
                }
            }
        }
    }

    #requestFormContainer__price {
        #requestFormContainer__price__container {
            flex-wrap: wrap;
        }

        #requestFormContainer__price__container__guarantee-offer {
            font-size: $fontSize16;
            margin-top: 26px;
        }

        #requestFormContainer__price__container__label {
            padding-left: 6px;
        }
    }

    #requestFormContainer__ssl-label {
        padding-bottom: 0;
    }

    .callExpert-card {
        margin-top: 42px;
        margin-right: 20px;
    }
}

.travelersSelectContainer {
    display: flex;

    label {
        font-weight: normal;
        margin-right: 10px;
    }

    > div {
        display: flex;
        align-items: center;
    }

    &__adult {
        margin-right: 60px;
    }
}

.travelersSelectWrapper {
    display: flex;
    align-items: center;

    &__label {
        align-items: center;

        label {
            font-size: $fontSize14;
            font-weight: 400;
            line-height: 16px;
            display: flex;
            flex-direction: column;
            margin-right: 6px;
        }
    }

    &__select {
        position: relative;

        svg {
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 0;
        }

        select {
            min-width: 120px;
            min-height: 45px;
            padding: 0 34px 0 54px;
        }
    }
}

.kidsAgePickerWrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    &__calendarRow {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-left: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .dayInputPickerWrapper {
        margin-right: 16px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        .DayPicker-NavBar {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 6px 0 0 0;
        }

        .DayPicker-NavButton {
            position: relative;
            font-size: 20px;
            margin: 0;
            top: 0;
            right: 0;
        }

        .DayPicker-Caption {
            min-width: 280px;
            select {
                @extend .autoAppearanceSelect;
                margin-right: 5px;
                padding: 0 10px;
                height: 35px;
            }
        }

        .DayPicker-RequestFormCaption {
            padding-right: 56px;
            display: flex;
            justify-content: space-around;
        }

        .DayPicker-Day {
            border-radius: 0;
        }

        .DayPickerInput-Overlay {
            z-index: 5;
        }

        .DayPickerInput {
            > input {
                min-height: 45px;
                border: 1px solid $grayCC;
                padding-left: 35px;
                &.error {
                    border-color: $validatorError;
                }
            }

            + svg {
                z-index: 1;
                margin-right: -25px;
                fill: $midGray;
                pointer-events: none;
            }
        }
    }

    &__errorMessage {
        color: $validatorError;
        margin-left: 3px;
        margin-top: -10px;
        margin-bottom: 16px;
    }
}

.datesSelectWrapper {
    display: flex;
}

.cabinsSelectorContainer {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.cabinsSelectorContainer .cabinItem:first-child {
    margin-left: 0;
}

.cabinsSelectorContainer .cabinItem:last-child {
    margin-right: 0;
}

.cabinItem {
    cursor: pointer;
    position: relative;
    margin: 0 14px;
    background: #fff;
    max-width: 240px;
    height: 200px;
    width: 100%;

    @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
        width: 200px;
        height: 180px;
    }

    &--active,
    &:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);

        .cabinItem__triangle {
            visibility: visible;
        }
    }

    &__info {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.0001) 33.73%,
            rgba(0, 0, 1, 0.00916146) 33.74%,
            #00233b 95.18%
        );
        height: 65%;
        width: 100%;
        padding: 6px;
        color: #fff;
        z-index: 1;
    }

    &__img {
        width: 100%;
        height: 100%;
    }

    &__type {
        font-size: $fontSize24;
    }

    &__price {
        font-size: $fontSize16;
    }

    &__discount {
        position: absolute;
        top: 10px;
        left: 0;

        &__text {
            height: 25px;
            padding: 4px;
            position: relative;
            display: block;
            font-size: $fontSize11;
            font-weight: bold;
            line-height: 1.6;
            color: #fff;
            background-color: $pink;

            &::after {
                content: ' ';
                position: absolute;
                right: -10px;
                top: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12.5px 0 12.5px 10px;
                border-color: transparent transparent transparent #e01a59;
            }
        }
    }

    &__triangle {
        visibility: hidden;
        position: absolute;
        z-index: 0;
        bottom: -35px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 120px 0 120px;
        border-color: $primaryColor2 transparent transparent transparent;
        filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.75));

        @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
            border-width: 35px 100px 0 100px;
        }
    }
}

.selectedCabinOverview {
    margin: 24px 0 40px;
    display: flex;

    &__info {
        display: flex;
        flex-direction: column;
    }

    &__imgWrapper {
        position: relative;
        flex: 0 0 210px;
        width: 210px;
        height: 160px;
        background-size: cover;
        background-color: $gray;
        margin-right: 22px;
    }

    &__enlargeBtn {
        position: absolute;
        bottom: 10px;
        right: 5px;
        background: transparent;
        border: none;
        cursor: pointer;

        &:hover {
            svg path {
                fill: $pinkLight;
            }
        }
    }

    &__description {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;

        li {
            display: flex;
            width: calc(50% - 15px);
            margin-right: 15px;
            align-items: center;
            margin-bottom: 14px;
            font-size: $fontSize14;

            svg {
                flex: 0 0 16px;
                fill: $gray33;
                margin-right: 10px;
            }
        }
    }

    &__advantagesWrapper {
        font-size: $fontSize14;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &__advantages {
        position: relative;
        overflow: hidden;

        &:not(&--is-open) {
            &::before {
                content: '';
                height: 100px;
                width: 100%;
                display: block;
                position: absolute;
                background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, #fff 100%);
            }
        }

        ul > li {
            font-size: $fontSize14;
            line-height: 2;

            svg {
                margin-right: 6px;
            }
        }
    }
}

.reqFormSummaryContainer {
    padding-right: 10px;

    &__destination {
        h3 {
            font-weight: bold;
        }

        margin-bottom: 38px;
        &__nights {
            font-size: $fontSize14;
            color: $fontColor;
            font-weight: 700;
        }
    }

    &__briefSummary {
        list-style-type: none;
        margin-bottom: 36px;

        li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            svg {
                margin-right: 14px;
            }
            span {
                font-size: $fontSize14;
                line-height: 24px;
            }
        }
    }
}

.personalDataContainer {
    flex: 1;
}

.personalData__form {
    padding: 0 34px;

    .form-row {
        select {
            @extend .autoAppearanceSelect;
            z-index: 1;
            min-height: 45px;
            padding: 14px;

            &.isEmpty {
                color: rgba(46, 52, 56, 0.5);
            }
        }

        &__cell {
            vertical-align: middle;
        }
        &__cell__label {
            &-holder__label {
                font-weight: 400;
            }
        }
    }

    input:not([type='radio']) {
        min-height: 45px;
    }

    &__genderInputContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 10px;

        label {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            margin: 0 12px;
            font-size: $fontSize14;
            font-weight: 400;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            input {
                margin: 0;
                height: 15px;
                width: 15px;
                margin-right: 10px;
            }
        }
    }

    .personalData__form__input__ccp {
        display: flex;

        &__prefix {
            display: flex;
            align-items: center;
            font-size: 18px;
            border: 1px solid $grayCC;
            color: $form-control-color;
            padding: 0 8px;
            border-right: none;
            background-color: $grayDD;
            line-height: 1;
        }

        &__country-code {
            min-width: 80px;
            margin-right: 10px;
            display: flex;

            input {
                padding: 0;
                text-align: center;
            }
        }
    }

    &__cb-newsletter {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: 12px;
        line-height: 19px;

        label {
            margin-bottom: 0;
        }

        &__cb-holder {
            margin-right: 8px;

            input {
                min-height: 18px;
                min-width: 18px;
                border-radius: 0;
                margin: 0;
            }
        }
    }

    &__messageArea {
        min-height: 80px;
    }
}
