@import 'vendor/index';

.rc-slider-rail {
    height: 9px;
    border-radius: 15px;
    background: $gray;
}

.rc-slider-track {
    height: 9px;
    border-radius: 15px;
    background: $darkAcqa;
}

.rc-slider-step {
    height: 9px;
}

.rc-slider-handle {
    width: 32px;
    height: 32px;
    border: 6px solid $white;
    background: $darkAcqa;
    margin-left: -16px;
    margin-top: -12px;
    box-shadow: 0 0 0 1px $midGray;
}
