.price-with-discount-container {
    padding-left: 10px;
    text-align: center;
    flex: 0 0 auto;

    .price-text {
        font-size: 25px;
        font-weight: bold;
    }

    .price-PP {
        margin-left: 5px;

        letter-spacing: normal;
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }
}

.price-with-discount {
    line-height: 1;
    text-align: center;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    margin-left: 10px;
    &__from.no-discount {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    &__price {
        font-size: 25px;
        font-weight: bold;
    }
    &__align {
        grid-column-start: 1;
        grid-column-end: 3;
        margin-top: 3px;
    }
    &__per-person {
        margin-left: 6px;
    }

    @media (min-width: $screen-sm) {
        max-width: none;
        display: flex;
        justify-content: center;
        width: 100%;
        position: relative;
        &__from {
            padding: 0 5px 3px;
            align-self: flex-end;
        }
        &__price {
            grid-column-start: auto;
            grid-column-end: auto;
            text-align: left;
        }
        &__align {
            margin-right: 30px;
        }
        &__discount {
            position: absolute;
            right: 7px;
        }
    }
}

.price-on-request {
    font-weight: bold;
    font-size: 18px;
    margin-left: 10px;
}

.priceStartFrom {
    font-family: Arial;
    font-size: 14px;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    margin-right: 5px;
}

.priceDiscountTag {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;

    margin-bottom: 4px;
}
