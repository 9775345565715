.thank-you {
    &__heading {
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 110px;
        @media only screen and (max-width: 1279px) {
            background: #07496f !important;
        }

        @media only screen and (min-width: 1280px) {
            height: 177px;
            background-size: cover;
        }
    }

    &__heading-banner {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100%;
    }
    &__heading-data {
        z-index: 1;
        display: flex;
        text-align: center;
        position: relative;
        flex-direction: column;
        align-items: center;
    }
    &__heading-overlay {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;

        @media only screen and (min-width: 1280px) {
            display: block;
            background-color: rgba(11, 78, 128, 0.7);
        }
    }
    &--headline {
        color: white;
        font-size: 25px;
        font-weight: bold;
        margin: 0;
    }

    &--subline {
        color: white;
        font-size: 15px;
    }
}
