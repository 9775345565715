/* ---------  BLINKING CURSOR Cross Browser Animation Definition    --------- */

.blinking-cursor {
    font-weight: 100;
    font-size: 18px;
    color: black;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}

@keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-moz-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-webkit-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-ms-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@-o-keyframes blink {
    from, to {
        color: transparent;
    }
    50% {
        color: black;
    }
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@-moz-keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@-ms-keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
@-o-keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@keyframes fadein { 
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { 
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { 
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-ms-keyframes fadein { 
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { 
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
