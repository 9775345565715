.shipPageContainer {
    display: flex;
    flex-direction: column;

    h2 {
        text-align: center;
        font-size: 25px;
        margin: 40px 0 15px;
        color: #2a2a2a;
        font-weight: bold;
        font-family: Arial;
    }

    .collapsibleSection {
        padding: 0;
        margin: 0;
    }
    .icon {
        fill: transparent;
        width: 20px;
        height: 20px;
        fill: #006598;
    }
    .iconTransparent {
        fill: transparent;
        stroke: #006598;
        stroke-width: 20px;
    }

    .mapboxContainer {
        height: 490px;
        width: 1140px;
        margin: -15px;
    }

    @media (max-width: $screen-md-min) {
        width: 100%;
        .dynamicFormWrapper {
            margin-left: -15px;
            margin-right: -15px;
        }
        .recommendation-section {
            background-color: transparent;
            margin: 0 -15px;
        }
    }
    @media (min-width: $screen-md-min) {
        padding-bottom: 20px;
    }
    @media (min-width: $screen-sm-min) {
        > div {
            background-color: white;
            padding: 15px;
            margin-top: 30px;
        }
        .recommendation-section {
            background-color: transparent;
        }
    }
}

.shipFacts {
    display: flex;
    width: 100%;
    background-color: $lighterGray;

    ul {
        line-height: 1.5;
        font-size: 14px;
        margin: 15px;
    }

    li {
        display: flex;
        align-items: center;
        font-weight: bold;
    }
    .factName {
        margin-right: 10px;
    }
    .factText {
        display: inline-flex;
        align-items: center;
        margin: 0 10px;
    }
    .factValue {
        font-size: 18px;
    }

    svg {
        fill: $primaryColor1;
    }

    @media (min-width: $screen-md-min) {
        background-color: white;
        margin-bottom: 15px;
        ul {
            display: flex;
            flex: 1 0 auto;
            justify-content: space-around;
        }
        li {
            margin-right: 10px;
        }
        .icon {
            height: 50px;
            width: 50px;
        }
        .factText {
            display: inline-flex;
            align-items: start;
            flex-direction: column;
            .factValue {
                font-size: 20px;
                font-weight: bold;
            }
        }
        svg {
            height: 50px;
            width: 50px;
            stroke-width: 0;
            stroke: $primaryColor1;
        }
    }
}

.headline.h-desktop {
    margin: -15px -15px 10px -15px;
    background-color: #f0f0f0;
    padding: 40px 15px 15px 15px;
}

.ship-marker {
    width: 330px;
}

.ship-marker svg {
    width: 41px;
    height: 41px;
    stroke-width: 0;
    stroke: white;
    fill: #00233b;
}

.ship-marker__current-port {
    font-size: 19px;
}

.ship-marker__next-port span {
    font-size: 15px;
    color: #f2f2f2;
}

.ship-marker__anchor-icon {
    font-size: 20px !important;
    color: white !important;
}

.ship-marker__box:before {
    border-right: 11px solid #00233bcc;
    border-bottom: 15px solid transparent;
    border-top: 15px solid transparent;
    margin-top: 3px;
}
