.overview-price-component {
    min-height: 203px;
    display: block;
    width: 100%;

    &__features {
        display: flex;
        width: 100%;
        clear: both;
        flex-flow: column wrap;
    }

    &__feature {
        color: $softBlack;
        fill: $softBlue;
        font-size: 1.166rem;
        font-weight: 600;
        display: flex;
        & > * {
            flex-shrink: 0;
        }
        &:not(:first-child) {
            margin-top: 8px;
        }
        span {
            margin: 0 0 0 8px;
            max-width: 100%;
        }
    }

    &__cabin-options {
        border-bottom: 2px solid $grayF5;
        float: left;
        margin: 25px 0 15px;
        padding-bottom: 10px;
        width: 100%;
    }
    &__cabin-option {
        cursor: pointer;
    }
    &__cabin-option:nth-of-type(odd) {
        background-color: $grayF8;
    }

    &__cabin-option__title,
    &__cabin-option__extra,
    &__cabin-option__value {
        padding: 5px;
        line-height: 1.428571429;
        vertical-align: top;
        box-sizing: border-box;
        font-size: 1.166rem;
    }

    &__cabin-option__title {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    &__cabin-option__extra {
        text-align: right;

        [class*='per-person-price-table'] {
            text-decoration: line-through;
            width: 100%;
        }
    }

    &__cabin-option__value {
        text-align: right;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    &__cabin-option__dual-currency-old-price {
        display: block;
        clear: both;
        text-align: right;
        white-space: nowrap;
        font-size: 1.166rem;
        color: $price91;
    }

    &__slected-price {
        float: right;
        display: flex;
        flex-flow: column wrap;
        align-items: flex-end;
        min-height: 83px;
    }
    &__table-holder {
        min-height: 100px;
        margin-bottom: 15px;
    }
    &__table-holder .loading-indicator {
        position: absolute;
        margin: auto;
        font-size: unset;
        top: 50%;
    }
    &__placeholder {
        position: absolute;
        background-color: white;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 100%;
        clear: both;
        height: 200px;

        &__loading {
            width: 30px;
            height: 30px;
            margin-right: 20px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            &.full-width {
                width: 100%;
                margin: 20px 0;
            }
        }
        &_please-wait {
            font-size: 1.5rem;
            color: $primaryColor1;
        }
    }
}
