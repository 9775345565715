.cabin-description {
    padding: 15px;
    position: relative;
    font-size: 14px;
    min-height: 215px;

    &__title {
        font-weight: 700;
    }

    &__list {
        padding-left: 25px;

        &__item {
            &:before {
                content: '•';
                padding-right: 12px;
            }
        }
    }

    &__image {
        float: right;
    }

    &__item {
        margin: 0;
    }
}
