@import '../../../assets/styles/slick.scss';

.product-gallery {
    position: relative;
    min-height: 205px;

    & *:hover,
    & *:focus {
        outline: none;
    }

    &__image-holder {
        &.isMobile {
            height: auto;
            position: relative;
        }
    }

    &__thumbnail-holder {
        height: 91px;
        position: relative;
    }

    &__thumbnail__play-btn-holder {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        cursor: pointer;
        z-index: 5;
    }

    &__thumbnail__play-btn {
        fill: $white;
        stroke: $white;
        filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.6));
        border: 4px solid $white;
        border-radius: 50%;
    }

    &__image,
    &__thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__pagination {
        min-height: 91px;
        margin-top: 20px;
        .slick-slider {
            min-height: 91px;
            display: flex;
            align-items: center;
            .slick-list {
                flex: 1;
            }
        }
        .slick-slide {
            padding: 0 4px;
            cursor: pointer;
        }
    }

    &__slider {
        overflow: hidden;
        .slick-slide:not(.slick-active) {
            pointer-events: none;
        }
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-prev,
    .slick-next {
        font-size: 2.8rem;
        color: $primaryColor3;
        cursor: pointer;
        &.slick-disabled:before {
            opacity: 0.25;
        }
        &:hover {
            color: $primaryColor2;
        }
    }

    .slick-prev {
        @extend .icon-SliderArrowLeft;
        margin-right: 30px;
    }

    .slick-next {
        @extend .icon-SliderArrowRight;
        margin-left: 30px;
    }

    .slick-loading .slick-list {
        background: $white center center no-repeat;
    }

    &__cover-image {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        cursor: pointer;
    }

    &__play-button {
        position: absolute;
        height: 1.5em;
        text-align: center;
        font: 48px/1.5 sans-serif;
        color: white;
        text-shadow: 0 0 0.5em black;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
    }
}
