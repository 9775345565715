.ship-overview-page {
    @media only screen and (min-width: $screen-lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .loading-indicator {
        margin-top: 75px;
        margin-bottom: 75px;
    }

    background: #f0f0f0;

    br {
        content: '';
        display: block;
        margin: 2px;
    }

    &--container {
        margin: 0px 15px;
        max-width: 1170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;

        @media screen and (min-width: $screen-sm-min) {
            order: 3;
        }

        > .collapsibleSection {
            margin: 0 -15px;
            > .collapsibleSectionContent {
                margin: 0 -30px 0 -14px;
            }
        }

        #offers {
            margin: 0 0 24px !important;

            & > div.slider > div {
                max-width: 100% !important;
                margin-bottom: 15px !important;

                a {
                    height: 100% !important;
                }
            }

            & > div.slider > div > a > div:nth-child(2) > div:nth-child(2) {
                b {
                    display: none;
                }
            }

            @media screen and (min-width: $screen-sm-min) {
                & > div.slider > div {
                    max-width: calc(100% - 30px);
                }
                & > div.slider > div:nth-child(n) > a > div:nth-child(2) > div:nth-child(2) {
                    min-height: 120px;
                }
            }
        }

        .breadcrumbs {
            @media screen and (min-width: $screen-sm-min) {
                padding-left: 14px !important;
                border-top: 1px solid transparent !important;
            }
            @media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
                margin-top: -20px !important;
            }
        }

        .collapsible {
            position: relative;
            margin: 10px 0 30px;
            font-size: 15px;
            line-height: 20px;

            @media screen and (min-width: $screen-sm-min) {
                margin: 30px 0 50px;
            }

            @media screen and (min-width: $screen-lg-min) {
                background: $white;
                padding: 15px;
                margin: 30px 0;
            }

            h2 {
                display: none;

                @media screen and (min-width: $screen-sm-min) {
                    display: block;
                    font-size: 18px;
                }

                @media screen and (min-width: 1024px) {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }

            #show,
            #hide {
                position: absolute;
                bottom: -1em;
                z-index: 100;
                text-align: center;
                color: $acqa;

                @media screen and (min-width: $screen-lg-min) {
                    display: none;
                }
            }
        }

        .loadmore {
            display: flex;
            justify-content: center;
            margin: 36px 0;
        }

        .collapsible-text {
            h2 {
                font-size: 1.1rem !important;
            }
        }
        .furtherInfo {
            margin-bottom: 60px;
        }
    }
    &--sortbar {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.166rem;
        margin: 12px;
        justify-content: flex-end;
        align-items: center;

        &--label {
            margin-right: 10px;
        }

        &--dropdown {
            display: flex;
            justify-content: flex-end;
            position: relative;

            &--arrow {
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid rgb(211, 211, 211);
                margin-top: -5px;
                margin-right: 4px;
                position: absolute;
                right: 4px;
                top: 50%;
            }

            select {
                background: $white;
                border: none;
                border-radius: 0px;
                width: 100%;
                cursor: pointer;
                padding: 10px 20px 10px 8px;
                font-size: 14px;
                min-width: 140px;

                @media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
                    margin-right: 3px !important;
                }
            }
        }
    }
}
