.shipIconLocation {
    @media (min-width: $screen-md-min) {
        margin-left: 15px;
    }
}

.shipIconLocationIcon {
    fill: rgb(0, 53, 93);
    fill-rule: evenodd;
    opacity: 0.596;
    width: 40px;
    @media (min-width: $screen-sm-min) {
        width: 60px;
    }
}

.shipLocation {
    margin: 5px 3px;
    min-width: fit-content;
    @media (min-width: $screen-sm-min) {
        display: flex;
        flex-direction: column;
        transform: translateY(-10px);
    }
}

.shipInfo {
    margin-left: 0;
    margin-top: 10px;
    @media (min-width: $screen-sm-min) {
        margin-left: 50px;
        margin-top: 0;
    }
    @media (min-width: $screen-md-min) {
        margin-left: 100px;
        margin-top: 0;
    }
}

.positionWrapper {
    display: flex;
    width: 100%;
    position: relative;
    margin: 5px 0;
    flex-wrap: wrap;

    @media (min-width: $screen-sm-min) {
        margin: 20px 25px -20px 0px;
    }
    @media (min-width: $screen-md-min) {
        margin: 44px 25px 5px 0px;
    }
}

.positionInfoContentH3 {
    font-size: 20;
    font-weight: bold;
    display: block;
    margin-bottom: 5;
    @media (min-width: $screen-sm-min) {
        line-height: 1.3;
        display: inline;
    }
}

.positionInfoContentLat {
    font-size: 14px;
    margin-right: 10px;
    @media (min-width: $screen-sm-min) {
        margin-right: 0;
        font-size: 15px;
    }
}

.positionInfoContentLong {
    font-size: 14px;
    @media (min-width: $screen-sm-min) {
        font-size: 15px;
    }
}
