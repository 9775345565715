.collapsibleSection {
    border-bottom: solid 1px $gray;
    position: relative;
    padding: 10px 25px;
}

.collapsibleSectionHeader {
    display: flex;
    align-items: center;

    .titleSection {
        flex: 1 1 auto;
        strong,
        b {
            margin-right: 7px;
        }
    }

    .collapsibleSectionIcon {
        fill: $softBlack50;
        transition: transform 0.15s ease-in-out;
        padding: 10px 5px;
        display: flex;
        align-items: center;
        flex: 0 0 auto;
    }

    .rotate {
        transform: rotate(-180deg);
    }
}
.collapsibleSectionTitle {
    margin: 0 0 0 10px;
    font-size: 16px;
    font-weight: normal;
}

.collapsibleSectionInfo {
    fill: $softBlue;
    display: flex;
    align-items: center;
    svg {
        margin-right: 10px;
    }
}

.collapsibleSectionContent {
    > div {
        padding: 0 5px;
        margin: 20px 0;
        transition: height 0.35s ease-in-out;
    }
}

.collapseText {
    margin: 10px 0px 20px;
    line-height: 20px;
    overflow: hidden;
    font-size: 15px;

    .collapseTextChild {
        overflow: hidden;
        position: relative;
        transition: height 0.35s ease-in-out, margin 0.15s ease-in-out;
        a {
            color: $gray2A;
        }
    }
    a {
        color: $acqa;
    }
}
.collapseTextBtn {
    color: $acqa;
    display: inline-flex;
    align-items: center;
    margin-top: 10px;
}
