.sails-cabins-selection {
    $col-width-4: calc((100% / 4) - 15px);
    $col-width-2: calc((100% / 2) - 15px);
    $col-width-1: 100%;

    position: relative;
    display: inline-block;
    width: 100%;
    clear: both;

    &__inner-wrapper {
        max-width: 1150px;
        margin: 0 auto;
    }

    &__legend {
        color: $white;
        font-size: 14px;
        padding: 5px 8px;
        margin-top: 25px;
        display: inline-block;
        background-color: $primaryColor1;
    }

    &__box {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        min-height: 318px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $grayDA;
        padding: 21px 7.5px 7.5px 7.5px;

        &__head {
            display: block;
            clear: both;
            width: 100%;
            &__title,
            &__sail-selector {
                display: inline-block;
            }

            &__title {
                font-size: 1.166rem;
                display: inline-block;
                max-width: 100%;
                margin-bottom: 5px;
                font-weight: 700;
                position: relative;
                min-height: 1px;
                margin: 0 7.5px 0 15px;
                width: $col-width-1;
                @media (min-width: $screen-xs) {
                    width: $col-width-2;
                }
                @media (min-width: $screen-sm) {
                    width: $col-width-4;
                }
            }
        }
        &__list {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            clear: both;
            margin-top: 14px;
            .cabin-selection-card,
            .cabin-selection-card--selected {
                margin: 7.5px;
                box-sizing: border-box;
                width: $col-width-1;
                @media (min-width: $screen-xs) {
                    width: $col-width-2;
                }
                @media (min-width: $screen-sm) {
                    width: $col-width-4;
                }
            }
        }
    }
}

.cabin-selection-card,
.cabin-selection-card--selected {
    display: flex;
    cursor: pointer;
    position: relative;
    flex-direction: column;
    text-decoration: none;
    &--selected,
    &:hover {
        text-decoration: none;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
    }
    &__head {
        display: block;
        &__title {
            position: absolute;
            width: 100%;
            z-index: 0;
            left: 0;
            top: 0;
            background-color: rgba(0, 35, 59, 0.6);
            color: $white;
            font-weight: 700;
            padding: 7px 10px;
            text-align: center;
            font-size: 1.333rem;
            margin: 0;
            z-index: 1;
        }
        &__img-holder {
            overflow: hidden;
            height: 0;
            padding-top: 61.37%;
            width: 100%;
            position: relative;
            &__img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: fill;
            }
        }
    }
    &__price {
        flex: 1;
        width: 100%;
        display: flex;
        font-weight: 700;
        padding: 15px 24px;
        position: relative;
        align-items: center;
        box-sizing: border-box;
        border: 1px solid $grayDA;
        &.whitHover:hover {
            box-shadow: 0 0 3px 3px $primaryColor2;
        }
        > div{
            flex: 1;
            text-align: center;
        }
        &__default-currency {
            color: $primaryColor2;
            font-size: 1.1rem;
            text-align: center;
            width: 100%;
        }
        &__dual-currency {
            color: $price91;
            text-align: center;
            font-size: 1rem;
        }
        &__arrow-icon {
            position: absolute;
            right: 10px;
            top: 50%;
            margin-top: -8px;
        }
    }
}
