.decksContainer {
    display: flex;

    flex-direction: column;
    justify-content: center;
    min-height: 150px;

    padding: 0 15px;

    background: $white;

    h4 {
        font-size: 15px;
    }

    select {
        padding: 10px 15px;
        width: 100%;

        color: $black;
        background-color: transparent;
        border: 1px solid $gray;
        min-height: 44px;
        font-size: 15px;
    }

    .decksContainer__selectContainer {
        .Select {
            width: 100%;
            cursor: pointer;
            max-width: 400px;
            margin: 0;
            .option,
            .option-selected {
                color: black;
                cursor: pointer;
                font-size: 15px;
            }
            .option {
                padding: 10px 15px;
                &:hover {
                    color: $white;
                    background: $primaryColor1;
                }
            }
            .option-selected {
                padding: 0 15px;
                display: flex;
                align-items: center;
            }
        }
    }

    .imageContainer {
        display: flex;
        justify-content: center;
    }

    img {
        width: auto;
        height: auto;
        max-width: 100%;

        margin: 15px 0;
    }

    @media (min-width: $screen-sm-min) {
        min-height: 250px;

        select {
            max-width: 400px;
        }

        .imageContainer {
            margin-top: auto;
        }
    }
}
