.newsLetterPage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    text-align: center;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 580px;
    }
    .newsletterForm {
        @media screen and (min-width: 768px) {
            width: 500px;
            margin-top: 100px;
        }
    }
    .checkMarkWrapper {
        @media screen and (min-width: 768px) {
            width: 100%;
            align-self: flex-end;
            margin-top: auto;
        }
        &--list {
            @media screen and (min-width: 768px) {
                display: flex;
                margin: 0 auto;
                max-width: 700px;
            }
        }
    }
    .html-with-links {
        @media screen and (min-width: 768px) {
            text-align: left !important; //override the inline style for newsletter form.
        }
    }
}
.NewsletterFooterWrapper {
    margin: 30px 10px;
    background: white;
    text-align: center;
    @media screen and (min-width: 768px) {
        margin: 50px auto;
        max-width: 670px;
    }
}
