body {
    font-family: Arial, Helvetica, sans-serif;
}

@font-face {
    font-family: dreamicons;
    src: local('Pacifico Regular');
    src: url(#{$baseFontPath}/dreamIcons_24a2a46b2cb8b8e6ecc56e5ee9eec3fa.eot);
    src: url(#{$baseFontPath}/dreamIcons_24a2a46b2cb8b8e6ecc56e5ee9eec3fa.eot)
            format('embedded-opentype'),
        url(#{$baseFontPath}/dreamIcons_24a2a46b2cb8b8e6ecc56e5ee9eec3fa.woff) format('woff'),
        url(#{$baseFontPath}/dreamIcons_24a2a46b2cb8b8e6ecc56e5ee9eec3fa.ttf) format('truetype'),
        url(#{$baseFontPath}/dreamIcons_24a2a46b2cb8b8e6ecc56e5ee9eec3fa.svg) format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: dreamlines;
    src: local('Pacifico Regular');
    src: url(#{$baseFontPath}/dreamlines_896a1c8e4c4f3d4c6ec59924d6eaf72d.eot);
    src: url(#{$baseFontPath}/dreamlines_896a1c8e4c4f3d4c6ec59924d6eaf72d.eot)
            format('embedded-opentype'),
        url(#{$baseFontPath}/dreamlines_896a1c8e4c4f3d4c6ec59924d6eaf72d.woff) format('woff'),
        url(#{$baseFontPath}/dreamlines_896a1c8e4c4f3d4c6ec59924d6eaf72d.ttf) format('truetype'),
        url(#{$baseFontPath}/dreamlines_896a1c8e4c4f3d4c6ec59924d6eaf72d.svg) format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.icon-handset-rounded:before,
.icHeart:before,
.icon-exchange:before,
.icon-magnifyingGlass:before,
.icon-dreamlinesPackage-expert:before,
.icon-shipv2:before,
.icon-winner:before,
.icon-facebook:before,
.icon-youtube:before,
.icon-twitter:before,
.icon-star:before,
.icon-anchor:before,
.icon-people:before,
.icon-marker:before,
.icon-info:before,
.icon-globe:before,
.icon-SliderArrowLeft:before,
.icon-SliderArrowRight:before,
.icon-ratings:before,
.icon-reloading:before,
.icon-arrowLeft:before,
.icon-arrowRight:before,
.icon-globe:before,
.icon-calendar:before,
.icon-checkMark:before,
.icon-decks:before,
.icon-cross:before,
.icon-marker:before,
.icon-instagram:before {
    font-family: dreamlines;
    display: inline-block;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smooth: antialiased;
}

.icon-checkMark:before {
    content: '\f116';
}

.icon-calendar:before {
    content: '\f115';
}

.icon-globe:before {
    content: '\f136';
}

.icon-arrowLeft:before {
    content: '\f108';
}
.icon-arrowRight:before {
    content: '\f109';
}

.icon-reloading:before {
    content: '\f167';
}

.icon-ratings:before {
    content: '\f166';
}

.icon-anchor:before {
    content: '\f105';
}

.icon-star:before {
    content: '\f173';
}

.icon-info:before {
    content: '\f13f';
}

.icon-cross:before {
    content: '\f11b';
}

.icon-SliderArrowLeft:before {
    content: '\f100';
}
.icon-SliderArrowRight:before {
    content: '\f101';
}
.icHeart:before {
    font-family: dreamicons;
    content: '\F105';
}
.icon-globe:before {
    content: '\f136';
}
.icon-handset-rounded:before {
    content: '\F13B';
}
.icon-magnifyingGlass:before {
    content: '\F149';
}
.icon-marker:before {
    content: '\f14c';
}
.icon-people:before {
    content: '\f15f';
}
.icon-dreamlinesPackage-expert:before {
    content: '\F121';
}
.icon-shipv2:before {
    content: '\F16F';
}
.icon-decks:before {
    content: '\f11d';
}
.icon-winner:before {
    content: '\F186';
}
.icon-facebook:before {
    content: '\F12D';
}
.icon-youtube:before {
    content: '\F188';
}
.icon-twitter:before {
    content: '\F17A';
}
.icon-instagram:before {
    content: '\F142';
}
.icon-exchange:before {
    content: '\F12C';
}

.icon-facebook:hover,
.icon-youtube:hover,
.icon-twitter:hover,
.icon-instagram:hover {
    text-decoration: none;
}

// Font size vars
$standardFontSize: 12px;
$fontSize11: 0.91rem;
$fontSize13: 1.1rem;
$fontSize14: 1.166rem;
$fontSize15: 1.25rem;
$fontSize16: 1.333rem;
$fontSize17: 1.4rem;
$fontSize18: 1.5rem;
$fontSize20: 1.66rem;
$fontSize22: 1.833rem;
$fontSize23: 1.9rem;
$fontSize24: 2rem;
$fontSize25: 2.0833rem;
$fontSize26: 2.167rem;
$fontSize28: 2.4rem;
$fontSize30: 2.5rem;
$fontSize32: 2.666rem;
$fontSize34: 2.91rem;
$fontSize40: 3.4rem;
$fontSize48: 4rem;
$fontSize52: 4.8rem;
