.harboursSection,
.furtherInfoSection,
.advisorSection,
.ugcSection {
    .headline {
        font-family: Arial;
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2a2a2a;
        margin-top: 40px;
        margin-bottom: 15px;
        display: block;
    }
}

.harboursSection {
    display: unset;
    text-align: center;
    @media only screen and (max-width: $screen-lg) {
        display: none;
    }

    .mapboxgl-canvas {
        left: 0;
    }

    .recommendation-section {
        margin: 20px -11px -11px -11px !important;
    }

    .teaserContentWrapper {
        flex-direction: column;
        height: calc(100% - 250px);
        position: relative;
    }

    .teaserContentInfo {
        border-right: none !important;
        justify-content: unset !important;
        text-align: left;
        align-self: flex-start;

        @media #{$ie-only} {
            display: none !important;
        }

        .overflow-ellipsis--multi-line {
            -webkit-line-clamp: unset !important;
            height: auto !important;
        }
    }

    .teaserPriceCTA {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid #e0e0e0;
        align-self: end;

        @media #{$ie-only} {
            // We're hiding the description on IE
            margin-top: 0;
            border-top: none;
        }

        .priceDiscountTag {
            position: absolute !important;
            right: 0;
        }

        .priceDiscountStartFrom {
            display: none;
        }

        .priceStartFrom {
            display: inline;
            padding-right: 10px !important;
            font-weight: normal;
        }
    }

    .teaserPriceCTAWithoutDescription {
        padding-top: 7px;
        margin-top: 0;
        border-top: 0;
    }

    .recommendationSectionCTA {
        display: none;
    }
}

.furtherInfoSection .furtherInfoSectionContent,
.ugcSection > div {
    background: white;
    padding: 20px;
}

.furtherInfoSection h2 {
    margin-top: 20px;
}

.ugcSection {
    margin-bottom: 40px;
}
.destinationPage {
    .slider-item > a {
        height: 100%;
        background-color: white;
    }
}

@media only screen and (max-width: $screen-xs-max) {
    .destinationPage {
        & > .container {
            padding: 0;
        }
    }
}
