.tooltip-wrapper-wp {
    position: relative;
    @media print {
        display: none;
    }
}

.tooltip-wrapper-wp_icon {
    min-width: 30px;
    min-height: 20px;
    padding-left: 5px;
    cursor: pointer;
}

.tooltip-wrapper-wp_svg {
    fill: $white;
    stroke: $white;
    stroke-width: 10px;
    vertical-align: middle;
}

.tooltip-wrapper-wp_arrow {
    display: none;
    position: absolute;
    z-index: 9;
    border-top-color: transparent;
    border-right-color: transparent;
}

.tooltip-wrapper-wp_arrow {
    top: calc(100% + 1px);
    left: 5px;
    width: 20px;
    height: 20px;
    border: 10px solid $grayF7;
    transform: rotate(-215deg) skew(22deg);
    box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.2);

    @media (min-width: $screen-sm) {
        top: calc(100% + 5px);
        left: 5px;
        width: 10px;
        height: 10px;
        border: 7px solid $grayF7;
        transform: rotate(-222deg) skew(7deg);
        box-shadow: -2px 2px 2px 0px rgba(65, 63, 63, 0.3);
    }
}

.tooltip-wrapper-wp_body {
    display: none;
    position: absolute;

    min-width: 200px;
    max-height: 200px;

    top: 30px;

    font-size: 12px;
    line-height: 18px;
    z-index: 8;

    color: $gray33;
    background: $white;

    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
    line-height: normal;

    border-radius: 4px;
    border: 1px solid $grayAA;
    padding: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

.tooltip-wrapper-wp:hover .tooltip-wrapper-wp_body,
.tooltip-wrapper-wp:hover .tooltip-wrapper-wp_arrow {
    display: block;
}

.tooltip-wrapper-wp_title {
    font-size: 12px;
    font-weight: bold;
    color: $gray33;

    margin-bottom: 10px;

    border-radius: 4px;

    display: block;
    text-align: left;
    background-color: $grayF7;
    padding: 10px 12px;
}

.tooltip-wrapper-wp_text {
    padding: 0 12px 10px;
}

.tooltip-wrapper-wp_icon {
    margin: 0 0 0 4px;
    padding: 0;
    min-width: unset;
    display: flex;
    align-items: center;
}
