.newsletter-form {
    &--wrapper {
        display: inline-block;
        width: 90%;
        max-width: 575px;
        margin: 20px 0;
        padding: 25px 10px;
        background: rgba(255, 255, 255, 0.65);
        position: relative;

        @media only screen and (min-width: $screen-lg) {
            padding: 50px 40px 60px;
            margin: 60px 0;
        }
    }

    &--container {
        margin: 0px 15px 0px;
    }

    &--submit-error {
        font-size: 16px;
        margin: 10px;
    }

    &--heading {
        margin: 0px 0px 40px 0px;
        font-weight: bold;
        line-height: 1.2em;
        font-size: 22px;
        color: $primaryColor2;

        @media only screen and (min-width: $screen-lg) {
            margin: 0px 0px 65px 0px;
            font-size: 36px;
        }
    }

    &--element {
        margin: 10px 0px 0px;
        position: relative;
        font-size: 15px;

        &--small-margin {
            margin: 12px 0px 0px;
        }
    }

    &--input {
        text-align: center;
        width: 100%;
        padding: 14px 11px;
        border: 1px solid $borderColorLightGray;
        border-radius: 4px;
        &--valid {
            border-color: $borderColorLightGray;
        }
        &--invalid {
            border-color: $error;
        }
        &--alig-left {
            text-align: left;
        }
    }

    &--error-msg {
        color: $error;
        font-size: 12px;
        margin: 5px 0;
        text-align: left;
    }

    &--thankyou-msg {
        font-size: 20px;
        font-weight: bold;
        margin: 10px 0;
        color: $primaryColor2;
        line-height: 32px;
    }

    &--thankyou-sub-text {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 20px;

        &__bold {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 16px;
        }
    }

    &--retry-button-wrapper {
        margin: 25px 10px;
        max-width: none;
        @media only screen and (min-width: $screen-sm) {
            margin: 40px auto;
        }
    }

    &--form-subtext {
        margin: 10px 0 0;
        font-size: 13px;
        line-height: 1;
        text-align: left;
    }
    &--sub-text {
        font-size: 12px;
        margin-top: 16px;
        @media only screen and (min-width: $screen-sm) {
            font-size: 14px;
        }
    }
}
