.onboardCredit {
    &__wrapper {
        display: none;
        color: $white;
        width: 184px;
        height: 184px;
        font-size: 2.5em;
        border-radius: 50%;
        box-shadow: -3px 3px 10px 0px rgba(25, 25, 25, 0.6);
        text-align: center;
        background: $primaryColor2;
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -15px;
        margin-left: 25px;
        transform: rotate(-20deg) translate(-50%, -50%);
        transform-origin: left top;
        @media only screen and (min-width: $screen-lg) {
            display: block;
            width: 135px;
            height: 135px;
            margin-left: 0px;
        }
    }

    &--topLeft {
        @media only screen and (min-width: $screen-sm) {
            width: 120px;
            height: 120px;
            top: -60px;
            left: auto;
            right: 40px;
        }
        @media only screen and (min-width: $screen-lg) {
            transform: rotate(-20deg) translate(-100%, 0%);
            margin-left: 15px;
            left: 0;
            right: 100%;
            top: 0;
            margin-top: -10px;
            font-size: 2.5em;
            width: 170px;
            height: 170px;
        }
        display: block;
        font-size: 13px;
        transform: rotate(-20deg) translate(0%, 0%);
        transform-origin: center center;
        background: $newsletterOBC;
        width: 80px;
        height: 80px;
        top: -30px;
        left: -10px;
        right: auto;
        color: $white;
        border-radius: 50%;
        box-shadow: -3px 3px 10px 0px rgba(25, 25, 25, 0.6);
        text-align: center;
        position: absolute;
    }

    &__container {
        display: block;
        top: 50%;
        position: relative;
        transform: translate(0%, -50%);
    }

    &__amount {
        font-size: 2.1em;
        font-weight: bold;
        line-height: 1em;
        @media only screen and (min-width: $screen-sm) {
            font-size: 2.1em;
        }
    }

    &__label {
        font-size: 0.6rem;
        @media only screen and (min-width: $screen-sm) {
            font-size: 1.4rem;
        }
    }
}
