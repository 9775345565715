.review-comment {
    display: flex;
    padding-bottom: 10px;
    &__icon {
        font-size: 30px;
        &--cabin {
            font-size: 25px;
        }
    }
    &__content {
        margin-top: 3px;
        margin-left: 15px;
        overflow: hidden;
    }
    &__heading {
        font-weight: bold;
        font-size: $fontSize14;
        margin-bottom: 5px;
    }
}
