.resetButton {
    display: none;
    text-align: center;
    cursor: pointer;

    &::before {
        content: '\f167';
        font-family: dreamlines;
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;

        margin-right: 5px;
    }

    font-family: Arial;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;

    &.blue {
        color: #06aaee;
    }

    &.darkblue {
        color: #006598;
    }
}

.search-result-item {
    margin: 25px 0;
}

.searchResultItemOverlay {
    padding: 10px;

    &__headline {
        display: flex;
        justify-content: left;
        font-size: 24px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.06;
        letter-spacing: normal;
        color: #333333;
        margin-top: 5px;
    }
    &__title {
        display: flex;
        justify-content: left;
        font-size: 14px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.06;
        letter-spacing: normal;
        color: #333333;
        margin-top: 5px;
    }
    &__info {
        padding: 15px 0;
        font-size: 14px;
        color: #7d7d7d;
        display: flex;
        justify-content: left;
        text-align: left;
    }
    &__infoArea {
        display: flex;
        justify-content: left;
        font-weight: 600;
        font-size: 14px;
        color: #333333;
        flex-flow: row wrap;
        width: 100%;
    }
    &__list {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        padding: 15px 0;
    }
    &__list__links {
        display: flex;
        flex-flow: row wrap-reverse;
        width: 100%;
        padding: 15px 0;
    }
    &__listItem {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        justify-content: left;
        width: 100%;
        line-height: 3;
    }
    &__link {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 16px;
        color: #0099ff;
    }
    &__item__title {
        margin-left: 5px;
    }
    &__button__wrapper {
        width: 100%;
    }
}

// Tablet only
@media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
    .searchPageResultWrapper {
        position: relative;
        float: left;
        width: 100% !important;
        padding: 0 !important;
        margin-top: 0 !important;

        .searchResultsWrapper {
            padding: 15px !important;
        }
    }
}

@media only screen and (min-width: $screen-lg) {
    .searchPageSubmitButton {
        position: relative !important;
        background: transparent !important;
        z-index: auto !important;
        box-shadow: none !important;

        > div {
            padding: 11px 5px !important;
        }
    }
}

@media only screen and (max-width: $screen-xs-max) {
    .searchPage {
        width: 100%;
    }
}

@media only screen and (max-width: $screen-sm-max) {
    .searchResultItemLabel {
        position: absolute;
    }
}

@media only screen and (min-width: $screen-sm) {
    .searchPage {
        .filterSectionTitle {
            font-size: 20px;
        }

        > .headline {
            margin-top: 27px;
            padding-bottom: 9px;

            display: flex;
            align-items: center;
            flex-wrap: wrap;
            h1 {
                margin: 0 0 0 15px;
                line-height: 1;
            }
            > .headlineWrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                p {
                    margin: 0 0 0 15px !important;
                }
            }
        }

        .breadcrumbs {
            margin-top: 20px;
        }

        .searchPageFilterWrapper {
            width: 33%;
            float: left;

            .faqPage--contact {
                width: 100% !important;
                box-shadow: none !important;
            }
        }

        .searchPageResultWrapper {
            position: relative;
            float: left;
            width: 67%;
            padding-left: 15px;
            margin-top: 12px;
        }

        .searchResultControls {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .resetButton {
        display: block;
    }

    .searchResultsWrapper {
        padding: 0 0 30px 0 !important;
    }

    .hr {
        background: #f0f0f0;
        margin-right: 10px;
        height: 1px;
        width: 100%;
    }

    .searchResultItemOverlay {
        padding: 10px;

        &__listItem {
            width: 50%;
        }

        &__link {
            justify-content: left;
            width: 65%;
            line-height: 3.5;
        }

        &__button__wrapper {
            position: absolute;
            right: 30px;
            max-width: 175px;
        }
    }
}

.home-page-container {
    .searchMask {
        height: auto;
        @media (min-width: $screen-sm) {
            width: 50%;
            max-width: 470px;
            padding: 24px;
            background: $searchMaskTransparent;
        }
        @media (min-width: $screen-md) {
            max-width: 570px;
        }
        .calendarWrapper {
            position: relative;
            &.open-startDate,
            &.open-endDate {
                &::after {
                    @media (min-width: $screen-sm) {
                        display: none;
                    }
                }
            }
            input {
                cursor: pointer;
            }
            @media (min-width: $screen-sm) {
                width: 100%;
                flex-direction: column;
                & > div {
                    width: 100%;
                    margin-bottom: 10px;
                    > div {
                        background-size: 15.39px 15.6px;
                        background-position: calc(100% - 9px) center;
                        border-radius: 4px;
                    }
                    input {
                        height: 38px;
                        font-size: 13px;
                        padding-left: 8px;
                        outline: 0;
                    }
                }
            }
            @media (min-width: $screen-md) {
                flex-direction: row;
                & > div {
                    flex: 1;
                    margin-bottom: 10px;
                    &:first-of-type {
                        margin-right: 5px;
                    }
                    &:last-of-type {
                        margin-left: 5px;
                    }
                    input {
                        padding-left: 8px;
                        height: 38px;
                        font-size: 13px;
                    }
                }
            }
            .DayPickerInput-Overlay {
                @media (min-width: $screen-sm) {
                    width: 100%;
                }
            }
            .calendarEndDateWrapper {
                .DayPickerInput {
                    @media (min-width: $screen-sm) {
                        &:before {
                            background: transparent;
                        }
                    }
                }
                .DayPickerInput-Overlay {
                    margin-left: 0;
                }
            }
            .calendarXIcon {
                @media (min-width: $screen-sm) {
                    width: 14px;
                    height: 14px;
                    transform: translate(-35px, calc(-50% + 1px));
                }
            }
        }
        & > div:first-of-type {
            & > div {
                cursor: pointer;
            }
            @media (min-width: $screen-md) {
                z-index: 3;
                width: 100%;
                display: flex;
                position: relative;
                & > div {
                    flex: 1;
                    &:first-of-type {
                        margin-right: 15px;
                    }
                    &:last-of-type {
                        margin-left: 15px;
                    }
                }
            }
            .Select-arrow {
                @media (min-width: $screen-sm) {
                    border-width: 5px 4px 0;
                    border-color: $arrow-color transparent transparent;
                    &.Select-arrow-up {
                        border-width: 0 4px 5px !important;
                        border-color: transparent transparent $arrow-color;
                    }
                }
            }
        }
        h1 {
            @media (min-width: $screen-sm) {
                margin-top: 0 !important;
                font-size: 27px !important;
                text-align: left !important;
                margin-bottom: 30px !important;
            }
        }
        .searchPageSubmitButton {
            & > div {
                font-size: 16px !important;
                border-radius: 0 !important;
                padding: 12px 37px 12px 11px !important;
            }
        }
        #searchForm {
            position: relative;
        }
    }
}

.harboursSection {
    display: unset;
    text-align: center;
    @media only screen and (max-width: $screen-lg) {
        display: none;
    }
}
