.deck-plan__img {
    min-height: 200px;
    max-height: 500px;
    max-width: 100%;
}

.decks {
    &__ship-name {
        font-size: 15px;
        font-weight: 700;
        font-style: italic;
    }
}
