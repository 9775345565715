.breadcrumbs {
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    font-size: 15px;
    padding: 8px 0px;
    line-height: 21px;
    background-color: transparent;
    @media (min-width: $screen-sm-min) {
        font-size: 14px;
        line-height: 20px;
        padding: 8px 15px;
        background-color: transparent;
        &.greyBackground {
            background: $grayF8;
        }
    }
    &.noLateralPadding {
        padding-left: 0;
        padding-right: 0;
    }
    &.darkBlue {
        li {
            color: $indigo;
            a {
                color: $indigo;
                &:hover {
                    color: $fontColor;
                }
            }
            &:last-child {
                div {
                    color: $fontColor;
                }
            }
        }
    }
}
