.faqPage {
    &--header {
        color: white;
        text-align: center;
        padding: 15px 15px 20px;
        font-size: 15px;
        position: relative;

        @media only screen and (min-width: 768px) {
            padding: 21px 15px;
        }
        @media only screen and (max-width: 1279px) {
            background: #07496f !important; //override the inline style of desktop
        }
        @media only screen and (min-width: 1280px) {
            background-repeat: no-repeat;
            height: 177px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-size: cover;
        }
        &-overlay {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            @media only screen and (min-width: 1280px) {
                display: block;
                background-color: rgba(11, 78, 128, 0.7);
            }
        }
        &-icon {
            background-repeat: no-repeat;
            display: none;
            width: 108px;
            height: 96px;
            opacity: 0.57;
            margin-right: 30px;

            @media only screen and (min-width: 1280px) {
                display: inline-block;
            }
        }
        &-data {
            @media only screen and (min-width: 1280px) {
                z-index: 1;
                display: flex;
                align-items: center;
                text-align: left;
                margin-left: -55px;
            }
        }
        &-content {
            max-width: 700px;
        }
        h2 {
            font-size: 24px;
            margin: 0px 0px 15px 0px;
            @media only screen and (min-width: 768px) and (max-width: 1279px) {
                margin-bottom: 8px;
            }
        }
    }
    &--container {
        @media only screen and (min-width: 1280px) {
            display: flex;
            width: 1170px;
            justify-items: center;
            margin: 0 auto;
        }
        > div:nth-child(2) {
            padding: 15px;

            @media only screen and (min-width: 1280px) {
                padding: 30px 0 30px 30px;
                width: calc(100% - 356px);
            }
        }
    }
    &--answers {
        a {
            text-decoration: underline;
            color: $acqa;
        }
        .row.faq {
            display: flex;
            flex-wrap: wrap;
            .col-xs-6.col-sm-3 {
                float: none;
            }
            a img {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
    &--contact {
        width: 356px;
        height: fit-content;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        display: none;
        margin-top: 30px;
        overflow: hidden;
        border-radius: 6px;

        @media only screen and (min-width: 1280px) {
            display: block;
        }
        &-data {
            padding: 20px;
        }
        &-item {
            display: flex;
            padding: 30px 0;
            border-bottom: 1px solid #c3c3c3;
            align-items: center;

            &-title,
            &-phNo {
                font-family: Arial;
                font-size: 15px;
                font-weight: bold;
                color: #2a2a2a;
                display: block;
                line-height: 22px;
            }
            &:first-child {
                padding-top: 15px;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
        &-icon-container {
            border-radius: 50%;
            border: 1px solid black;
            font-size: 0;
            margin-right: 9px;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-text-container {
            width: calc(100% - 60px);
        }
    }
}
