.rating-value {
    color: $primaryColor1;
    font-size: $fontSize24;
    &--white {
        color: $white;
        font-size: $fontSize32;
    }
    &__rating {
        font-weight: bold;
    }
}
