.reviews {
    clear: both;
    padding-top: 35px;
    position: relative;
    width: 100%;
    display: block !important;

    @media only screen and (min-width: $screen-lg) {
        padding-top: 15px;
    }

    &__row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @media only screen and (min-width: $screen-lg) {
            flex-direction: row;
        }
    }
    &__column {
        width: 100%;
        margin-bottom: 20px;
        @media only screen and (min-width: $screen-lg) {
            width: 50%;
            margin-bottom: 0px;
        }
        &--right {
            @media only screen and (min-width: $screen-lg) {
                padding-left: 30px;
            }
        }
    }
    &__empty {
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 15px;
        font-weight: bold;
    }
}
