.accordion {
    &__toggle {
        background-color: $primaryColor3;
        border: none;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        padding: 5px;
        font-size: 12px;

        &__icon {
            width: 10px;
            height: 10px;
            fill: $white;
        }

        &--reverse {
            transform: rotate(180deg);
        }
    }

    &__item {
        margin-top: 5px;

        &:first-child {
            margin-top: 0;
        }

        &__active {
            color: $darkBlue !important;

            @media only screen and (min-width: 768px) {
                border-color: $darkBlue !important;
                border-style: solid !important;
                border-width: 0 0 0 2px !important;
                padding-left: 15px !important;
            }
        }

        &__header {
            border: 1px solid $grayDD;
            height: 41px;
            background-color: $grayF7;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 10px;
            cursor: pointer;
            font-weight: 700;
            font-size: 14px;
        }

        &__content {
            max-height: 0;
            transition: max-height 1s ease-out, border-color 1s steps(1, end);
            overflow: hidden;
            margin-top: -1px;
            border: 1px solid transparent;

            &--open {
                border-color: $grayDD;
                transition: max-height 0.5s ease-in, border-color 0.5s;
                max-height: 1000px;
            }
        }
    }
}