.summary-review {
    display: flex;
    color: $primaryColor2;
    &__rating-box {
        position: relative;
        background-color: $primaryColor1;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        padding-top: 10px;
        padding-bottom: 10px;
        @media only screen and (min-width: $screen-lg) {
            width: 150px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        &:after {
            border-bottom: 7px solid transparent;
            border-left: 7px solid $primaryColor1;
            border-top: 7px solid transparent;
            content: '';
            margin-top: -6px;
            position: absolute;
            right: -7px;
            top: 0px;
            bottom: 0px;
            margin-top: auto;
            margin-bottom: auto;
            height: 7px;
        }
    }
    &__class-box {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    &__class {
        font-weight: bold;
        font-size: $fontSize32;
        margin-bottom: 5px;
        @media only screen and (min-width: $screen-lg) {
        }
    }
}
