.cruise-stage-tab-header {
    font-size: 1.166rem;
    line-height: 1.66rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 45px;

    &__title {
        font-size: 1.333rem;
        display: block;
        clear: both;
    }

    &__descr {
        margin: 0 0 10px;
    }

    &__price-button {
        float: right;
        display: flex;
        flex-flow: column wrap;
        align-items: flex-end;
    }
}
