.stars {
    display: inline-flex;
    line-height: 0;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .tooltipTitle {
        display: none;
    }

    .starIcon {
        overflow: hidden;
    }

    .halfStarIcon {
        // svg?
        overflow: hidden;
        transform: translate(-50%, 0);

        path {
            transform: translate(50%, 0);
        }
    }
    .tooltipIcon {
        display: inline-flex;
        align-items: center;
    }
}
