.theme-overview-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $screen-sm-min) {
        background: #f0f0f0;
    }

    &--overlay {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @media only screen and (min-width: $screen-lg-min) {
            display: block;
            background-color: rgba(34, 75, 122, 0.35);
        }
    }
    &--header-data {
        max-width: 600px;
        margin: 0 auto;

        @media only screen and (min-width: $screen-lg-min) {
            z-index: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
            position: relative;
        }
        h1 {
            margin-top: 5px;
            font-size: 25px;
            @media screen and (min-width: $screen-sm-min) {
                font-weight: bold;
            }
        }
    }
    &--wrapper {
        position: relative;
        display: flex;
        color: $white;
        text-align: center;
        padding: 10px;
        font-size: 15px;
        width: 100%;
        order: 1;

        @media screen and (max-width: 1279px) {
            background: $blue !important;
        }
        @media screen and (min-width: $screen-lg-min) {
            padding-left: 0;
            background-position: center;
            background-size: cover;
            min-height: 180px;
        }
    }
    &--breadcrumbs {
        padding: 0 15px;
        align-self: flex-start;
        align-items: center;
        order: 3;
        width: 100%;
        background: $white;
        @media screen and (min-width: $screen-sm-min) {
            order: 2;

            line-height: 1;
            ol {
                padding: 5px 0 2px !important;
                max-width: 1170px;
                margin: 0 !important;

                @media screen and (min-width: 1024px) {
                    margin: 0 auto !important;
                }
            }
        }
    }
    &--teasers-wrapper {
        @media screen and (min-width: $screen-sm-min) {
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
        }
    }
    &--margin-top {
        margin-top: 20px !important;
    }
    &--content {
        margin: 0px 15px;
        max-width: 1170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;

        @media screen and (min-width: $screen-sm-min) {
            order: 3;
        }

        .collapsible {
            position: relative;
            margin: 10px 0 30px;
            font-size: 15px;
            line-height: 20px;

            @media screen and (min-width: $screen-sm-min) {
                margin: 30px 0 50px;
            }

            @media screen and (min-width: $screen-lg-min) {
                background: $white;
                padding: 15px;
                margin: 30px 0;
            }

            h2 {
                display: none;

                @media screen and (min-width: $screen-sm-min) {
                    display: block;
                    font-size: 18px;
                }

                @media screen and (min-width: 1024px) {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }

            #show,
            #hide {
                position: absolute;
                bottom: -1em;
                z-index: 100;
                text-align: center;
                color: $acqa;

                @media screen and (min-width: $screen-lg-min) {
                    display: none;
                }
            }

            #hide {
                display: none;
            }
            #show:target {
                display: none;
            }
            #show:target ~ #hide {
                display: block;
            }
            #show:target ~ .collapsible--panel {
                max-height: 2000px;
            }

            .collapsible--panel {
                & > *:first-child {
                    margin-top: 0;
                }
                position: relative;
                max-height: 74px;
                overflow: hidden;
                transition: max-height 1s ease-in-out;
                @media screen and (min-width: $screen-sm-min) {
                    max-height: 90px;
                }
                @media screen and (min-width: $screen-lg-min) {
                    max-height: 100%;
                }
            }
        }

        .theme-overview-page--seotext {
            order: 2;
            @media screen and (min-width: $screen-sm-min) {
                order: 3;
            }
            @media screen and (min-width: 1024px) {
                background: $white;
                padding: 0 15px;
                margin: 30px 0;

                h2 {
                    margin-bottom: 20px;
                    font-size: 24px;
                }

                p {
                    font-size: 14px;
                }
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                li {
                    padding-left: 1em;
                    text-indent: -0.7em;
                }

                li::before {
                    content: '•';
                    color: $acqa;
                    padding-right: 20px;
                }

                h3 {
                    margin-bottom: 20px;
                    font-size: 24px;
                }
                div > div:nth-child(1) {
                    position: static !important;
                    height: 100% !important;
                }
                div > div:nth-child(2) {
                    display: none !important;
                }
            }
        }

        .theme-overview-page--linklist {
            @media screen and (min-width: $screen-sm-min) {
                ol {
                    margin-bottom: 15px;
                    li {
                        display: inline;
                        a p {
                            display: inline-flex;
                            width: 33%;
                            margin: 2px 0;
                        }
                    }
                }
            }

            @media screen and (max-width: 1024px) and (orientation: landscape) {
                ol {
                    margin-bottom: 30px;
                    li {
                        a p {
                            width: 25%;
                        }
                    }
                }
            }

            @media screen and (min-width: 1025px) {
                margin-bottom: 30px;
                padding: 20px;
                background: #ffffff;

                div > p {
                    font-size: 18px;
                }
            }
        }

        .recommendation-teaser {
            display: inline-flex;
            width: 100%;
            overflow: hidden;
            border-radius: 6px;

            &:not(:first-child) {
                margin-top: 20px;
            }

            @media screen and (min-width: $screen-sm-min) {
                &:not(:first-child) {
                    margin-top: 0;
                }
                max-width: 354px;
                margin: 0 22px 22px 0;
                background: white;
            }
            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                &:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
            @media screen and (min-width: 1024px) {
                max-width: 314px;

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }

            @media screen and (min-width: $screen-lg-min) {
                max-width: 375px;
            }

            a {
                width: 100%;
                text-decoration: none;
                display: inline-block;

                &:hover,
                &:focus,
                &:active {
                    color: #2a2a2a;
                }
                .recommendation-teaser--top {
                    position: relative;
                    padding-bottom: 66.67%;
                    @media screen and (min-width: $screen-sm-min) {
                        padding-bottom: 44.67%;
                    }
                    @media screen and (min-width: $screen-md-min) {
                        padding-bottom: 50.67%;
                    }
                    @media screen and (min-width: $screen-lg-min) {
                        padding-bottom: 48.67%;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        @media screen and (min-width: $screen-sm-min) {
                            height: 157px;
                        }
                        @media screen and (min-width: $screen-lg-min) {
                            height: 180px;
                        }
                    }
                    div {
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        margin: 0;
                        text-align: center;
                        padding: 10px 6px;
                        font-size: 19px;
                        font-weight: bold;
                        color: #ffffff;
                        text-shadow: 0 0 4px #2a2a2a;
                        background: linear-gradient(
                            to bottom,
                            rgba(8, 29, 46, 0),
                            rgba(1, 47, 74, 0.71)
                        );
                    }
                }

                .recommendation-teaser--bottom {
                    padding: 10px 10px 5px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    flex-direction: row;

                    @media screen and (min-width: $screen-sm-min) {
                        flex-direction: column;
                        padding: 0 5px;
                    }

                    &__description {
                        border-right: 1px solid #e0e0e0;
                        display: flex;
                        flex-direction: column;
                        flex: 1 1 auto;
                        width: 100%;
                        min-height: 48px;
                        justify-content: center;
                        @media screen and (min-width: $screen-sm-min) {
                            border-right: none;
                            border-bottom: 1px solid #f3f3f3;
                            padding-bottom: 10px;
                        }
                        & > div {
                            padding-right: 5px;
                            font-size: 15px;
                            @media screen and (min-width: $screen-sm-min) {
                                font-size: 14px;
                                padding: 5px 0 0 0;
                                margin: 0 5px;
                            }
                        }
                    }
                    &__price {
                        @media screen and (min-width: $screen-sm-min) {
                            width: 100%;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        span.recommendation-teaser {
            background: transparent;
            @media screen and (min-width: $screen-sm-min) {
                width: 354px;
            }
            @media screen and (min-width: 1024px) {
                width: 314px;
            }
            @media screen and (min-width: $screen-lg-min) {
                width: 375px;
            }
        }
    }
}

@media only screen and (min-width: $screen-lg) {
    .recommendation-teaser {
        position: relative;
        &--bottom__price {
            .priceDiscountStartFrom {
                display: none;
            }

            .priceDiscountTag {
                position: absolute !important;
                right: 0;
            }
        }
    }
}