.calendarIcon {
    width: 15px;
    height: 15px;
}

.calendarWrapper {
    margin-top: 10px;
    position: relative;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 2;

    @media (min-width: 0px) and (max-width: $screen-xs-max) {
        box-shadow: rgba(0, 0, 0, 0.11) 0px 8px 7px 0px;
        border: 1px solid rgb(216, 216, 216);
    }
    &.open-startDate,
    &.open-endDate {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3px;
            width: 50%;
            background-color: #006598;
            border-radius: 1.5px;
            transition: 400ms;
            z-index: 1;
        }
    }
    &.open-startDate {
        &::after {
            transform: translate3d(0, 1px, 0);
        }
    }
    &.open-endDate {
        &::after {
            transform: translate3d(100%, 1px, 0);
        }
    }
    & > div {
        width: 50%;
        position: relative;
        &:first-child {
            .DayPickerInput {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
        &:last-child {
            margin-right: 0;
            @media (min-width: $screen-sm) {
                z-index: 0;
            }
            .DayPickerInput {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
        > div {
            background-repeat: no-repeat;
            background-size: 17px 18px;
            background-position: 9px 12px;
            background-color: white;
        }
        input {
            width: 100%;
            height: 45px;
            border: 0;
            font-size: 14px;
            background: transparent;
            padding-left: 30px;
        }
    }

    .DayPicker-Day--selected.DayPicker-Day--start.DayPicker-Day--end {
        &::before,
        &::after {
            border-width: 0;
        }
    }

    .DayPicker {
        &-Caption {
            text-align: center;
            padding-top: 10px;
            padding-bottom: 5px;
            & > div {
                font-weight: 600;
                text-transform: uppercase;
                color: #484848;
            }
        }
        &-Weekday {
            color: #9b9b9b;
        }
        &-Day {
            font-size: 13px;
            border-radius: 0 !important;
            border: 0;
            color: #333;
            width: 46px;
            height: 45px;
            padding: 1px;
            position: relative;

            @media (min-width: $screen-sm) {
                width: 26px;
                height: 25px;
            }
            &:hover {
                background: rgba(0, 98, 149, 0.1);
                color: #4a4a4a;
                border: 0;
            }
            &--disabled {
                color: #d8d8d8;
            }

            &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                background: #006295; //#006295;
                position: relative;
                &:hover,
                &:active {
                    color: white;
                    background: #006295; //#006295;
                    border: 0;
                    position: relative;
                }
            }
            &--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                background: rgba(0, 98, 149, 0.2) !important;
                color: #333;
            }
            &--start:not(.DayPicker-Day--outside),
            &--end:not(.DayPicker-Day--outside) {
                background: #006295 !important;
                color: white;
                &:hover,
                &:active {
                    color: white;
                    background: #006295; //#006295;
                    border: 0;
                    position: relative;
                }
            }
            &--start:not(.DayPicker-Day--outside) {
                z-index: 1;
                &:after,
                &:before {
                    left: 19px;
                    top: 50%;
                    border: solid transparent;
                    content: ' ';
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-left-color: #006295;
                    border-width: 12px;
                    margin-top: -12px;
                    transform: skewX(-30deg);
                    z-index: -1;
                }
                &:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-left-color: #006295;
                    border-width: 12px;
                    margin-top: -12px;
                    transform: skewX(30deg);
                    z-index: -1;
                }
            }
            &--end:not(.DayPicker-Day--outside) {
                z-index: 1;
                &:after,
                &:before {
                    right: 19px;
                    top: 50%;
                    border: solid transparent;
                    content: ' ';
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-left-color: #006295;
                    border-width: 12px;
                    margin-top: -12px;
                    transform: skewX(-30deg) scale(-1);
                    z-index: -1;
                }
                &:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-left-color: #006295;
                    border-width: 12px;
                    margin-top: -12px;
                    transform: skewX(30deg) scale(-1);
                    z-index: -1;
                }
            }
        }
    }
    .DayPickerInput {
        display: block;
        position: relative;
        z-index: 2;
        &-Overlay {
            @media (min-width: 0px) and (max-width: $screen-xs-max) {
                height: calc(100vh - 90px);
                width: calc(100vw - 26px);
                box-shadow: none;
                overflow: auto;
                margin-top: 13px;
            }
            text-align: center;
            padding-bottom: 15px;
        }
    }
}

.calendarEndDateWrapper {
    .DayPickerInput {
        &:before {
            content: '';
            width: 1px;
            height: 30px;
            background: #979797;
            position: absolute;
            top: 8px;
            // display: ${(props: { hideSeparator: boolean }) =>
            //     props.hideSeparator ? 'none' : 'block'};
        }
    }
}

.calendarXIcon {
    width: 15px;
    height: 15px;
    stroke-width: 2;
    stroke: #4a4a4a;
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translate(-15px, -50%);
    right: 0;
    cursor: pointer;
}

.calendarArrowIcon {
    fill: #333333;
    stroke: #333333;
    width: 40px;
    height: 32px;
    top: 50%;
    position: absolute;
    padding: 5px;
    cursor: pointer;
    @media (min-width: $screen-sm) {
        width: 21px;
        height: 21px;
        top: 20px;
    }
}

.calendarArrowLeftIcon {
    transform: scale(-1);
    left: -16px;
    @media (min-width: $screen-sm) {
        left: 10px;
    }
}

.calendarArrowRightIcon {
    right: -16px;
    @media (min-width: $screen-sm) {
        right: 10px;
    }
}
