#sail-select div .Select .Select-control {
    border-radius: 6px;
    height: auto;
    border: none;
    padding: 0;

    & #react-select-price--value {
        width: 100%;
    }
}

#sail-select div .Select {
    border: solid 2px;
    border-color: #e6e7e9;
    border-radius: 6px;
}

#sail-select div .is-open .Select-control {
    & .Select-arrow-zone {
        & .Select-arrow {
            border-color: transparent transparent #fff;
        }
    }
}
