// global variables
@import 'global/variables';
@import 'global/colors';

// vendor
@import 'vendor/normalize.scss';

//global partial files
@import 'global/animations';
@import 'global/classes';
@import 'global/common';
@import 'global/theme';
@import 'global/typography';

@import '../../src/components/components';

@import '../../src/pages/pages';

@import '../../node_modules/react-day-picker/lib/style.css';

html,
body {
    font-size: initial;
    line-height: initial;
    @media (min-width: $screen-sm-min) {
        font-size: 12px;
        line-height: 1.42857143; // From bootstrap - 20px
    }
}

// A/B test
#obc-banner,
#bpg-banner {
    &.visible {
        display: grid !important;
    }
}

// Aligns with native selects with vertical padding of 10px;
.select-arrow {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px);
    background-size: 5px 5px, 5px 5px;
    background-repeat: no-repeat;
}
// IE fixes
@media #{$ie-only} {
    ul {
        padding: 0;
    }
}

.hideElementDesktopAndUp {
    @media screen and (min-width: $screen-md){
        display: none !important;
    }
}

.hideElementMobile {
    @media screen and (max-width: $screen-sm-max){
        display: none !important;
    }
}
