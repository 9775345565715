.detailed-reviews {
    width: 100%;
    color: $primaryColor2;
    @media only screen and (min-width: $screen-lg) {
        margin-top: 25px;
    }
    &__heading {
        font-weight: bold;
        font-size: $fontSize18;
        margin-bottom: 15px;
        display: block;
        @media only screen and (min-width: $screen-lg) {
            margin-bottom: 30px;
        }
    }
}
