$default-padding: 15px;

.modal {
    &--hidden {
        display: none;
    }

    &--shown {
        display: block;
    }

    &--size-normal &__dialog {
        .icon-cross {
            font-size: $fontSize14;
        }
    }

    &--size-large &__dialog {
        @media only screen and (min-width: $screen-lg) {
            width: 800px;
        }

        .icon-cross {
            font-size: $fontSize16;
        }
    }

    &__overlay {
        background: $black;
        opacity: 0.5;
        height: 100%;
        width: 100%;
        position: fixed;
    }

    &__dialog {
        background-color: $white;
        border: none;
        border-radius: 6px;
        max-width: 90vw;
        width: 800px;
        margin: 60px auto;
        position: relative;
        background-size: cover;
        @media only screen and (min-width: $screen-sm) {
            margin: 90px auto;
        }
    }

    &__bg {
        position: relative;
        height: 180px;

        @media only screen and (min-width: $screen-sm) {
            position: absolute;
            height: 100%;
            top: 0px;
            width: 100%;
        }
    }

    &__body {
        padding: $default-padding;
        position: relative;

        &.split {
            padding: 20px 9px;
            border-radius: 6px;
            @media only screen and (min-width: $screen-sm) {
                padding: 20px 30px;
                margin-left: 53%;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &.content-layer {
            background-color: $white;
        }
    }

    &__heading {
        font-size: $fontSize18;
        line-height: 1.2;
        font-weight: bold;
        margin: 20px 0;
        text-align: center;
        margin: 30px 0 30px;
        @media only screen and (min-width: $screen-sm) {
            font-size: $fontSize24;
        }
        @media only screen and (min-width: $screen-lg) {
            font-size: $fontSize30;
        }
    }

    &__close-btn {
        border: 2px solid red;
        position: absolute;
        right: 0px;
        top: 0px;
        line-height: 1;
        background: transparent;
        border: 0;
        padding: 10px;
        z-index: 1;

        @media only screen and (min-width: $screen-lg) {
            padding: 14px;
        }
    }

    &__header {
        &.has-title {
            padding: $default-padding;
        }

        &.primary1 {
            background-color: $primaryColor1;
            color: $white;
        }

        &.primary2 {
            background-color: $primaryColor2;
            color: $white;
        }
    }

    &__footer {
        overflow: hidden;

        &.has-submit-button {
            padding: $default-padding;
        }
    }

    &__submit-btn {
        font-size: $fontSize15;
    }

    &__title {
        font-size: $fontSize20;
    }
}
