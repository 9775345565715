.list-action {
    .list-group {
        margin-bottom: 0px !important;
    }

    .list-group-item {
        float: left;
        padding: 5px 12px !important;
        background: none !important;
    }
}

.list-group-horizontal .list-group-item {
    display: inline-block;
    border: 0px !important;
    text-decoration: underline;
}

.btn-favorite,
.btn-share,
.btn-pdf-download {
    border: 0px;
    background: none;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.tooltip-icon {
    position: relative;

    &:before {
        content: attr(data-text);
        position: absolute;
        top: 100%;
        width: 200px;
        padding: 10px;
        background: #fff;
        border: 1px solid;
        color: #000;
        text-align: center;
        display: none;
        left: -50%;
        opacity: 0;
        transition: 0.3s opacity;
        z-index: 10000;
    }
    &:hover:after {
        display: block;
        opacity: 1;
    }

    &:hover:before {
        display: block;
        opacity: 1;
    }

    .left:before {
        left: initial;
        margin: initial;
        right: 100%;
        margin-right: 15px;
    }
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
    font-size: 14px;
}

.action-icon {
    background-color: transparent;
    margin-right: 5px;
    padding: 3px 6px 3px 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        width: 17px;
    }
}

.tooltip-icon-share {
    position: relative;

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: -10px;
        right: 0;
        left: 0;
    }

    &:hover .tooltip-icon-share-text {
        visibility: visible;
        opacity: 1;
    }
}

.btn-pdf-download__container {
    position: relative;
    #btn-pdf-download__pdf-modal {
        top: 100%;
        left: 50%;
        z-index: 2;
        width: 400px;
        color: white;
        cursor: default;
        margin-top: 30px;
        position: absolute;
        background: $primaryColor1;
        transform: translateX(calc(-50% - 35px));
        padding: 15px 20px 45px 20px;
        #btn-pdf-download__pdf-modal__close {
            top: 0;
            right: 0;
            padding: 8px 10px;
            position: absolute;
            svg {
                fill: $white;
            }
        }
        &::before {
            top: 1px;
            left: 50%;
            content: '';
            width: 40px;
            height: 20px;
            display: block;
            position: absolute;
            background: $primaryColor1;
            transform: translate(calc(-50% + 35px), -100%);
            clip-path: polygon(50% 0, 100% 100%, 0 100%, 50% 0);
        }
        h2 {
            margin: 15px 0;
            font-size: 22px;
            font-weight: 700;
            text-align: center;
        }
        p {
            font-size: 14px;
            line-height: 1.3;
            margin: 0 0 25px;
            text-align: center;
            white-space: initial;
            &#pdf-modal__email-address__error {
                margin-top: 0;
                font-weight: 700;
                margin-bottom: 5px;
                color: $discountColor;
            }
        }
        #btn-pdf-download__pdf-modal__inline-form {
            display: flex;
            input {
                outline: 0;
                height: 31px;
                width: 210px;
                color: $gray66;
                font-size: 12px;
                margin-right: 8px;
                padding: 6px 12px;
                border: 1px solid $grayCC;
                &:focus {
                    border-color: $grayCC;
                    box-shadow: 0 0 8px rgba(102, 175, 233, 60%);
                }
                &.error_animation {
                    border: 1px solid $discountColor;
                    animation: increase_decrease 1s 0.1s;
                }
            }
        }
    }
}

@keyframes increase_decrease {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
