.harboursSection,
.furtherInfoSection,
.advisorSection,
.ugcSection {
    .headline {
        font-family: Arial;
        font-size: 25px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2a2a2a;
        margin-top: 40px;
        margin-bottom: 15px;
        display: block;
    }
}

.harboursSection {
    display: unset;
    text-align: center;
    @media only screen and (max-width: $screen-lg){
        display: none;
    }
    .mapboxgl-canvas {
        left: 0;
    }

    .recommendation-section {
        margin: 20px -11px -11px -11px !important;
    }

    .teaserContentWrapper {
        flex-direction: column;
        height: calc(100% - 250px);
        position: relative;
    }

    .teaserContentInfo {
        border-right: none !important;
        justify-content: unset !important;
        text-align: left;
        align-self: flex-start;

        .overflow-ellipsis--multi-line {
            -webkit-line-clamp: unset !important;
            height: auto !important;
        }
    }

    .teaserPriceCTA {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid #e0e0e0;
        align-self: end;

        .priceDiscountTag {
            position: absolute !important;
            right: 0;
        }

        .priceDiscountStartFrom {
            display: none;
        }

        .priceStartFrom {
            display: inline;
            padding-right: 10px !important;
            font-weight: normal;
        }
    }
    .recommendationSectionCTA {
        display: none;
    }
}

.furtherInfoSection .furtherInfoSectionContent,
.ugcSection > div {
    background: white;
    padding: 20px;
}

.furtherInfoSection h2 {
    margin-top: 20px;
}

.ugcSection {
    margin-bottom: 40px;
}

.companyPage {
    .slider-item > a {
        height: 100%;
        background-color: white;
    }
}

@media only screen and (max-width: $screen-xs-max) {
    .companyPage {
        & > .container {
            padding: 0;
        }
    }
}

#offers {
    & > div.slider > div {
        a {
            height: 100% !important;
        }
    }
}
