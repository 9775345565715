.advisorSection {
    .arrow {
        top: 50% !important;
        right: -8px !important;
        left: initial !important;
        transform: rotate(45deg) skew(0deg) !important;
    }

    & > div {
        background: white;
        padding: 20px;
        margin-top: 40px;

        display: grid;
        grid-template-columns: 355px auto;
        grid-template-rows: 100%;
        gap: 1px 1px;
        grid-template-areas: '. .';
        align-items: center;
        & > div,
        & > div * {
            margin: 0 !important;
            font-family: Arial;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #333333;
        }
        & > div {
            margin-left: 40px !important;
        }
    }
}

@media #{$ie-only} {
    .advisorSection > div {
        display: -ms-grid;
        -ms-grid-columns: 355px auto;
        -ms-grid-rows: 100%;
    }

    .advisorDescription {
        -ms-grid-column: 2;
    }
}
