.errorPage {
    &-wrapper {
        @media screen and (min-width: 768px) {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
        }
    }

    &-content {
        @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            justify-content: center;
        }
    }
    &-sorryMsg {
        color: #006598;
        text-align: center;
        font-size: 20px;
        padding: 0 15px;
        line-height: 1.3;
        font-weight: bold;
        margin-top: -15px;
        margin-bottom: 25px;
        @media screen and (min-width: 768px) {
            max-width: 700px;
            margin: 0 auto;
        }
    }
}
