.step-item {
    &__container {
        min-width: 200px;
        max-width: 250px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 -20px 20px;
        @media only screen and (min-width: 768px) {
            margin-bottom: 0;
        }
    }

    &__image {
        border: 2px solid $darkAcqa;
        border-radius: 50%;
    }

    &__text {
        text-align: center;
        margin-top: 1em;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        max-width: 100%;
    }
}
.dotted-arrow {
    &__container {
        display: none;

        @media only screen and (min-width: 768px) {
            display: flex;
            align-items: center;
            flex: 1;
        }
    }

    &__head {
        display: block;
        transform: rotate(90deg);
        height: 10px;
        flex: 0 0 auto;
        font-weight: bold;
    }
}
