.searchFilterWrapper {
    padding-top: 20px !important;
}

select {
    option {
        color: $kbd-bg;
    }
}

@media only screen and (min-width: $screen-lg) {
    .searchFilterContainer {
        display: block;
    }
}
@media only screen and (min-width: $screen-lg) {
    .searchFilterContainer {
        position: relative !important;
        width: 100% !important;
        height: auto !important;
        z-index: auto !important;
        background: transparent !important;
        overflow: visible !important;

        margin-top: 12px;
    }

    .searchFilterWrapper {
        padding: 0 !important;
        background: transparent !important;
        padding-top: 0 !important;
        margin-bottom: 60px;
        overflow: visible !important;
    }

    .calendarWrapper {
        .DayPickerInput-Overlay {
            width: 222px;
            z-index: 9999;
        }
    }

    .searchPage {
        .headline {
            h1 {
                font-family: Arial;
                font-size: 28px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 0.68;
                letter-spacing: normal;
                color: #333333;
            }
        }
    }

    .activeFilterItem {
        border-radius: 5px;
        padding: 7px !important;
        font-size: 14px !important;
        margin-top: 0 !important;
        margin-bottom: 10px;
        span {
            display: flex;
        }
    }

    .resetButton {
        margin-bottom: 10px;
    }

    .activeFilterWrapper {
        align-items: center !important;
        padding: 0px 0px 5px !important;
    }
}
