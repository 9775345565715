.dynamicFormWrapper {
    margin-top: 0;
    background: white;
    &.transparent {
        background-color: transparent;
        .dynamicForm {
            input {
                background-color: white !important;
            }
        }
    }
}

.dynamicForm {
    .headline {
        text-align: left;
        font-size: 18px;
        margin: 20px 15px;
        font-weight: bold;
        line-height: 1.5;
    }

    svg {
        display: inline-flex;
        margin: 0 5px 20px 0;
    }

    .errorText {
        font-size: 14px;
        color: #e01a59;
        margin: 1px 15px;
        grid-column: span 2;
        display: flex;
        align-items: center;

        > div {
            margin: 4px 0;
        }

        svg {
            vertical-align: middle;
            margin: 0 5px 0 0;

            color: #e01a59;
            fill: #e01a59;
        }
    }

    .submitDynamicForm {
        & > div {
            padding: 11px 5px;
        }
        margin: 15px;
    }

    .Select-clear {
        display: none;
    }

    .Select-control {
        border: 1px solid rgb(224, 224, 224);
        width: initial;
        margin: 0 15px;
    }

    .Select-value-label {
        color: rgb(115, 115, 115) !important;
        font-size: 16px !important;
    }

    @media only screen and (min-width: $screen-sm) {
        padding-bottom: 20px;
        margin-top: 40px;

        .dynamicForm {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto;
            gap: 1px 1px;
        }

        .headline {
            font-size: 25px;
            text-align: center;
            width: 90%;
            margin: auto;
            padding: 25px 0;
        }

        .submitDynamicForm {
            & > div {
                padding: 11px 5px;
            }
            max-width: 345px;
            margin: 0 auto;
        }

        .Select {
            margin: 0;
        }
    }

    @media only screen and (min-width: $screen-lg-min) {
        .dynamicForm {
            width: 735px;
            margin: auto;
            background: white;
        }
    }

    // IE fixes
    @media #{$ie-only} {
        .dynamicForm {
            display: -ms-grid;
            -ms-grid-columns: 50% 1px 50%;
        }
    }
}

.dynamicFormSent {
    background-color: $white;
    border: 1px solid $gray;
    min-height: 210px;
    padding: 15px;
    margin-top: 30px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;

    svg {
        margin-bottom: 15px;
    }
    .headline {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .body {
        text-align: center;
        font-size: 15px;
        line-height: 1.3;
    }
}
