.discountArrowContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.discountDiscount {
    padding-left: 2px;
    font-weight: bold;
    line-height: 1;
}

.discountLabel {
    position: relative;
    line-height: 1;
}
