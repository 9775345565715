.steps {
    &__container {
        padding: 15px;
        @media only screen and (min-width: 768px) {
            padding: 2em;
        }
    }

    &__headline {
        margin-top: 0;
        text-align: center;
        padding: 1em;
        font-size: 25px;
        color: #333333;
    }

    &__list {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0 20px;
        justify-content: space-between;
        align-items: center;
        @media only screen and (min-width: 768px) {
            flex-direction: row;

            align-items: flex-start;
        }
    }
}
