.routes-list {
    display: block;
    width: 100%;
    font-size: 1.166rem;

    &__table {
        width: 100%;
    }

    &__thead {
        text-transform: lowercase;
    }
    &__thead p:first-letter {
        text-transform: uppercase;
    }

    &__th,
    &__td {
        height: 100%;
        cursor: pointer;
        position: relative;
        &:not(.collapsible),
        &.collapsible.open {
            vertical-align: top;
            padding: 8px;
            line-height: 1.4;
        }
        &.collapsible > &__collapsible-inner {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        }
        &.collapsible.open > &__collapsible-inner {
            max-height: 1000px;
            transition: max-height 1s ease-in-out;
            padding: 15px 0;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 1px;
                background: $grayDD;
            }
        }

        &__collapsible-inner {
            position: relative;
            &__square {
                display: none;
            }
            &__arrival-departure {
                display: flex;
                font-weight: bold;
                margin: 15px 0;
                column-gap: 10px;
            }
            img {
                display: flex;
                float: right;
                margin: 10px 0px 10px 10px;
            }
        }
        &.collapsible.open .routes-list__td__collapsible-inner__square {
            display: block;
            position: absolute;
            top: -10px;
            width: 20px;
            height: 20px;
            left: 50px;
            border-width: 0px 0px 1px 1px;
            border-top-style: initial;
            border-right-style: initial;
            border-bottom-style: solid;
            border-left-style: solid;
            border-top-color: initial;
            border-right-color: initial;
            border-bottom-color: rgb(224, 224, 224);
            border-left-color: rgb(224, 224, 224);
            border-image: initial;
            background: rgb(255, 255, 255);
            transform: rotate(-45deg);
            transition: all 0.15s ease-in-out 0s;
        }
        &__toggle-collapsible {
            position: relative;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            height: 100%;
            width: 100%;
            fill: $darkAcqa;
            float: left;
            vertical-align: middle;
            &__inner-holder {
                height: 100%;
                width: 100%;
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;

                cursor: pointer;

                transition: transform 0.3s ease-in-out;
                &.active {
                    transform: rotate(180deg);
                }
            }
        }
        &__flag {
            fill: $darkAcqa;
            display: inline-block;
            vertical-align: middle;
        }
    }

    &__th {
        border-bottom: 2px solid $grayDD;
    }

    &__td {
        &:not(.collapsible) {
            border-top: 1px solid $grayDD;
        }
    }

    &__small-descr {
        display: block;
        clear: both;
        color: $gray9b;
        font-size: 91%;
    }
}

@media screen and (max-width: 599px) {
    .routes-list {
        border: 1px solid $grayDD;
        font-size: 0.9rem;
    }
}
