.toggle {
    font-size: 14px;
    color: $acqa;
    margin-top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
        fill: $acqa;
        margin-left: 6px;
    }

    .rotate {
        transform-box: fill-box;
        transform-origin: center;
        transform: rotate(180deg);
    }
}