$react-dates-color-white: #fff !default;
$react-dates-color-gray: #565a5c !default;
$react-dates-color-gray-lighter: lighten($react-dates-color-gray, 45%) !default; // #cacccd
$react-dates-color-text: #484848 !default;

.Calendar {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Calendar {
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1000;
    position: fixed;
    background-color: $lighterGray;
}

.Calendar__header {
    height: 60px;
    width: 100%;
    background-color: $white;
    line-height: 60px;
    text-align: center;
    border-bottom: 1px solid $midGray;
}

.Calendar__title {
    font-size: 19px;
}

.Calendar__action {
    width: 60px;
    color: $acqa;
    font-size: 15px;
}

.DateRangePickerInput {
    margin-top: 10px;
    font-size: 15px;
    background-color: $react-dates-color-white;
    display: flex;
    padding: 8px 0;
    border-radius: 4px;
}

.DateInput {
    flex: 1 0 50%;
    line-height: 26px;
    position: relative;
    min-width: 0;
}

.DateInput__input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    height: 100%;
    width: 100%;

    &[readonly] {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

.DateInput__display-text {
    padding: 0px 8px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
}

// =================================================

.CalendarTabs {
    width: 100%;
    background-color: white;
    text-align: center;
    display: flex;
    align-items: center;
}

.CalendarTabs__tab {
    color: #7d7d7d;
    font-size: 17px;
    flex: 0 0 50%;
    padding: 10px 5px;
    position: relative;
}

.CalendarTabs__tab--active {
    background-color: $darkAcqa;
    color: white;
    font-weight: bold;

    &::after {
        content: '';
        position: absolute;
        display: block;
        left: 50%;
        top: 100%;
        transform: translateX(-16px);
        border-top: 11px solid $darkAcqa;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
    }
}

.CalendarFooter {
    background: $white;
    padding: 10px 15px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px -2px 4px 0px;
    z-index: 1;
}

.CalendarFooter__button {
    background: $acqa;
    padding: 15px;
    border-radius: 5px;
    color: $white;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.CalendarDay {
    height: 35px;
    line-height: 35px;
    flex: 1 0 14%;
    text-align: center;
}

.CalendarDay--blocked-out-of-range {
    color: $midGray;
}

.CalendarDay--selected-start {
    color: $white;
    background-color: $darkAcqa;
    clip-path: polygon(0% 0%, 0% 100%, 80% 100%, 100% 50%, 80% 0%);
}

.CalendarDay--selected-end {
    color: $white;
    background-color: $darkAcqa;
    clip-path: polygon(20% 0%, 0% 50%, 20% 100%, 100% 100%, 100% 0%);
}

.CalendarDay--selected {
    background-color: #c7dce6;
}

.CalendarDay--selected-selected {
    color: white;
    background-color: $darkAcqa;
}

.CalendarMonth__wrapper {
    font-size: 15px;
    letter-spacing: 1.5px;
    padding: 12px;
}

.CalendarMonth__header {
    text-align: center;
    padding-bottom: 9px;
    padding-top: 20px;
    text-transform: uppercase;
}

.CalendarMonth__days {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
}

.CalendarWeek__header {
    display: flex;
    font-size: 13px;
    color: #9b9b9b;
    border-bottom: solid 1px $midGray;
    padding-bottom: 7px;
}

.CalendarWeek__days {
    flex: 1 0 14%;
    text-align: center;
}

.datepicker-modal {
    position: fixed !important;

    .datepicker-header {
        color: $darkerGray !important;
    }

    .datepicker.default {
        .datepicker-navbar-btn {
            color: $acqa !important;
        }

        .datepicker-wheel {
            border-top: 1px solid $darkerGray !important;
            border-bottom: 1px solid $darkerGray !important;
        }
    }
}
