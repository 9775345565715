.cruise-header-mobile {
    background-color: $white;
    padding: 11px 5px 0 15px;
    align-items: center;
    font-weight: bold;
    flex: 1 1 0;
    position: relative;
    z-index: 2;

    .stars {
        min-width: 70px;
        margin-left: 15px;
        transform: translateY(2px);

        > svg {
            width: 10px;
        }
    }
    &_title {
        display: flex;
        align-items: flex-end;
        position: relative;
    }

    &_title_h1 {
        font-size: 18px;
        margin: 10px 0;
        color: $softBlack;
        font-weight: bold;
    }

    &_title_h1_span {
        margin: 0;
        flex: 0 1 auto;
        margin-right: 10;
    }

    &_infoText {
        display: flex;
        font-weight: normal;
        font-size: 14px;
        color: $softBlack70;
        align-items: center;
        :last-child {
            margin-left: auto;
        }
    }

    &_companyText {
        margin-right: 15px;
    }

    &_harbourRow {
        font-weight: normal;
        font-size: 14px;
        flex: 1 1 auto;
    }

    &_fromText {
        font-size: 14px;
    }

    &_row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0;
    }
}
