.per-person-price-overview {
    display: flex;
    flex-flow: row wrap;
    margin-top: 7px;

    &__price-block {
        display: flex;
        flex-flow: column wrap;
        text-align: right;
        align-items: flex-end;
    }

    &__title {
        color: $titleA1;
        font-size: 12px;
        font-weight: 400;
        margin: 0 0 5px 0;
    }

    &__price-holder {
        display: 'inline-flex';
        align-items: 'baseline';
    }

    &__price {
        color: $price05;
        font-size: 1.833rem;
        font-weight: 700;
        line-height: 1.5rem;
        margin: 0 0 5px 0;
    }

    &__dual-price {
        display: block;
        clear: both;
        color: $price91;
        text-align: right;
        font-size: 1rem;
        font-weight: 700;
        margin-top: -0.3rem;
        span {
            font-family: Arial, Helvetica, sans-serif !important;
        }
    }

    &__hotel-services {
        font-size: 12px;
        color: $fontColor;
        font-weight: 700;
        margin: 0 0 5px 0;
    }

    &__hint {
        margin: 15px 15px 10px 15px;
        color: $gray2A;
        font-size: 12px;
    }

    &__discount {
        background: $discountColor;
        font-size: 12px;
        font-weight: 600;
        display: flex;
        flex-flow: column wrap;
        color: $white;
        margin-right: 37px;
        text-align: center;
        align-items: center;
        justify-content: space-evenly;
        width: 52px;
        margin-top: 7px;
    }

    &__discount__label {
        font-size: 0.833rem;
        font-weight: 400;
        line-height: 0.8333rem;
        margin: 0;
        width: 100%;
        margin-top: 4px;
    }

    &__discount__value {
        color: $white;
        font-size: 1.333rem;
    }

    &__discount-text {
        color: $pink;
        font-weight: bold;
        margin-bottom: 7px;
    }
}
