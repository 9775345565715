body > header {
    background-color: $white;
}

header {
    min-height: 48px;
    background-color: $white;

    .navbar-right {
        margin-right: 0;
        float: right;
    }
    .globalPageheader {
        & > .row > .navbar {
            margin-top: 90px;
        }
        .row .container .row .switchable-phone-number-wrapper {
            padding-right: 8px;
            @media (max-width: $screen-sm-min) {
                display: none;
            }
        }
        &.withOutPageBanner {
            margin-bottom: 25px;
        }

        &_brandHeight {
            @media (min-width: $screen-sm-min) {
                height: 90px;
                max-height: 90px;
            }
        }

        &_brand {
            padding-bottom: 5px;
            padding-top: 5px;
            padding-left: 0;

            @media (max-width: $screen-md-min) {
                padding-left: 12px;
            }
            @media (min-width: $screen-sm-min) {
                padding: 0 15px;
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                a {
                    display: inline !important;
                }
            }
        }

        &_awards {
            padding-top: 10px;
            .logosprite-globalpageheader_safepayment2 {
                margin-left: 10px;
            }
        }

        &_trustLogos {
            top: 50%;
            transform: translateY(-50%);
        }

        &_contact {
            display: inline-block;
            margin: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;
            // z-index: 999;
            &:hover {
                text-decoration: none;
            }
            address {
                margin: 0;
            }
        }
    }
    .exchangeRate-top {
        margin-top: 10px;
        margin-right: 20px;
        display: inline-block;
        vertical-align: top;
    }
    .exchangeRate {
        background: $lighterGray;
        border: none;
        display: inline-block;
        font-size: $fontSize14;
        font-weight: bold;
        margin: auto 0;
        padding: 10px 10px;
        flex: 0 0 auto;
        position: absolute;
        left: -145px;
        @media (min-width: $screen-lg) {
            font-size: $fontSize16;
        }
    }
}
.navbar-toggle {
    margin: 0 15px 0 0;
}

// country specific css: NL
.nl {
    header .globalPageheader_awards {
        a.safepayment1 {
            background: url('/website-assets/images/spritesheet-zsmo2m.png');
            background-position: -280px -86px;
            width: 50px;
            height: 50px;
            display: inline-block;
        }

        a.safepayment2 {
            background: url('/website-assets/images/spritesheet-zsmo2m.png');
            background-position: -426px -292px;
            width: 50px;
            height: 50px;
            display: inline-block;
            margin-left: 10px;
        }
    }
}

.trustpilot-widget {
    visibility: hidden;
}

.navbar {
    margin: 0;
    border-radius: 0;
    border: none;
    background: $darkBlue;

    * {
        font-size: 1.166rem;
    }

    & .navbar-nav {
        li > a {
            &:hover,
            &:focus {
                background-color: $primaryColor2;
            }
        }

        .dropdown-menu {
            li > a {
                padding: 5px 20px;
            }
        }
    }

    &.navbar-white {
        background: $white;

        * {
            font-size: 12px;
        }

        & .navbar-nav {
            li > a {
                color: $darkBlue2;
                font-weight: bold;
                &:hover,
                &:focus {
                    background-color: #eef2ff;
                }
            }
            & .dropdown-menu {
                border: 1px solid $lighterGray;
                li > a:hover {
                    color: $darkBlue2 !important;
                }
            }
        }
        & .search-cruise {
            border-color: $lighterGray;
            button {
                position: relative;
                top: 2px;
                &:before {
                    color: $darkBlue2;
                }
            }
        }
    }

    .navbar-toggle {
        z-index: 1000;

        &[aria-expanded='true'] {
            &:before {
                border-left: 1px solid $borderColor;
                content: '';
                height: 60px;
                left: 0;
                position: absolute;
                top: -15px;
            }
            &:after {
                border-top: 1px solid $borderColor;
                content: '';
                position: absolute;
                right: 41px;
                top: 45px;
                width: 100px;
            }
        }
    }
    .navbar-nav {
        > li {
            @media (max-width: $screen-xs-max) {
                border-top: 1px solid $borderColor;
                &:first-child {
                    border: none;
                }
            }
            > a {
                padding: 8px;
                &:before {
                    padding-right: 5px;
                }
                @media (min-width: $screen-sm-min) {
                    color: $white;
                    padding: 16px 8px;
                }
                @media (min-width: $screen-md-min) {
                    padding: 16px 10px;
                }
            }
            &.active {
                > a,
                > a:hover {
                    font-weight: bold;
                    background-color: #00233b;
                    color: #ffffff;
                }
            }
            &:hover {
                > .dropdown-menu {
                    display: block;
                    li > a:hover {
                        background-color: #f5f5f5;
                        color: #262626;
                    }
                }
            }
        }
        li {
            a {
                &.active {
                    font-weight: bold;
                }
            }
        }
        &.navbar-right {
            margin-right: -15px;

            @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                margin-right: -10px;
            }
            li a span {
                display: inline;
                @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
                    display: none;
                }
            }
        }
    }
}

.globalPageheader {
    &_phone {
        color: $gray-base;
        font-size: $fontSize20;
        font-weight: bold;
        @media (min-width: $screen-xs-min) {
            font-size: $fontSize25;
        }
        @media (min-width: $screen-md-min) {
            height: 35px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        &-small {
            font-size: $fontSize14;
            @media (min-width: $screen-lg-min) {
                font-size: $fontSize20;
            }
        }
        &_number {
            display: inline-block;
        }
        &_text {
            display: inline-block;
            font-size: $standardFontSize;
            font-weight: normal;
            margin: 0;
        }
        &:before {
            padding-right: 4px;
        }
    }

    &_officeHours {
        color: #888888;
        font-size: 0.94rem;
        text-decoration: none;
    }
    &_officeHoursWrapper {
        padding-left: 0;
        display: flex;
        justify-content: flex-end;

        address {
            margin-bottom: 0;
        }
    }
    &_contact {
        padding-right: 15px;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .mobile-hidden {
        display: none;
    }
    .mobile-visible {
        display: block;
    }
}
@media (min-width: $screen-sm-min) {
    .mobile-hidden {
        display: block;
    }
    .mobile-visible {
        display: none;
    }
}
