.paginationItem {
    min-width: 35px;
    height: 35px;

    padding: 0 7px;

    background-color: white;
    border: solid 1px #c3c3c3;
    border-radius: 3px;

    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #006598;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &.dots {
        background-color: #f0f0f0;
        color: #c3c3c3;
        font-weight: 100;

        &::before {
            content: '...';
        }
    }

    &.highlight {
        background-color: #00233b;
        border-color: #00233b;
        color: white;
    }

    &.leftArrow,
    &.rightArrow {
        background-color: #006598;
        border-color: #006598;
        color: white;

        &::before {
            font-family: dreamlines;
            display: inline-block;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            line-height: 1;
            text-decoration: inherit;
            text-rendering: optimizeLegibility;
            text-transform: none;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            font-smoothing: antialiased;

            content: '\f153';
        }
    }

    &.leftArrow::before {
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }
}

.paginationWrapper {
    display: flex;
    margin: 0;
    padding: 0;

    li {
        margin-right: 5px;
        a {
            outline: none;
            text-decoration: none;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
