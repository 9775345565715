.slider-arrow {
    display: none;
}

@media only screen and (min-width: $screen-sm) and (max-width: $screen-lg) {
    .recommendation-section .slider-item {
        flex: 0 0 34% !important;
        min-width: 335px;
        padding: 8px;
    }
    .slider_container {
        position: relative;
        .slider {
            margin: 0 40px;
            justify-content: center;
            > .slider-item {
                min-width: 460px;
            }
            &.smooth {
                scroll-behavior: smooth;
            }
        }
        .slider-arrow {
            top: 50%;
            z-index: 1;
            display: block;
            cursor: pointer;
            font-size: 33.6px;
            position: absolute;
            color: $primaryColor3;
            font-family: dreamlines;
            transform: translateY(-50%);
            &::before {
                display: block;
            }
            &:hover {
                color: $black;
            }
            &.disabled {
                opacity: 0.3;
                pointer-events: none;
            }
            &#slider-arrow-left {
                left: 5px;
                &::before {
                    content: '\f100';
                }
            }
            &#slider-arrow-right {
                right: 0;
                &::before {
                    content: '\f101';
                }
            }
        }
    }

    .slider-item {
        & > div {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
        }
    }
}

@media only screen and (min-width: $screen-lg) {
    .recommendation-section {
        margin: 0 !important;

        .priceDiscountStartFrom {
            display: none;
        }

        .slider {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .slider-arrow {
            display: none;
        }

        .slider-item {
            flex: 0 0 33.333333% !important;
            max-width: calc(100% / 3) !important;
            padding: 11px !important;
        }

        h2 {
            font-size: 25px !important;
            margin-top: 40px !important;
        }
    }
}

.slider {
    @media only screen and (max-width: $screen-sm) {
        gap: 16px;
    }
}

.slider-item {
    max-width: calc(100% - 100px);
    @media only screen and (min-width: $screen-sm) {
        max-width: auto;
    }
    & > * {
        background-color: white !important;
        height: 100%;
        &:hover {
            color: #2a2a2a;
        }
    }
}
