.specialsContent {
    padding: 7px 25px;

    @media (min-width: $screen-md-min) {
        padding: 0;
        background: $white;
        border: 2px solid $grayF0;
        border-radius: 0 0 6px 6px;
    }

    .specialsItem {
        margin: 15px 0;

        strong,
        b {
            font-size: 16px;

            @media (min-width: $screen-md-min) {
                font-weight: 700;
                line-height: 37px;
            }
        }

        .specials-description,
        p {
            margin: 5px 0;
            overflow: hidden;

            @media (min-width: $screen-md-min) {
                margin: 0 0 8.5px;
            }
        }

        .specials-description,
        p,
        div {
            font-size: 13px;
            line-height: 1.46;

            @media (min-width: $screen-md-min) {
                font-size: 14px;
                line-height: 20px;
            }

            strong,
            b {
                font-size: inherit;
                line-height: inherit;
            }

            a {
                color: $acqa;
            }
        }

        @media (min-width: $screen-md-min) {
            margin: unset;
            padding: 8px 25px 11px 25px;
            border-bottom: 2px solid $grayF5;
            border-radius: 6px;

            &:last-of-type {
                border-bottom: unset;
            }

            ul {
                padding-inline-start: 40px;

                li {
                    list-style-type: disc;
                }
            }
        }
    }
}
