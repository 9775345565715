.contact-form-container {
    background-color: #fff;
    @media (min-width: $screen-lg-min) {
        background-color: #07496f;
    }
    padding: 15px;
}
.contact-form {
    display: flex;
    flex-flow: column wrap;

    max-width: 1300px;
    margin: auto;
    padding: 0;

    line-height: 1.3;
    font-size: 14px;
    color: $darkGray;

    &_headline {
        color: $black;
        text-align: center;
        font-size: 18px;
        margin: 0 0 20px;
        font-weight: bold;
    }

    &_requiredFieldsText {
        text-align: right;
    }
    @media (min-width: $screen-lg-min) {
        &_headline,
        &_requiredFieldsText {
            color: white;
        }
    }
    &_errorBox {
        color: $error;
        background-color: $pinkLight;
        text-align: left;
        margin: 20px 0;
        padding: 10px;
    }

    &_form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: $screen-lg-min) {
            flex-direction: row;
        }

        &_inputGroup {
            display: flex;
            flex: 1 1;
            flex-wrap: wrap;
            flex-direction: row;
            margin-right: -10px; // hack to align the rightmost inputs
            @media (min-width: $screen-lg-min) {
                margin-right: 0;
            }

            > div {
                width: calc(50% - 10px);
                margin-right: 10px;
                margin-bottom: 10px;

                @media (min-width: $screen-lg-min) {
                    min-width: 250px; // wraps field when resizing textbox
                }
            }
        }
        &_textWrapper {
            flex: 1;
        }

        // IE Fix
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            &_inputGroup {
                flex: 0 1 auto;
            }
            &_textWrapper {
                flex: 1 0 auto;
            }
        }
    }

    &_btnWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        > div {
            background: #06aaee;
            border: none;
            box-shadow: none;
            color: #fff;
            line-height: 1;
            padding: 5px 2px 5px 5px;
            position: relative;
            text-align: center;
            cursor: pointer;
            width: 265px;
            height: 50px;
            border-radius: 5px;
            > div {
                background: transparent !important;
                padding: 10px 40px 5px 20px !important;
                font-size: 20px !important;
                > span:first-child {
                    font-weight: bold;
                }
                .offerButton__arrows {
                    top: 60%;
                }
            }
        }
    }
}
.contact-form-sent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: 210px;
    font-size: 15px;
    color: $darkGray;

    @media (min-width: $screen-lg-min) {
        color: white;
        svg > g {
            fill: white;
        }
    }

    & > h3 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
    }
}

.contact-input {
    width: 100%;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 40px;
    font-size: 16px;
    opacity: 1;
    border: 1px solid $gray;
    color: $black;
    background: $white;
}
.input-error {
    border: 2px solid $pink;
}
.contact-text {
    height: auto;
    min-height: 90px;
}

.country-code-select {
    padding: 5px;
    display: flex;
    align-items: center;
    min-height: 100%;

    & > .country-flag {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
}

.phone-select {
    display: inline-flex;
    justify-content: center;
    & > * {
        flex: 0 1 50px;
        margin-right: 10px;
    }
    .phone-input {
        flex: 1 0;
        margin-right: 0;
    }
}
