.rating-bar {
    margin-bottom: 30px;
    height: 15px;
    color: $primaryColor2;
    font-weight: bold;
    position: relative;
    display: block;
    @media only screen and (min-width: $screen-lg) {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
    }
    &__description {
        display: flex;
        position: absolute;
        bottom: -20px;
        width: 100%;
        @media only screen and (min-width: $screen-lg) {
            flex: 2.35;
            position: initial;
        }
    }
    &__bar {
        width: 100%;
        background-color: $gray;
        height: 15px;
        display: block;
        @media only screen and (min-width: $screen-lg) {
            flex: 3;
            margin-right: 40px;
        }
        &--shorter {
            @media only screen and (min-width: $screen-lg) {
                margin-right: 60px;
            }
        }
    }
    &__bar-filled {
        height: 100%;
        background-color: $primaryColor1;
    }
    &__rating-value {
        top: 0px;
        position: absolute;
        right: 0px;
    }
    &__rating-value-grey {
        color: $gray;
    }
    &__icon {
        margin-left: 4px;
    }
}
