.scroll-to-button {
    padding: 4px 52px 4px 20px;
    background: $softBlue;
    border: none;
    color: $white;
    float: right;
    font-size: 1.333rem;
    line-height: 1.428571429;
    text-align: center;
    font-weight: 400;
    height: 30px;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    min-width: 100%;
    &__holder {
        margin-top: 7px;
        min-width: 100%;
        display: inline-block;
        position: relative;
    }

    &__icon-holder {
        background-color: $lightBlue;
        bottom: 0;
        color: $white;
        fill: $white;
        font-size: 12px;
        line-height: 35px !important;
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.disabled {
        background: $midGray;
        pointer-events: none;
        .scroll-to-button__icon-holder {
            background-color: $grayAA;
        }
    }

    &:hover {
        background-color: $primaryColor2;
    }

    :hover > &__icon-holder {
        background-color: $btn-bg-color;
    }
}
