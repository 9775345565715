#info-banner {
    &.bannerWrapper {
        background: #bdc5ca;

        @media screen and (min-width: 768px) {
            display: flex;
            position: relative;
            width: 100%;
            text-align: center;
            background: linear-gradient(to right, #6d818f 50%, #bdc5ca 50%);
        }

        @media (min-width: 1280px) {
            height: 60px;
        }
    }

    .contentWrapper {
        display: flex;
        height: 100%;
        @media screen and (min-width: 768px) {
            display: -ms-grid;
            display: inline-grid;
            -ms-grid-columns: 1fr 120px 1fr;
            grid-template-columns: 1fr minmax(60px, auto) 1fr;
            grid-column-gap: 15px;
            width: 100%;
        }
    }

    .nameWrapper {
        display: none;
        -ms-grid-column: 1;
        grid-column: 1;
        padding: 6px 0;
        font-weight: bold;
        text-align: right;
        align-self: center;

        @media screen and (min-width: 768px) {
            display: block;
        }
        p {
            color: white;
            @media (min-width: $screen-lg-min) {
                float: right;
            }
        }
    }

    img {
        -ms-grid-column: 2;
        grid-column: 2;

        width: auto;

        align-self: center;
        justify-self: center;
        margin-left: -20px;
        @media screen and (min-width: 768px) {
            margin-left: auto;
        }

        @media (min-width: 1280px) {
            height: 60px;
        }
    }

    .copyWrapper {
        -ms-grid-column: 3;
        grid-column: 3;
        align-self: center;
    }

    .copyLinkWrapper {
        text-align: left;
        align-self: center;
        margin-top: 4px;
        p {
            font-size: 20px;
            margin-bottom: 5px;
            // Added media queries to fix layout issues in Safari on Iphone 11
            @media (max-width: 767px) {
                font-size: 17px;
                line-height: 1.5;
                margin-bottom: 0;
            }
            @media (min-width: 1280px) {
                display: inline-block;
                margin-bottom: 0;
            }
        }
    }

    p {
        color: #00233b;
        margin: 0;
        line-height: 1;
        font-size: 20px;
        font-weight: bold;
    }

    .link {
        font-size: 14px;
        color: rgb(0, 35, 59);
        padding: 1% 10% 0px 0px;
        text-decoration: none;
        display: inline-block;
        font-weight: normal;

        // Added media queries to fix layout issues in Safari on Iphone 11
        @media screen and (max-width: 767px) {
            width: 100%;
        }

        @media screen and (min-width: 768px) {
            color: #00233b;
            padding: 3px 15px;
            font-size: 16px;
            letter-spacing: normal;
            text-align: center;
            border: 2px solid #00233b;
        }

        @media (min-width: 1280px) {
            margin-left: 15px;
        }
        svg {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }

    .arrowRightIcon {
        fill: #000;
        fill: #fff;
        margin-left: 5px;
    }

    .closeIconWrapper {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 19px;
        cursor: pointer;
        @media screen and (min-width: 321px) and (max-width: 767px) {
            top: 30px;
        }
    }
}
