.newsLetter {
    &--wrapper {
        &.newsLetter-responsive {
            @media screen and (min-width: 768px) {
                min-height: 180px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                > div:nth-child(2) {
                    max-width: 605px;
                    z-index: 1;
                }
            }

            @media screen and (max-width: 1279px) {
                background-image: none !important;
            }

            @media screen and (min-width: 1280px) {
                background-size: cover;
                background-repeat: no-repeat;
            }
        }

        &.searchNewsletter {
            background-image: none !important;
            padding: 10px;
            margin: 10px 0;
        }
    }

    &--btnText {
        .newsLetter--container & {
            color: white;
        }
    }

    &--header {
        p {
            color: $white;
            margin: 0;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            flex: 1 1 auto;

            .newsLetter-responsive & {
                @media screen and (min-width: 768px) {
                    font-size: 20px;
                    line-height: 24px;
                }
            }
        }
    }

    &--emailWrapper {
        overflow: hidden;
        position: relative;
        margin-top: 20px;
    }

    &--email-input {
        &.is-error {
            border-color: #e01a59;
        }
    }

    &--container {
        > div:first-child {
            .newsLetter-responsive & {
                @media screen and (min-width: 768px) {
                    width: auto !important;
                    padding: 10px 45px !important;
                    cursor: pointer;
                }
            }
        }
    }

    &--confirmEmailWrapper {
        color: $white;
        font-size: 17px;
        flex: 1 1 auto;
        line-height: 20px;

        * {
            .footer & {
                color: white;
            }
        }

        .newsLetter-responsive & {
            @media screen and (min-width: 768px) {
                font-size: 20px;
            }
        }

        &-email-text {
            margin-top: 20px;
            font-size: 15px;

            .newsLetter-responsive & {
                @media screen and (min-width: 768px) {
                    margin-left: -30px;
                }
            }
        }
    }

    &--html-link {
        a {
            text-decoration: underline;
            color: #ffffff;
        }

        @media screen and (min-width: 768px) {
            text-align: left !important; //override the inline style for newsletter form.
        }
    }
}

.searchNewsletter {
    &--container {
        display: flex;
        flex-direction: column;
    }
}
