@import './components/Heading.scss';
@import './components/Steps.scss';
@import './components/Step.scss';

.information {
    &__container {
        display: flex;
        flex-direction: column;
    }

    &__horizontal-separator {
        border-top: 1px solid #d4d4d4;
        margin: 0;
        @media (max-width: 767px) {
            display: none;
        }
        @media (min-width: $screen-md) {
            margin: 17px 0;
        }
    }

    &__download-pdf {
        font-size: $fontSize18;
    }

    &__row {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;

        @media (min-width: $screen-md) {
            flex-direction: row;
            padding: 1rem 0 3rem;
        }
    }

    &__row-item {
        text-align: center;
        padding: 2em 4em;
        @media (max-width: 767px) {
            padding: 0 4em 2em;
        }
        &--separator {
            text-align: center;
            padding: 2em 4em;
            border-top: 1px solid #d4d4d4;

            @media (min-width: $screen-md) {
                border-top: 0;
                border-left: 1px solid #d4d4d4;
            }
        }

        &.full-width {
            .information__text {
                max-width: none;
            }
        }
    }

    &__headline,
    &__phone-no {
        font-size: 25px;
        color: #333333;
    }
    &__phone-no {
        @media (max-width: 767px) {
            font-size: 22px;
        }
    }
    &__text {
        margin: auto;
        max-width: 350px;
        font-size: 14px;
        color: #333333;
    }
    &__download-pdf {
        font-size: 16px;
        a {
            color: #06aaee;
            &:before {
                content: '\F11F';
                font-family: dreamlines;
            }
        }
    }
}

// Reset the component's stylings just for thank you page
.recommendation-section {
    & .tiles__row .tiles__tile {
        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            flex: 0 0 33.33%;
            min-width: 0;
        }
    }

    & .recommendation-box {
        height: auto;
    }
}
