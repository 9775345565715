.custom-select > div {
    outline: none;

    .Select {
        margin: 0 15px 15px;
        position: relative;
        font-family: 'Helvetica Neue' !important;
        border-radius: 8px;
        .Select-control {
            height: 48px;
            align-items: stretch;
            overflow: visible;
            border-color: #e0e0e0;
            border-radius: 8px;
            border-width: 2px;

            .Select-multi-value-wrapper > div div:nth-child(2) {
                padding: 12px 13px 10px;
            }
        }
    }
}
