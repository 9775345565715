.multiple-prices-switch {
    display: flex;
    width: 100%;
    flex-flow: wrap;
    border-radius: 6px;
    border: 2px solid $softBlue20;
    background-color: $softBlue4;
    padding: 16px;
    font-size: 14px;
    transform: translateY(15px);
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-sm-min) {
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    &__switch-wrapper {
        label {
            margin-bottom: 0;
        }
    }

    &__label {
        color: $softBlack;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;

        @media (min-width: $screen-sm-min) {
            margin-left: 8px;
            margin-bottom: 0;
        }

        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            // align-items: center;

            @media (min-width: $screen-sm-min) {
                flex-direction: row;
                // align-items: flex-start;
            }
        }
    }
    &__link {
        color: $softBlue;
        line-height: 22px;

        @media (min-width: $screen-sm-min) {
            margin-left: 8px;
        }

        &:hover {
            color: $softBlue;
            text-decoration: underline;
        }
    }
}
