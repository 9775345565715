.gallerySliderSection {
    background: white;
    padding: 12px;
    margin-top: 40px;
}

.gallerySliderSection > div {
    float: left;
    width: 50%;
}

.gallerySliderSectionContent {
    padding-left: 16px;
}

.gallerySliderSectionContent > div {
    clear: both;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
}

.gallerySliderSectionContent > span {
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    float: left;
}

.gallerySlider .galleryImage {
    width: 100%;
}

.gallerySlider .thumbImage {
    width: 135px;
}

.BrainhubCarousel__dots {
    overflow-x: hidden;
    justify-content: initial;
    scroll-behavior: smooth;
}

.BrainhubCarousel__customArrows {
    position: relative;
    z-index: 9999;
    .arrowRight {
        left: -40px;
    }
}

.arrowIcon {
    fill: #333333;
    stroke: #333333;
    width: 40px;
    height: 32px;
    top: 50%;
    position: absolute;
    padding: 5px;
    cursor: pointer;
}

.arrowLeft {
    transform: scale(-1);
}

// IE is not rendering correctly
.thumbnailArrow {
    @media #{$ie-only} {
        display: none;
    }
}

.gallerySlider > div {
    position: relative;
    & > .arrowIcon {
        bottom: 35px;
        top: initial;
    }
    & > .arrowRight {
        right: 0;
    }
}
