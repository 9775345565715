// Hide scrollbar for Chrome, Safari and Opera
.slider::-webkit-scrollbar {
    display: none;
}

.slider{
    &.active{
        a{
            pointer-events: none;
        }
    }
}

@media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
    .teaserSlideItem {
        min-width: 40% !important;
    }
    .collapsibleSection {
        padding: 20px 5px !important;
    }
}
