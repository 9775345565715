.overall-reviews {
    width: 100%;
    color: $primaryColor2;
    margin-top: 25px;
    @media only screen and (min-width: $screen-lg) {
        margin-top: 30px;
    }
    &__heading {
        font-weight: bold;
        font-size: $fontSize18;
        margin-bottom: 15px;
    }
}
