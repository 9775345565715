$space-letter: 10px;

.dreamdeal {
    &-footer {
        color: white;
        padding: 5px 20px 15px 20px;
        @media screen and (max-width: 992px) {
            text-align: center;
        }
    }

    &-box {
        background-color: $dream-deal-pink;
        min-height: 50px;
        margin-bottom: 16px;

        @media screen and (max-width: 992px) {
            margin-top: 0;
            min-height: 100px;
        }
    }

    &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 992px) {
            height: auto;

            flex-direction: column;
        }

        &-title {
            margin-top: 10px;
            background-color: white;
            padding: 4px 10px 2px 10px;
            font-size: 16px;
            color: $dream-deal-pink;

            @media screen and (max-width: 992px) {
                font-size: 20px;
            }
        }

        &-counter {
            display: flex;
            justify-content: center;
            align-items: center;

            &__title {
                color: white;
                text-align: center;

                > p {
                    margin: 0;

                    @media screen and (max-width: 992px) {
                        margin-top: 8px;
                    }
                }
            }

            &__number {
                color: $dream-deal-pink;
                font-size: 2.5rem;
                font-weight: bold;
                background-color: white;
                margin: 10px;
                margin-top: 4px;
                letter-spacing: $space-letter;
                position: relative;
                padding: 0 5px;

                > div {
                    margin-right: -$space-letter;
                }

                &::after {
                    content: '';
                    width: 2px;
                    height: 100%;
                    left: 50%;
                    margin-left: -1px;
                    background-color: $dream-deal-pink;
                    position: absolute;
                    top: 0;
                }
            }

            @media screen and (max-width: 992px) {
                &__number {
                    font-size: 1.5rem;
                }

                &__title {
                    > p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
