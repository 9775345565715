.cruise-stage-header {
    display: flex;
    flex-flow: nowrap row;
    background: $white;
    position: relative;
    z-index: 1;
    min-height: 150px;
    padding: 24px;
    border: solid 2px $grayF0;
    border-radius: 6px;

    &__info {
        flex: 1;
    }

    &__iternary-info {
        font-size: 16px;
        font-weight: 500;
        color: $softBlack;
        line-height: 24px;
        white-space: nowrap;
        margin: 10px 0;
        &__night-ports {
            display: inline-block;
            margin-right: 8px;
            margin-left: 0;
        }
        &__cruise-id {
            display: inline-block;
            margin-left: 12px;
        }
        &__company {
            display: inline-block;
            margin-left: 12px;
        }
    }

    &__logo {
        display: flex;
        flex: 0 1 auto;
        width: 12%;

        &__img-holder {
            height: 100%;
            width: 100%;
            overflow: hidden;
            position: relative;
            margin-right: 10px;

            &__img {
                height: 100%;
                max-width: 100%;
                object-fit: contain;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    &__title {
        color: $softBlack;
        font-size: 24px;
        font-weight: 700;
        margin: 0;
        display: flex;
        align-items: center;
        margin: 0 0 5px;
        padding-top: 10px;

        .stars {
            align-items: flex-start;
            margin-left: 5px;
            margin-bottom: 10px;

            svg {
                transform: translateY(1px);

                &.starIcon {
                    transform: translateY(5px);
                }

                &.halfStarIcon {
                    transform: translate(-5px, 5px);
                }
            }
        }
    }

    &__subtitle {
        color: $black;
        font-size: 1.166rem;
        margin: 5px 0;
        display: flex;
        align-items: center;
        font-size: 18px;
        min-height: 20px;

        &__holder {
            font-size: 1.166rem;
        }

        &__url {
            display: inline-block;
            color: $softBlue;
            text-decoration: none;
            margin-left: 5px;
        }

        &__cruise-id {
            display: inline-block;
            font-size: 12px;
            margin-left: 7px;
        }

        &__company {
            display: inline-block;
            font-size: 12px;
            margin-left: 7px;
        }

        .summaryReview {
            margin-left: 15px;
            display: inline-flex;

            line-height: 0.92;

            .ratingBoxWithArrow {
                padding: 5px;
                margin-right: 10px;

                .ratingBox {
                    font-size: 13px;
                }

                .ratingBoxArrow {
                    width: 10px;
                    height: 10px;
                    margin-left: -5px;
                    margin-top: -5px;
                }
            }

            .ratingInfo {
                > div {
                    font-size: 14px;
                    font-weight: normal;
                }

                > span {
                    font-size: 9px;
                }
            }
        }
    }

    &__inclusives-row {
        display: flex;
        flex-flow: row wrap;
    }

    &__inclusives {
        margin: 0;
        display: flex;
        flex-flow: row wrap;
        row-gap: 10px;
        column-gap: 10px;

        &__heading {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            color: $softBlack;
            margin: 2px 8px 0 0;
        }

        &__item {
            border: solid 2px $grayF0;
            background-color: $grayFD;
            border-radius: 6px;
            padding: 6px 16px 6px 12px;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 22px;

            &__icon {
                display: inline-block;
                margin-right: 5px;
                display: flex;
                align-items: center;
            }
        }
    }
}
