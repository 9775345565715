.modalCheckMarkList {
    padding: 0;
    list-style: none;
    list-style-type: none;
    font-weight: bold;

    &__wrapper{
        margin: 10px 15px;
    }
    &__heading {
        font-weight: bold;
        font-size: 1.2rem;
        margin: 10px 0;
        @media only screen and (min-width: $screen-sm) {
            font-size: 1.833rem;
        }
    }
    &__item {
        list-style: none;
        list-style-type: none;
        display: block;
        margin-top: 5px;
    }
    &__icon {
        font-size: 1.3rem;
        margin-right: 10px;
        vertical-align: top;
        display: inline-block;
        line-height: 1em;
        @media only screen and (min-width: $screen-sm) {
            font-size: 2.5rem;
        }
    }
    &__label {
        font-size: 14px;
        vertical-align: top;
        display: inline-block;
        margin-top: 5px;
        @media only screen and (min-width: $screen-sm) {
            font-size: 1.333rem;
        }
    }
}
