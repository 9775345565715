.navigation-panel {
    border: none;
    font-size: 1.25rem;
    margin-bottom: 2px;
    margin-top: 10px;
    &__wrapper {
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        background-color: white;
        // @include breakpoint(screen-md) {
        //     font-size: 14px;
        // }
    }
    &__item {
        list-style-type: none;
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &--active {
            background-color: $primaryColor2;
            border-left: 1px solid $primaryColor2;
            margin-left: -1px;
        }
        &:hover {
            color: white;
            background-color: $primaryColor2;
            border-left: 1px solid $primaryColor2;
            margin-left: -1px;
        }
        &--external {
            &:hover {
                background-color: white;
            }
        }
        &:last-child {
            .navigation-panel__link:after {
                content: none;
            }
        }
    }
    &__item-title {
        // @extend .hidden-xs;
        margin-left: 3px;
    }
    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        text-align: center;
        padding: 8px;
        color: $primaryColor2;
        &:hover,
        &:active,
        &:focus {
            color: white;
            text-decoration: none;
        }
        &:after {
            border-right: 1px solid $primaryColor2;
            bottom: 4.5px;
            content: '';
            right: 0px;
            top: 4.5px;
            position: absolute;
        }
        &--without-border {
            &:after {
                content: none;
            }
        }
        &--active {
            color: white;
        }

        &--external {
            color: $primaryColor3;
            &:after {
                content: none;
            }
            &:hover,
            &:active,
            &:focus {
                color: $primaryColor3;
                text-decoration: none;
            }
        }
    }
}
