.contactPage {
    & > .contact-form-container {
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
}

.contactHeader {
    min-height: 110px;
    display: flex;
    flex-direction: column;

    background-position: center;
    background-size: cover;

    align-items: center;
    justify-content: center;
    color: white;

    padding: 20px 0;

    h1 {
        font-size: 25px;
        font-weight: normal;
        text-align: center;

        text-shadow: 0 0 4px rgba(0, 0, 0, 0.81);
    }

    h3 {
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.81);
        display: none; // Shows below the header
        @media (min-width: $screen-lg-min) {
            display: inline-flex;
        }
    }

    @media (min-width: $screen-sm-min) {
        font-size: 3.2rem;
        padding: 40px 0;
        h1 {
            font-weight: bold;
        }
    }
}

.contactSubtitle {
    font-size: 15px;
    text-align: center;
    max-width: 600px;
    margin: 5px auto 20px;
}

.advisorListContainer {
    padding: 15px;
    background: $lightGray;
    overflow: hidden;

    & > h3 {
        @media (min-width: $screen-lg-min) {
            display: none; // Shows on the header
        }
    }
}

.advisorList {
    display: grid;
    grid-gap: 20px;
    max-width: 1300px;
    margin: 10px auto;

    @media (min-width: $screen-sm-min) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: $screen-lg-min) {
        grid-template-columns: repeat(4, 1fr);
    }

    // IE fallback
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    justify-content: space-around;
    .advisorContainer {
        @media (min-width: $screen-sm-min) {
            flex-basis: 45%;
            .advisorDetails {
                width: 100%;
            }
        }
        @media (min-width: $screen-lg-min) {
            flex-basis: 272px;
        }
    }
}

.advisorContainer {
    display: flex;
    flex: 0 0 100%;
    padding: 10px;

    background-color: white;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    @media (min-width: $screen-lg-min) {
        flex-basis: 272px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

/* Handles only the main change to tablet + desktop */
.advisorCard {
    @media (min-width: $screen-lg-min) {
        align-items: center;
        justify-content: space-between;

        .phoneSpan {
            margin-left: -30px; //offset to center the phone number
        }

        .advisorAvatar {
            width: 150px;
            height: 150px;
        }
    }
}

/* Advisor information card for both main screen and modal window */
.advisorDetails {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    flex: 1 1 auto;
    justify-content: space-between;
    margin: 0 0 0 10px;
    max-width: 100%;

    & > h4 {
        margin: 5px 0 0 0;
        font-weight: bold;
        font-size: 18px;
    }
    & > P {
        max-width: 100%;
        color: $darkGray;
        font-size: 14px;
        line-height: 18px;
        margin: 10px 0;
        flex-grow: 1; // Pushes phone down to align them
    }

    // for CTA button
    a {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & > .phoneSpan, // in the modal it's a direct children
    & > .invisibleMobile > .phoneSpan {
        font-size: 15px;
        font-weight: bold;
    }
}

.phoneSpan {
    display: inline-flex;
    align-items: center;

    & > svg {
        margin-right: 5px;
    }
}

.advisorAvatar {
    width: 98px;
    height: 98px;

    flex: 0 0 auto;

    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.21);
    border-radius: 50%;
}

.invisibleMobile {
    @media (max-width: $screen-xs-max) {
        display: none;
    }
}

.visibleMobile {
    @media (min-width: $screen-sm-min) {
        display: none;
    }
}

.advisorModal {
    & > h3 {
        font-size: 18px;
        margin: 10px;
        font-weight: bold;
    }
    & > p {
        font-size: 15px;
    }

    // overwrite media queries to avoid desktop style
    .contact-form-container {
        background-color: white;
        padding: 0;
    }
    .contact-form {
        max-width: 760px;
        &_headline {
            display: none;
        }
        &_requiredFieldsText {
            color: $darkGray;
        }
        &_form {
            flex-direction: column;
            &_inputGroup {
                margin-right: -10px; // keep the hack as we have tablet view
            }
        }
    }

    .contact-form-sent {
        color: black;
        svg > g {
            fill: $primaryColor1;
        }
    }
}

.AdvisorModalInfo {
    margin: 0 auto;
    display: flex;

    align-items: center;

    .advisorDetails {
        align-items: start;
    }

    @media (max-width: $screen-md-min) {
        .advisorAvatar,
        .advisorDetails {
            display: none;
        }
    }
}
