#routes-container {
    display: flex;
    width: 100%;
    > div {
        width: 100%;
        @media screen and (min-width: 768px) {
            width: calc(50% - 10px);
            &:first-of-type {
                margin-right: 10px;
            }
            &:last-of-type {
                margin-left: 10px;
            }
        }
    }
    #cruiseRouteMap {
        min-height: 450px;
        max-height: 500px;
        display: flex;

        .mapboxgl-ctrl-attrib.mapboxgl-compact {
            min-height: 24px;
            .mapboxgl-ctrl-attrib-inner {
                transform: translateY(1px);
            }
        }

        div {
            max-width: 100%;
            max-height: 100%;
        }
        .map-popUp {
            z-index: 1;
            max-width: 250px;
            * {
                margin: 0;
                font-size: 14px;
            }
            h3 {
                font-weight: 600;
                font-style: italic;
            }
            .mapboxgl-popup-content {
                padding: 15px;
                .map-popUp__content {
                    overflow-y: auto;
                    max-height: 170px;
                    > div {
                        max-height: 170px;
                    }
                }
                .map-popUp__image {
                    float: right;
                    max-width: 125px;
                }
            }
        }
        .mapboxgl-marker {
            min-width: 30px;
            min-height: 39px;
            cursor: pointer !important;
            background: url(#{$baseImagePath}/common/markers/marker-number.png)
                no-repeat center transparent;
            &.active {
                z-index: 1;
                background: url(#{$baseImagePath}/common/markers/marker-number-active.png)
                    no-repeat center transparent;
            }
            &.only_point {
                min-width: 10px;
                min-height: 10px;
                background: #202;
                border-radius: 5px;
                margin-top: -5px;
                margin-left: -5px;
            }
            &.only_point_anchor {
                background: url(#{$baseImagePath}/common/markers/marker-harbour.png)
                    no-repeat center transparent;
            }
            .marker-html_pin {
                font-size: 12px;
                padding-top: 7px;
                font-weight: 600;
                text-align: center;
            }
        }
    }
}
