.inclusive-header,
.inclusive-container {
    display: none;
}

.inclusives-title {
    font-weight: 700;
    font-size: 15px;
}

.inclusives-text {
    font-size: 15px;
    line-height: 20px;

    ul {
        padding-inline-start: 0;

        li {
            margin-bottom: 8px;
            list-style-type: none;

            &:before {
                content: '•';
                padding-right: 12px;
            }
        }
    }

    a {
        color: $acqa;
    }
}

@media (min-width: $screen-md-min) {
    .inclusive-container {
        .inclusives-title {
            font-size: 16px;
            line-height: 17.5px;
        }

        .inclusives-text {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            ul {
                padding-inline-start: 40px;

                li {
                    list-style-type: disc;
                    margin-bottom: 0px;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}
