.newsletter-modal-custom-select {
    position: relative;
    color: $black;
    .search-select {
        &__control {
            border-radius: 0;
            border: 1px solid $gray;
            margin-bottom: -1px;
            height: 40px;
            display: flex;
            background-color: $white;
        }
        &__value-container {
            flex: 1;
            display: flex;
            align-items: flex-start;
            padding: 2px 0px 2px 8px;
            overflow: hidden;
            position: relative;
        }
        &__placeholder {
            color: $darkGray;
            margin-left: 2px;
            margin-right: 2px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
        }
        &__single-value {
            color: #333;
            line-height: 1.1;
            margin: auto 0;
            overflow: hidden;
            max-height: 2.7rem;

            @supports (-webkit-line-clamp: 2) {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                height: auto;
            }
        }

        &__menu {
            margin: 0;
            border-radius: 0;
            border: 1px solid $gray;
            width: 100%;
            background-color: hsl(0, 0%, 100%);
            position: absolute;
            z-index: 1;
        }
        &__menu-list {
            max-height: 300px;
            overflow-y: auto;
            padding: 0;
            -webkit-overflow-scrolling: touch;
        }
        &__group {
            padding: 8px 0;
        }
        &__group-heading {
            font-size: 13px;
            cursor: default;
            font-weight: bold;
            margin-bottom: 0.25em;
            padding: 0 10px;
        }
        &__dropdown-indicator {
            color: hsl(0, 0%, 80%);
            padding: 8px 4px;
            transform-origin: center;
            height: 100%;
            svg {
                display: inline-block;
                fill: $darkGray;
                line-height: 1;
                stroke: $darkGray;
                stroke-width: 0;
                height: 100%;
                vertical-align: middle;
            }
            @media (max-width: $screen-sm-max) {
                padding: 8px 0px;
            }
        }
        &__clear-indicator {
            display: none;
        }
        &__indicator-separator {
            display: none;
        }
        &__option {
            padding: 8px 10px;
            line-height: 1.1;

            @media (max-width: $screen-sm-max) {
                font-size: 12px;
            }
            user-select: none;
            &--is-selected {
                background-color: $gray;
            }
            :hover {
                background-color: $highLightBlue;
                color: $white;
            }
        }
    }
    /* IF DISABLED */
    &.search-select--is-disabled {
        .search-select {
            &__control {
                background-color: rgba(255, 255, 255, 0.25);
                border: none;
            }
            &__indicators {
                display: none;
            }
            &__placeholder {
                left: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                font-weight: bold;
            }
            &__value-container {
                justify-content: center;
            }
            &__single-value {
                color: $white;
            }
        }
    }
}
