.newsletterForm {
    display: inline-block;
    width: 90%;
    margin: 20px 0;
    padding: 25px 10px 20px;
    background: rgba(255, 255, 255, 0.65);
    position: relative;
}

.checkMarkWrapper {
    background: rgba(0, 35, 59, 0.7);
    padding: 10px;
    text-align: 'center';

    &--list {
        display: inline-block;
        &-item {
            display: flex;
            align-items: center;
            margin: 5px auto;
        }
    }
}
