.cruise-stage {
    display: flex;
    flex-direction: column;
}

.cruiseStageSummary {
    h4 {
        font-size: 20px;
        font-weight: bold;
        margin: 0 0 15px;
    }
    > div {
        display: flex;

        align-items: center;
        margin: 8px 0;

        font-size: 15px;

        h5 {
            font-weight: bold;
            margin: 0 5px 0 0;
        }
    }
}

.sailSelectValue {
    display: flex;
    align-items: center;

    margin: 0 0 0 15px;
}

.Select-control {
    align-items: stretch;
    font-size: 14px;
    padding: 5px 0;
}

.sailSelectOption {
    color: $black;
    padding: 10px;
    border-bottom: 1px solid $gray;

    .sailOptionDiscount {
        font-size: 11px;
        color: $pink;
        font-weight: bold;
    }
}
.cruise-stage-overview__description-list {
    float: right;
    overflow: hidden;
}

.cruise-stage-overview__ul {
    display: inline-block;
    margin-top: 15px;
    padding-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
    list-style: none;
}

.cruise-stage-overview__li {
    display: flex;
}

.cruise-stage-overview__label {
    float: left;
    font-weight: 400;
    min-width: 125px;
    width: 35%;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 1.166rem;
    line-height: 1.66rem;
}
.cruise-stage-overview__descr {
    font-size: 1.166rem;
    line-height: 1.66rem;
    fill: $primaryColor1;
}
