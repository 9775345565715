$select-text-color: #4a4a4a !default;
$select-option-color: #4a4a4a !default;
$select-option-focused-bg: darken(#f2f9fc, 10%) !default;
$select-option-disabled-color: #4a4a4a !default;
$select-arrow-color: #c3c3c3 !default;
$select-arrow-width: 8px !default;
$select-input-border-color: #c3c3c3 !default;
$select-menu-zindex: 10 !default;
$select-menu-max-height: 285px !default;
@import 'vendor/default';

.Select-control {
    min-height: 40px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.is-open > .Select-control {
    .Select-arrow {
        border-width: 0px $select-arrow-width $select-arrow-width;
    }
}
.Select-arrow-up {
    top: -2px;
    border-color: transparent transparent $select-arrow-color;
    border-width: 0 $select-arrow-width $select-arrow-width !important;
}

.Select-arrow {
    border-width: $select-arrow-width $select-arrow-width 0px;
}

.Select-placeholder,
.Select--single > .Select-control .Select-value {
    line-height: 37px;
}

.Select-menu-outer {
    border-radius: 0;
    margin: 0;
}

@media only screen and (min-width: $screen-lg) {
    .Select {
        &.is-open {
            .Select-menu-outer {
                max-width: 525px;
            }
        }
        &.direction-down.is-open {
            max-width: 525px;
            margin: 0 auto;
    
            .Select-menu-outer {
                max-width: 525px;
            }
        }
    }
}

.Select {
    &.direction-up {
        &.is-searchable {
            margin-top: -79px;
        }

        &.is-open {
            box-shadow: 0 -2px 5px 0px $select-arrow-color;
            .Select-menu-outer {
                box-shadow: 0 -2px 5px 0px $select-arrow-color;
                top: auto;
                bottom: 100%;
                border-bottom: 0;
            }
        }
    }
    &.direction-down.is-open {
        box-shadow: 0 2px 5px 0px $select-arrow-color;
        .Select-menu-outer {
            box-shadow: 0 2px 5px 0px $select-arrow-color;
            top: 100%;
            bottom: auto;
            border-top: 0;
        }
    }
}

.Select-control {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    .Select-multi-value-wrapper {
        display: flex;
        flex-grow: 1;
    }
}
.Select-arrow-zone {
    max-width: 40px;
    display: flex;
    flex: 0 1 20%;
    justify-content: center;
    background: inherit;
    align-items: center;
    padding: 0 5%;
}

/* ---------- For showing the scrollbar all the time (webkit browsers only)-------------------*/
.Select-menu-outer .Select-menu {
    &::-webkit-scrollbar {
        -webkit-appearance: none !important;
        width: 4px !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5) !important;
        border-radius: 10px !important;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px !important;
    }
}

.Select:not(.is-searchable) {
    .Select-input {
        display: none !important;
    }
}
.is-searchable {
    .Select-control {
        align-items: stretch;
        background-color: $lighterGray;
        .Select-multi-value-wrapper {
            display: block;
            width: 100%;
        }
    }
}

.Select-value-label {
    font-size: 14px !important;
}
