.searchSection {
    width: 100%;
    height: auto;
    background-size: cover;
    position: relative;
    max-height: 293px;

    .searchSectionLayer {
        width: 100%;
        height: 100%;
        background: rgba(0, 35, 59, 0.3);
        position: absolute;
        top: 0;
        left: 0;
    }

    &.searchSection-indigo {
        .searchMask {
            background: $indigo;
        }
        .searchSectionLayer {
            background: linear-gradient(to bottom, rgba(1, 11, 61, 0), rgba(1, 11, 61, 1));
        }
    }
    .searchSectionContainer {
        position: relative;

        @media only screen and (min-width: $screen-lg) {
            display: flex;
            width: 1170px;
            justify-items: center;
            margin: 0 auto;

            .searchMask {
                width: 478px;
            }
        }

        .searchFilter {
            height: 100%;
        }

        @media only screen and (min-width: 0px) and (max-width: $screen-xs-max) {
            .searchFilter {
                width: 100%;
            }
        }

        .searchSectionDesc {
            margin: auto 0 24px 24px;

            @media #{$ie-only} {
                margin: 24px;
            }
        }

        .searchSectionDesc p {
            margin-bottom: 0;
        }

        .headline {
            font-family: Arial;
            font-size: 30px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            color: #ffffff;
        }

        .subheadline,
        .searchSectionDesc-link,
        .subheadline * {
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.39;
            letter-spacing: normal;
            color: #ffffff;
        }
        .searchSectionDesc-link {
            font-weight: 700;
            text-decoration: underline;
            display: inline-block;
            margin-top: 10px;
            cursor: pointer;
        }
    }
}
