$searchCruiseDarkGrey: #6d8fa9;
$searchCruiseLightGrey: #a2b8c9;

.search-cruise {
    @media (min-width: $screen-lg-min) {
        &.visible-lg {
            display: block;
        }
        &.hidden-lg {
            display: none;
        }
    }
    border: 1px solid $searchCruiseDarkGrey;
    float: right;
    width: 186px;
    margin: 1px 13px 0;
    padding: 1px 0;

    @media (min-width: $screen-lg-min) {
        padding: 0;
        margin: 10px 0 10px 10px;
        width: 184px;
        border-color: $white;
    }

    input {
        border: 0;
        padding: 1px 7px;
        background: transparent;
        font-size: 13px;
        color: $searchCruiseDarkGrey;
        width: 153px;

        @media (min-width: $screen-lg-min) {
            width: 150px;
            padding: 5px 7px;
            color: $searchCruiseLightGrey;
        }

        &::-webkit-input-placeholder {
            color: $searchCruiseDarkGrey;
            font-size: 13px;

            @media (min-width: $screen-lg-min) {
                color: $searchCruiseLightGrey;
            }
        }
        &:-moz-placeholder {
            color: $searchCruiseDarkGrey;
            font-size: 13px;

            @media (min-width: $screen-lg-min) {
                color: $searchCruiseLightGrey;
            }
        }
        &::-moz-placeholder {
            color: $searchCruiseDarkGrey;
            font-size: 13px;

            @media (min-width: $screen-lg-min) {
                color: $searchCruiseLightGrey;
            }
        }
        &:-ms-input-placeholder {
            color: $searchCruiseDarkGrey;
            font-size: 13px;

            @media (min-width: $screen-lg-min) {
                color: $searchCruiseLightGrey;
            }
        }
    }
    button {
        border: 0;
        background: transparent;
        height: 16px;

        @media (min-width: $screen-lg-min) {
            height: auto;
        }
        &:before {
            color: $searchCruiseDarkGrey;
            font-size: 14px;

            @media (min-width: $screen-lg-min) {
                color: $searchCruiseLightGrey;
            }
        }
    }
}
