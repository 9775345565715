.cabinSectionContainer {
    display: grid;
    justify-content: center;
    margin: 12px 0;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    > .cardBox {
        margin: 5px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    .modalContent {
        max-width: $screen-md;
    }
}

@media only screen and (min-width: $screen-lg) {
    .cabinSectionContainer {
        padding: 0 25px;
        .modalContent {
            min-width: $screen-md;
        }
        // Added media Queries to fix layout issues in Safari on Iphone 11
        @media screen and (min-width: 321px) and (max-width: 767px) {
            margin: 0 -15px;
        }
    }
}

.cardSubtitle {
    display: flex;

    justify-content: space-around;
    padding: 0 15px;

    > span {
        display: inline-flex;
        align-items: center;

        svg,
        .icon-people {
            font-size: 20px;
            margin-right: 5px;
        }
    }
}

.radio-input {
    appearance: none;
    width: 20px;
    height: 20px;
    border: solid 2px $newSoftGrey;
    border-radius: 50%;
    margin-left: 10px;

    &[type='radio']:focus {
        outline: none;
        outline-offset: 0;
        border-radius: 50%;
        background: $softBlue;
        border: 2px solid $white;
        box-shadow: 0px 0px 0px 2px $softBlue;
    }
}

.radio-input-checked {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $softBlue;
    border: 2px solid $white;
    box-shadow: 0px 0px 0px 2px $softBlue;
    &[type='radio']:focus {
        outline: none;
        outline-offset: 0;
    }
}

input.radio-input,
input.radio-input-checked,
.disabled {
    margin-left: 16px;
}
