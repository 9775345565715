.toggle-switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;

    &.disabled {
        opacity: 0.3;
        cursor: default;
    }
}
.toggle-switch input[type='checkbox'] {
    display: none;
}
.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: $grayCC;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
    position: absolute;
    content: '';
    left: 2px;
    top: 2px;
    width: 20px;
    height: 20px;
    background-color: $white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
    transform: translateX(19px);
    background-color: $white;
}
.toggle-switch input[type='checkbox']:checked + .switch {
    background-color: $softBlue;
}
