.standardContentPage {
    a {
        color: $blue;
    }
    h1 {
        font-size: 24px;
    }
    margin-left: 15px;
    margin-right: 15px;

    img {
        max-width: 100%;
        height: auto;
    }
    &--header {
        color: white;
        text-align: center;
        padding: 15px 15px 20px;
        font-size: 15px;
        position: relative;

        @media only screen and (min-width: 768px) {
            padding: 21px 15px;
        }
        @media only screen and (max-width: 1279px) {
            background: #07496f !important; //override the inline style of desktop
        }
        @media only screen and (min-width: 1280px) {
            background-repeat: no-repeat;
            height: 177px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-size: cover;
        }
        &-overlay {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            @media only screen and (min-width: 1280px) {
                display: block;
                background-color: rgba(11, 78, 128, 0.7);
            }
        }
        &-icon {
            background-repeat: no-repeat;
            display: none;
            width: 108px;
            height: 96px;
            opacity: 0.57;
            margin-right: 30px;

            @media only screen and (min-width: 1280px) {
                display: inline-block;
            }
        }
        &-data {
            @media only screen and (min-width: 1280px) {
                z-index: 1;
                display: flex;
                align-items: center;
                text-align: left;
                margin-left: -55px;
            }
        }
        h2 {
            font-size: 24px;
            margin: 0px 0px 15px 0px;
            @media only screen and (min-width: 768px) and (max-width: 1279px) {
                margin-bottom: 8px;
            }
        }
    }
    &--container {
        font-size: 14px !important;
        h2 {
            font-size: 18px !important;
        }
        h3 {
            font-size: 16px !important;
        }
        h4 {
            font-size: 14px !important;
        }

        @media only screen and (min-width: 1280px) {
            display: flex;
            width: 1170px;
            justify-items: center;
            margin: 0 auto;
        }
        > div:nth-child(2) {
            padding: 15px;

            @media only screen and (min-width: 1280px) {
                padding: 30px 0 30px 30px;
                width: calc(100% - 356px);
            }
        }
    }
    &--content {
        font-size: 14px !important;
        table {
            width: 100% !important;
            border-color: #ccc;
        }
        th,
        td {
            padding: 10px !important;
            font-size: 14px !important;
        }
        .Select {
            margin: 0px 15px 20px;
        }
        .Select-control {
            margin: 0;
        }
    }
    &--aditional-content {
        margin-left: 5px;
        margin-right: 5px;
        table {
            max-width: 100%;
            overflow-x: auto;
            display: block;
            td {
                padding: 5px;
            }
        }
    }
    &--contact-form {
        margin-left: 15px;
        margin-right: 15px;
        table {
            max-width: 100%;
            overflow-x: auto;
            display: block;
            td {
                padding: 5px;
            }
        }
        .Select {
            margin: 0px 15px 20px;
        }
        .Select-control {
            margin: 0;
        }
    }
    &--aside {
        width: 356px;
        height: 410px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        display: none;
        margin-top: 30px;

        @media only screen and (min-width: 1280px) {
            display: block;
        }
        &-header {
            background-color: #164e78;
            font-family: Arial;
            font-size: 20px;
            font-weight: bold;
            line-height: 1.65;
            text-align: center;
            color: white;
        }
        &-data {
            padding: 20px;
        }
        &-item {
            display: flex;
            padding: 30px 0;
            border-bottom: 1px solid #c3c3c3;
            align-items: center;

            &-title,
            &-phNo {
                font-family: Arial;
                font-size: 15px;
                font-weight: bold;
                color: #2a2a2a;
                display: block;
                line-height: normal;
            }
            &:first-child {
                padding-top: 15px;
            }
            &:last-child {
                border-bottom: 0;
            }
            &-anchor {
                font-family: Arial;
                font-size: 14px;
                color: $acqa;
                line-height: normal;
            }
        }
        &-icon-container {
            border-radius: 50%;
            border: 1px solid black;
            font-size: 0;
            margin-right: 9px;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-text-container {
            width: calc(100% - 60px);
        }
    }
}
