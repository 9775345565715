.review-tags {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    &__tag {
        margin-right: 8px;
        margin-bottom: 5px;
        padding: 5px;
        background-color: $primaryColor1;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: $fontSize11;
    }
}
