.tooltipWrapper {
    position: relative;

    .tooltipIcon {
        min-width: 30px;
        min-height: 20px;
        padding-left: 5px;
        cursor: pointer;

        > svg {
            fill: white;
            stroke: white;
            stroke-width: 10px;
            vertical-align: middle;
        }
    }

    .tooltipArrow {
        position: absolute;

        top: 100%;
        left: 5px;

        width: 20px;
        height: 20px;

        border: 10px solid white;
        border-top-color: transparent;
        border-right-color: transparent;

        transform: rotate(-215deg) skew(22deg);

        z-index: 9;
        box-shadow: -3px 3px 4px -2px rgba(0, 0, 0, 0.32);
    }

    .tooltipBody {
        display: block;
        position: absolute;

        min-width: 200px;
        max-height: 200px;

        top: 30px;
        left: -100%;

        padding: 20px 12px;

        font-size: 12px;
        line-height: 18px;
        z-index: 8;

        color: #333333;
        background: white;

        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        line-height: normal;
    }

    .tooltipTitle {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: #333333;

        margin-bottom: 10px;
    }

    @media print {
        display: none;
    }
}
