$black: #000000;
$white: #ffffff;
$gray: #e0e0e0;

$softBlack: #1a181e;
$softBlack70: #1a181eb3;
$softBlack50: #1a181e80;
$softBlue: #0099ff;
$softBlue20: #0099ff33;
$softBlue4: #0099ff0a;

$primaryColor1: #006295 !default;
$primaryColor2: #00233b !default;
$primaryColor3: #06aaee !default;

$whiteSemiTransparent: rgba(255, 255, 255, 0.7);
$searchMaskTransparent: rgba(0, 35, 59, 0.6);

$lighterGray: #f0f0f0;
$lightGray: #d7dfe4;
$midGray: #c3c3c3;
$darkGray: #737373;
$darkerGray: #3b3b3b;
$newSoftGrey: #e0e1e3;

$grayAA: #aaa;
$grayF7: #f7f7f7;
$grayEE: #eee;
$gray33: #333;
$grayDD: #ddd;
$gray2A: #2a2a2a;
$grayF2: #f2f2f2;
$grayF5: #f5f5f5;
$grayF0: #f0f0f0;
$grayF8: #f8f8f8;
$grayFD: #fdfdfd;
$gray66: #666;
$grayCC: #ccc;
$grayDA: #dadada;
$grey77: #777;
$gray9b: #9b9b9b;

$blue: #07496f;
$darkBlue: #164e78;
$darkBlue2: #20358c;
$indigo: #001477;
$paleBlue: #6d8fa9;
$lightBlue: #69d0fb;
$focusBlue: #66afe9;

$highLightBlue: #5897fb;
$acqa: #06aaee;
$darkAcqa: #006598;

$pink: #e01a59;
$pinkLight: #feebed;

$green: #32a52c;
$lightGreen: #84ba4d;

$discountColor: #ff4056;
$error: $pink;
$validatorError: #a94442;
$valid: green;

// Fonts
$fontColor: #333333;
$fontColorGray: #b1b3b4;

// Borders
$borderColor: #dadada;
$borderColorLight: #e4e4e4;
$borderColorLightGray: #cccccc;

// OBC
$newsletterOBC: #082844;

// footer
$footerBottomBar: $darkBlue;

// new search
$searchInfoBackground: #c2dcee;
$searchMultiSelectColor: #1c588a;

//
$titleA1: #a1a4a5;
$price05: #05233f;
$price91: #919495;

$btn-bg-color: #718796;
$dream-deal-pink: #9e1f62;
$purple: #931f63;

$form-control-color: #666;
$form-control-outline: #66afe9;

$sold-out-text: #016d9f;
$sold-out-background: #cce2ec;
$arrow-color: #888888;
