.cardBox {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 270px;
    min-height: 400px;

    color: white;

    > img {
        position: absolute;
        height: 60%;
        width: 100%;

        left: 0;
        top: 0;
    }

    // This is for the urgency label, consider making a general component to show labels everywhere
    .cardLabel {
        position: absolute;
        top: 1rem;
        right: 0;
        padding: 5px;

        background-color: red;
        font-size: 13px;
    }
    .content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: linear-gradient(to bottom, transparent 0%, $primaryColor2 30%);

        margin-top: 50%;
        padding: 30px 20px;
        text-align: center;

        z-index: 10;

        > h3 {
            margin: 10px 0;
            font-size: 20px;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
        }
        > span {
            font-size: 14px;
        }
        > p {
            display: -webkit-box;

            font-size: 12px;

            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            overflow: hidden;

            margin-top: auto;
        }
        .content-top{
            flex: 1;
            p{
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
}
