@media only screen and (min-width: $screen-lg) {
    .waypoints-container {
        display: flex;
        background-color: white;
        padding: 15px;

        > img,
        > div {
            width: 50% !important;
            height: 386px;
            flex: 1 1 auto;
        }
        > div {
            overflow-y: scroll;
            border: none !important;
            padding: 0 15px 0 26px !important;
        }
    }
    .waypoints-title {
        display: block;
    }
}
.waypoints-title {
    display: none;
}
.waypoints-container .has-routes-image {
    width: 100%;
}

.waypointsSection {
    @media screen and (min-width: $screen-desktop) {
        display: none;
    }
}
