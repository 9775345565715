.startPage {
    &--header {
        width: 100%;
        height: 200px;
        text-align: center;
        padding: 50px 5px;
        background: url('https://images.dreamlines.de/wp-content/uploads/20200116175645/background.jpg')
            center no-repeat;
        z-index: -1;
        background-size: cover;
        @media screen and (min-width: 768px) {
            margin-bottom: 30px;
            height: 400px;
        }
        @media screen and (min-width: 991px) and (max-width: 1200px) {
            height: 290px;
        }
        @media screen and (min-width: 991px) {
            background-position-y: 0px;
        }

        &-title {
            color: white;
            font-size: 25px;
            line-height: normal;
            font-weight: bold;
            display: block;
            @media screen and (min-width: 768px) {
                font-size: 35px;
            }
            @media screen and (min-width: 1280px) {
                font-size: 40px;
            }
        }
        &-subtitle {
            color: white;
            font-size: 19px;
            font-weight: normal;
            display: block;
            margin-top: 5px;
            line-height: normal;
            @media screen and (min-width: 768px) {
                font-size: 25px;
                margin-bottom: 45px;
            }
        }
        &-question {
            color: white;
            font-size: 19px;
            font-weight: bold;
            line-height: normal;
            display: block;
            margin-top: 25px;
            @media screen and (min-width: 768px) {
                font-size: 20px;
            }
            @media screen and (min-width: 1280px) {
                font-size: 22px;
            }
        }
    }
    &--decision-wrapper {
        height: 190px;
        display: flex;
        margin: -45px 5px 0px;

        @media screen and (min-width: 768px) {
            height: 235px;
            margin: -150px 5px 80px;
        }
        @media screen and (min-width: 991px) {
            height: 215px;
            margin: -75px 5px 60px;
        }
        @media screen and (min-width: 1280px) {
            margin: -140px 5px 40px;
        }
    }
    &--exclusive-wrapper {
        border: solid 1px #84ba4d;
        margin: 50px 15px;
        padding: 0px 5px;
        text-align: center;
        @media screen and (min-width: 768px) {
            max-width: 360px;
            margin: 0 auto 60px;
        }
        @media screen and (min-width: 1280) {
            margin-bottom: 30px;
        }
    }
    &--data-container {
        @media screen and (min-width: 768px) {
            max-width: 640px;
            margin: 0 auto;
        }
        .startPage--header & {
            @media screen and (min-width: 768px) {
                padding-top: 65px;
            }
            @media screen and (min-width: 991px) and (max-width: 1279px) {
                padding-top: 40px;
            }
        }
    }
    &--decision-item {
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
        width: 50vw;
        height: inherit;
        margin: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: center;

        &-text {
            font-size: 14px;
            line-height: 18px;
            margin: 5px 15px 0px;
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
        }
        > img {
            height: 47px;
            width: 40px;
            margin: 0;
            align-self: center;
            @media screen and (min-width: 768px) {
                width: 64px;
                height: auto;
                position: relative;
                top: -10px;
            }
            @media screen and (min-width: 992px) {
                top: -5px;
            }
        }
    }
}
