body {
    &.modal-open,
    &.search-filters-open {
        overflow: hidden;
    }
}

.teaserDescription {
    p {
        margin: 0px;
    }
}

.noJustifiedText {
    p {
        text-align: left !important;
    }
}

.responsive-overflow {
    &--multi-line {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-height: 20px;
        height: 60px;

        @media screen and (min-width: 768px) {
            height: 144px;
            -webkit-line-clamp: 7;
        }
    }
}

.overflow-ellipsis {
    &--single-line {
        white-space: nowrap;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
        -moz-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
    &--multi-line {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        line-height: 20px;
        height: 60px;
    }
}

.no-margin-on-children {
    * {
        margin: 0;
    }
}

.comp-loading {
    min-height: 100vh;
    min-width: 100vw;
    position: relative;
    &:before {
        content: '';
        @extend .loading-indicator;
        position: absolute;
        top: 25%;
    }
}

// Temporary-ish fix to hide all h2 sub elements
.h2-hidden {
    h2 {
        display: none;
    }
}

.comp-loaded {
    min-height: 0;
    min-width: 0;
}

.html-with-links {
    a {
        color: #06aaee;
        text-decoration: none;
    }
}

input {
    border-radius: 0;
}

.streetSectionWrapper,
.citySectionWrapper,
.birthdaySectionWrapper {
    display: block !important;
}

input::placeholder {
    color: $darkGray;
}

// Mapbox plugin
.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
    width: 40px !important;
    height: 40px !important;
}

.btn {
    border-radius: 0;
    font-size: 12px;
    font-weight: 400;
    &.btn-more {
        background: $primaryColor3;
        border: none;
        color: $white;
        padding: 4px 25px 5px 10px;
        position: relative;
        &:before {
            font-family: 'dreamlines';
            content: '\f109';
            position: absolute;
            right: 5px;
            transform: translateY(-50%);
            top: 50%;
        }
        &:hover {
            background-color: $primaryColor2;
        }
    }
}

.noPadding {
    padding-left: 0;
    padding-right: 0;
}
