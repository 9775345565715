$prefix: form-control;
[class^='#{$prefix}'] {
    &[class*='__input'] {
        height: 31px;
    }
    &[class*='__select'] {
        display: block;
        width: 100%;
        height: 31px;
        padding: 6px 12px;
        line-height: 1.4;
        color: $gray66;
        background-color: $white;
        background-image: none;
        border: 1px solid $grayCC;
        border-radius: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        appearance: menulist;
        font-size: 1.167rem;
    }
    &[class*='__textarea'],
    &[class*='__input'],
    &[class*='__select'] {
        &[class*='--disabled'] {
            background: $grayEE;
            opacity: 1;
            cursor: not-allowed;
            &:focus {
                outline: 0;
            }
        }
        &:not([class*='--disabled']):focus {
            border-color: $focusBlue;
            outline: 0;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
        }
        &[class*='--invalid'] {
            border: 1px solid $discountColor !important;
        }
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 6px 6px 6px 12px;
        background: $white;
        font-size: 1.167rem;
        line-height: 1.43;
        color: $gray66;
        border: 1px solid $grayCC;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
}

.#{$prefix}__cb-label {
    &__span {
        padding: 3px 12px 0 0;
        font-weight: 400;
        font-size: 12px;
        a {
            color: #06aaee;
            text-decoration: none;
        }
    }
}

.#{$prefix}__errors-holder {
    &__error {
        &:first-child {
            margin-top: 5px;
        }
        color: $validatorError;
        display: block;
    }
}
