.modalContainer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 15px;
    overflow: auto;
    z-index: 1000;
    text-align: center;
    &.mobilePositionBottom {
        @media (max-width: $screen-xs) {
            padding: 32px;
            transform: translateX(0px);
            padding: 0px;
        }
    }
    &.mobilePositionBottom > .modalContent {
        @media (max-width: $screen-xs) {
            padding: 20px 16px 36px 16px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            .modalClose {
                padding: 0;
                top: 16px;
                right: 16px;
            }
        }
    }
}

.modalOverlay {
    background: black;
    opacity: 0.5;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $zindex-modal-background;
}

.modalContent {
    z-index: $zindex-modal;
    background: $white;
    position: relative;
    padding: 15px;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.55);
    border-radius: 6px;

    @media (min-width: $screen-sm-min) {
        display: inline-block;
        top: 10%;
        margin: 0 auto;
    }

    .modalClose {
        border: none;
        background-color: transparent;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
