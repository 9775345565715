* {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
}
figure {
    margin: 0px;
}

ul {
    -webkit-padding-start: 0px;
    -moz-padding-start: 0px;
    padding-start: 0px;
    list-style-type: none;
    list-style: none;
}

li {
    list-style-type: none;
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    --webkit-appearance: none;
    &:focus,
    &:active {
        outline: none;
    }
}

p {
    margin: 10px 0;
}

p.MsoNormal {
    margin: 0 !important;
    @media (min-width: $screen-md-min) {
        margin: unset;
    }
}

.selectBoxWrapper {
    .selectBox {
        border: solid 1px #e0e0e0;
        background-color: #ffffff;

        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #333333;

        padding: 9px 35px 8px 13px;
    }
    &::after {
        font-family: dreamlines;
        display: inline-block;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        line-height: 1;
        text-decoration: inherit;
        text-rendering: optimizeLegibility;
        text-transform: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;

        content: '\f153';

        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);

        font-size: 14px;

        margin-left: -24px;
        margin-right: 14px;
    }
}

@media only screen and (min-width: 0px) and (max-width: $screen-xs-max) {
    .onlyTablet,
    .onlyTabletUp,
    .onlyDesktop {
        display: none !important;
    }
}

@media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
    .onlyMobile,
    .onlyDesktop {
        display: none !important;
    }
    .container {
        width: 100%;
    }
}

@media only screen and (min-width: $screen-lg) {
    .onlyMobile,
    .onlyTablet {
        display: none !important;
    }

    .hideDesktop {
        display: none !important;
    }

    #react-app {
        background-color: #f2f2f2;
    }
}

.blue_button {
    border: 0;
    margin: auto;
    color: $white;
    outline: none;
    display: block;
    font-size: 16px;
    cursor: pointer;
    max-width: 300px;
    background: $acqa;
    position: relative;
    text-decoration: none;
    padding: 13px 20px 11px 20px;
    svg {
        fill: $white;
    }
    &.darker_blue {
        background: $primaryColor1;
    }
    &.has_hover:hover {
        background: $primaryColor2;
    }
    &.w-100 {
        width: 100%;
    }
    &.with_arrow {
        padding: 13px 30px 11px 20px;
        svg {
            top: 50%;
            right: 7px;
            position: absolute;
            transform: translateY(-50%);
        }
    }
    &.minor {
        width: 150px;
        font-size: 12px;
        padding: 9px 10px;
        &.with_arrow {
            padding: 9px 25px;
            svg {
                transform: translateY(-50%) scale(0.75);
            }
        }
    }
    &.minimal {
        width: unset;
        font-size: 12px;
        &.with_arrow {
            padding: 7px 25px 5px 10px;
            svg {
                transform: translateY(-50%) scale(0.75);
            }
        }
    }
    &.bigger {
        max-width: unset;
        font-size: 18px;
    }
    &.inline-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            max-height: 18px;
            margin-right: 10px;
        }
    }
    &.link,
    &.link:hover {
        color: $white;
        text-decoration: none;
    }
}

.unstyled_button {
    border: 0;
    outline: 0;
    background: unset;
}
