.destination-overview-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;

    br {
        content: '';
        display: block;
        margin: 2px;
    }

    &--container {
        margin: 0px 15px;
        max-width: 1170px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .slider-item {
            max-width: 100%;
        }

        #offers {
            margin: 0 0 24px !important;

            & > div.slider > div {
                max-width: 100% !important;
                margin-bottom: 15px !important;
            }

            & > div.slider > div > a > div:nth-child(2) > div:nth-child(2) {
                b {
                    display: none;
                }
            }

            @media screen and (min-width: $screen-sm-min) {
                & > div.slider > div {
                    max-width: calc(100% - 30px);
                }
                & > div.slider > div:nth-child(n) > a > div:nth-child(2) > div:nth-child(2) {
                    min-height: 120px;
                }
            }
        }

        .breadcrumbs {
            @media screen and (min-width: $screen-sm-min) {
                //padding-left: 10px !important;
                border-top: 1px solid transparent !important;
            }
        }

        .collapsible {
            position: relative;
            margin: 10px 0 30px;
            font-size: 15px;
            line-height: 20px;

            @media screen and (min-width: $screen-sm-min) {
                margin: 30px 0 50px;
            }

            @media screen and (min-width: $screen-lg-min) {
                background: $white;
                padding: 15px;
                margin: 30px 0;
            }

            h2 {
                display: none;

                @media screen and (min-width: $screen-sm-min) {
                    display: block;
                    font-size: 18px;
                }

                @media screen and (min-width: 1024px) {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }

            #show,
            #hide {
                position: absolute;
                bottom: -1em;
                z-index: 100;
                text-align: center;
                color: $acqa;

                @media screen and (min-width: $screen-lg-min) {
                    display: none;
                }
            }
        }

        .furtherInfo {
            margin-bottom: 60px;
        }

        .recommendation-teaser {
            display: inline-flex;
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 6px;

            &:not(:first-child) {
                margin-top: 20px;
            }

            @media screen and (min-width: $screen-sm-min) {
                &:not(:first-child) {
                    margin-top: 0;
                }
                max-width: 354px;
                margin: 0 22px 22px 0;
                background: white;
            }
            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                &:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
            @media screen and (min-width: 1024px) {
                max-width: 314px;

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }

            @media screen and (min-width: $screen-lg-min) {
                max-width: 375px;
            }

            a {
                width: 100%;
                text-decoration: none;
                display: inline-flex;
                flex-direction: column;
                &:hover,
                &:focus,
                &:active {
                    color: #2a2a2a;
                }
                .recommendation-teaser--top {
                    position: relative;
                    padding-bottom: 66.67%;
                    @media screen and (min-width: $screen-sm-min) {
                        padding-bottom: 44.67%;
                    }
                    @media screen and (min-width: $screen-md-min) {
                        padding-bottom: 50.67%;
                    }
                    @media screen and (min-width: $screen-lg-min) {
                        padding-bottom: 48.67%;
                    }
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        @media screen and (min-width: $screen-sm-min) {
                            height: 157px;
                        }
                        @media screen and (min-width: $screen-lg-min) {
                            height: 180px;
                        }
                    }
                    div {
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        margin: 0;
                        text-align: center;
                        padding: 10px 6px;
                        font-size: 19px;
                        font-weight: bold;
                        color: #ffffff;
                        text-shadow: 0 0 4px #2a2a2a;
                        background: linear-gradient(
                            to bottom,
                            rgba(8, 29, 46, 0),
                            rgba(1, 47, 74, 0.71)
                        );
                    }
                }

                .recommendation-teaser--bottom {
                    padding: 10px 10px 5px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    flex: 1 0 auto;

                    @media screen and (min-width: $screen-sm-min) {
                        flex-direction: column;
                        padding: 0 5px;
                    }

                    &__description {
                        border-right: 1px solid #e0e0e0;
                        display: flex;
                        flex-direction: column;
                        flex: 1 1 auto;
                        width: 100%;
                        min-height: 48px;
                        justify-content: center;

                        b {
                            display: none;
                        }

                        @media screen and (min-width: $screen-sm-min) {
                            flex: 1 0 auto;
                            justify-content: start;
                            border-right: none;
                            border-bottom: 1px solid #f3f3f3;
                            padding-bottom: 10px;
                            padding-top: 10px;
                        }
                        & > div {
                            padding-right: 5px;
                            font-size: 15px;
                            @media screen and (min-width: $screen-sm-min) {
                                font-size: 14px;
                                padding: 5px 0 0 0;
                                margin: 0 5px;
                            }
                        }
                    }
                    &__price {
                        display: flex;
                        flex: 0 1 auto;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        font-size: 14px;
                        line-height: 1;

                        .price-text {
                            padding: 0 10px;
                        }
                        .priceDiscountTag {
                            padding-right: 5px;
                        }

                        @media screen and (min-width: $screen-sm-min) {
                            padding: 0 10px;
                            .price-with-discount-container {
                                display: flex;
                                flex: 0 1 auto;
                                flex-direction: row-reverse;
                                .priceDiscountTag {
                                    display: inline-flex;
                                    position: absolute !important;
                                    right: 1px !important;
                                    padding: 0;
                                }
                                .price-text {
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        span.recommendation-teaser {
            background: transparent;
            @media screen and (min-width: $screen-sm-min) {
                width: 354px;
            }
            @media screen and (min-width: 1024px) {
                width: 314px;
            }
            @media screen and (min-width: $screen-lg-min) {
                width: 375px;
            }
        }
    }
    &--header {
        position: relative;
        display: flex;
        color: $white;
        text-align: center;
        padding: 10px;
        font-size: 15px;
        width: 100%;
        order: 1;
        margin-bottom: 20px;

        @media screen and (max-width: 1279px) {
            background: $blue !important;
        }
        @media screen and (min-width: $screen-lg-min) {
            padding-left: 0;
            background-position: center;
            background-size: cover;
            min-height: 180px;
            margin-bottom: 0;
        }
    }
    &--header-background {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @media only screen and (min-width: $screen-lg-min) {
            display: block;
            background-color: rgba(34, 75, 122, 0.35);
        }
    }
    &--header-data {
        max-width: 600px;
        margin: 0 auto;
        position: relative;

        @media only screen and (min-width: $screen-lg-min) {
            z-index: 1;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
        h1 {
            margin-top: 5px;
            font-size: 25px;
            @media screen and (min-width: $screen-sm-min) {
                font-weight: bold;
            }
        }
    }
    &--teasers-wrapper {
        @media screen and (min-width: $screen-sm-min) {
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
        }
    }

    &--sortbar {
        display: flex;
        flex-wrap: wrap;
        font-size: 1.166rem;
        margin-bottom: 10px;

        &--filters {
            flex: 0 50%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    cursor: pointer;
                    float: left;
                    margin-left: 2px;
                    margin-right: 11px;
                    padding-right: 10px;
                    position: relative;
                    width: auto;
                    border-right: 1px solid #000;
                }

                li:last-child {
                    border-right: 0;
                }
            }

            .active {
                font-weight: 700;
            }
        }

        &--dropdown {
            flex: 0 50%;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &--label {
                padding-right: 10px;
            }

            & > div {
                flex-wrap: wrap;
            }

            //Dropdown Element
            & > div:nth-child(2) {
                margin-bottom: 0 !important;
                background-color: #fff;

                @media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
                    margin-right: 3px !important;
                }
            }
        }
    }
}
