.recommendation-list {
    padding: 0;
    margin: 15px auto;
    @media only screen and (max-width: 768px) {
        margin-top: 0;
    }

    &__title {
        padding: 15px;
        text-align: center;
        font-size: 25px;
        color: #333;
        font-weight: bold;
        @media only screen and (max-width: 768px) {
            padding-top: 0;
            margin-top: 0;
            text-align: left;
        }
    }

    &__wrap {
        flex-wrap: wrap;
    }

    &__link {
        height: 25px;
        color: #006295;
        font-size: 13px;
        font-weight: bold;
    }

    &__block {
        padding: 5px 20px;
        width: 25%;
        @media only screen and (max-width: 768px) {
            width: 100% !important;
            text-align: left;
        }
        @media only screen and (min-width: 769px) and (max-width: 1024px) {
            width: 50%;
            text-align: center;
        }
    }

    &__container {
        background-color: white;
        padding-top: 10px;
        display: flex;
    }

    &__container div:first-child:nth-last-child(1),
    div:first-child:nth-last-child(1) ~ div {
        width: 100%;
        text-align: left;
    }

    &__container div:first-child:nth-last-child(2),
    &__container div:first-child:nth-last-child(2) ~ div {
        width: 50%;
        text-align: center;
    }

    &__container div:first-child:nth-last-child(3),
    &__container div:first-child:nth-last-child(3) ~ div {
        width: 33%;
        text-align: center;
    }
}

.ml-2 {
    margin-left: 5px;
}

.mb-5 {
    margin-bottom: 0px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 0;
    }
}
