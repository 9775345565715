.mapboxContainer {
    height: 500px;
    width: 100%;
    .mapboxNavigation {
        position: absolute;
        right: 10px;
        bottom: 44px;
    }

    .mapMarker {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        position: absolute;
        left: -10px;
        top: -15px;
        .icon {
            position: relative;
            align-self: center;
        }
    }

    .mapboxgl-marker {
        min-width: 30px;
        min-height: 39px;
        margin-top: -39px;
        margin-left: -16px;
        cursor: pointer !important;
        background: url(#{$baseImagePath}/common/markers/marker-number.png)
            no-repeat center transparent;
        &.active {
            z-index: 1;
            background: url(#{$baseImagePath}/common/markers/marker-number-active.png)
                no-repeat center transparent;
        }
        .marker-html_pin {
            font-size: 12px;
            padding-top: 7px;
            font-weight: 600;
            text-align: center;
        }
    }

    .map-popUp {
        z-index: 1;
        max-width: 250px;
        * {
            margin: 0;
            font-size: 14px;
        }
        h3 {
            font-weight: 600;
            font-style: italic;
        }
        .mapboxgl-popup-content {
            padding: 15px;
            .map-popUp__content {
                overflow-y: auto;
                max-height: 170px;
                > div {
                    max-height: 170px;
                }
            }
            .map-popUp__image {
                float: right;
                max-width: 125px;
            }
        }
    }
}

.ship-marker {
    display: flex;
    flex-direction: row;
    width: 300px;
    font-size: 14px;

    svg {
        stroke-width: 0;
        stroke: white;
        fill: #07496f;
    }
    &__anchor-icon {
        font-size: 20px;
        margin-right: 10px;
    }
    &__next-port {
        display: flex;
    }

    &__current-port {
        margin-bottom: 7px;
        font-weight: bold;
    }
    &__box {
        margin-left: 15px;
        background-color: #00233bcc;
        color: $white;
        cursor: pointer;
        display: inline-block;
        min-width: 135px;
        padding: 10px;
        position: relative;
        width: 100%;
        bottom: 17px;
        &:before {
            border-right: 11px solid #00233bcc;
            border-bottom: 16px solid transparent;
            border-top: 16px solid transparent;
            margin-left: -21px;
            content: '';
            position: absolute;
        }
    }
}

.ship-position-page-marker {
    display: flex;
    flex-direction: row;
    width: 300px;
    font-size: 14px;

    svg {
        stroke-width: 0;
        stroke: #07496f;
        fill: #07496f;
        margin-top: -13px;
    }
    &__anchor-icon {
        font-size: 20px;
        margin-right: 10px;
    }
    &__next-port {
        display: flex;
    }

    &__current-port {
        margin-bottom: 4px;
        margin-top: 5px;
        font-weight: bold;
    }
    &__box {
        margin-left: 15px;
        background: $white;
        color: $black;
        cursor: pointer;
        display: inline-block;
        min-width: 135px;
        padding: 10px;
        position: relative;
        width: 100%;
        bottom: 17px;
        &:before {
            content: '';
            position: absolute;
            border-right: 11px solid $white;
            border-bottom: 24px solid transparent;
            border-top: 24px solid transparent;
            margin-left: -21px;
            margin-top: -10px;
        }
    }
}
