.inclusivesContainer {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $screen-md-min) {
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: center;
    }

    @media screen and (min-width: $screen-desktop) {
        display: none;
    }
}

.inclusivesIcon {
    display: flex;
    align-items: center;

    padding: 0 5px;

    flex: 1 1 50%;
    @media (min-width: $screen-md-min) {
        flex: 0 0 auto;
        padding: 0 30px;
    }
    svg {
        fill: $lightGreen;
        display: inline-flex;
        width: 28;
        height: 28;
        margin: 3px 0;
    }

    .iconText {
        font-size: 13px;
        margin: 0 4px;
    }

    @media (min-width: $screen-md-min) {
        flex-direction: column;
    }
}
