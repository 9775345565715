.ship-live-position {
    @media only screen and (min-width: $screen-lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &--edgeMargin {
        margin: 0px 15px;
    }

    &--container {
        margin: 0px 15px;
        max-width: 1170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;

        @media screen and (min-width: $screen-sm-min) {
            order: 3;
        }

        #offers {
            margin: 0 !important;
        }

        .mapboxContainer {
            width: 100vw;
            position: relative;
            left: 50%;
            right: 50%;
            margin-left: -50vw;
            margin-right: -50vw;
        }

        .collapsible {
            position: relative;
            margin: 10px 0 30px;
            font-size: 15px;
            line-height: 20px;

            @media screen and (min-width: $screen-sm-min) {
                margin: 30px 0 50px;
            }

            @media screen and (min-width: $screen-lg-min) {
                background: $white;
                padding: 15px;
                margin: 30px 0;
            }

            h2 {
                display: none;

                @media screen and (min-width: $screen-sm-min) {
                    display: block;
                    font-size: 18px;
                }

                @media screen and (min-width: 1024px) {
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }

            #show,
            #hide {
                position: absolute;
                bottom: -1em;
                z-index: 100;
                text-align: center;
                color: $acqa;

                @media screen and (min-width: $screen-lg-min) {
                    display: none;
                }
            }
        }

        .shipPositionCard {
            margin-top: 15px !important;
        }

        &--information {
            margin-top: 20px;
            margin-left: 10px;
            margin-right: 10px;
            padding: 15px;
            background-color: white;

            h3 {
                margin-top: 5px !important;
            }
        }

        .sh .loadmore {
            display: flex;
            justify-content: center;
            margin-top: 10px;

            a {
                background: rgb(255, 255, 255);
                color: rgb(0, 101, 152);
                min-width: 140px;
                text-align: center;
                padding: 15px 25px 15px 25px;
                border-radius: 5px;
                font-size: 20px;
                cursor: pointer;
                border: 2px solid rgb(0, 101, 152);
                text-decoration: none;
            }
        }

        .collapsible-text {
            h2 {
                font-size: 1.1rem !important;
            }
        }
        .further-info {
            background-color: $white;
            padding: 15px;
            margin-top: 30px;
            margin-left: 12px;
            margin-right: 12px;
            margin-bottom: 30px;

            @media only screen and (min-width: $screen-sm) and (max-width: $screen-md-max) {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    &--header {
        color: white;
        text-align: center;
        padding: 15px 15px 20px;
        font-size: 15px;
        position: relative;
        width: 100%;

        @media only screen and (min-width: 768px) {
            padding: 21px 15px;
        }
        @media only screen and (max-width: 1279px) {
            background: #07496f !important; //override the inline style of desktop
        }
        @media only screen and (min-width: 1280px) {
            background-repeat: no-repeat;
            height: 177px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-size: cover;
        }
        &-overlay {
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(34, 75, 122, 0.35);

            @media only screen and (min-width: 1280px) {
                display: block;
                background-color: rgba(11, 78, 128, 0.35);
            }
        }
        &-icon {
            background-repeat: no-repeat;
            display: none;
            width: 50px;
            height: 120px;
            opacity: 0.57;

            @media only screen and (min-width: 1280px) {
                display: inline-block;
            }
        }
        &-data {
            @media only screen and (min-width: 1280px) {
                z-index: 1;
                display: flex;
                align-items: center;
                text-align: left;
                margin-left: -55px;
            }
        }
        h2 {
            font-size: 24px;
            margin: 0px 0px 15px 0px;
            @media only screen and (min-width: 768px) and (max-width: 1279px) {
                margin-bottom: 8px;
            }
        }
        span {
            @media only screen and (min-width: $screen-lg) {
                position: relative;
                top: -15px;
            }
        }
    }
}
