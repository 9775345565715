.review {
    position: relative;
    margin-bottom: 15px;
    &__wrapper {
        @media only screen and (min-width: $screen-lg) {
            display: flex;
        }
    }
    &__mobile-header {
        display: flex;
        position: relative;
        height: 60px;
        background-color: $lightGray;
        color: $primaryColor2;
        @media only screen and (min-width: $screen-lg) {
            display: none;
        }
    }
    &__mobile-header-arrow {
        margin-top: 5px;
        margin-left: 10px;
    }
    &__desktop-header {
        display: none;
        flex: 0 0 150px;
        background-color: $lightGray;
        position: relative;
        text-align: center;
        padding: 15px 5px;
        @media only screen and (min-width: $screen-lg) {
            display: block;
        }
        &:after {
            border-bottom: 15px solid transparent;
            border-left: 15px solid $lightGray;
            border-top: 15px solid transparent;
            content: '';
            margin-top: -6px;
            position: absolute;
            right: -15px;
            bottom: 0px;
            top: 0px;
            height: 15px;
            width: 15px;
            margin-top: auto;
            margin-bottom: auto;
        }
    }
    &__desktop-header-icon {
        color: $primaryColor2;
        font-size: 35px;
        padding-top: 15px;
        padding-bottom: 10px;
    }
    &__desktop-header-name {
        font-weight: bold;
    }
    &__desktop-header-rating {
        padding: 5px 0px;
    }
    &__desktop-header-date {
        font-size: $fontSize11;
        font-weight: bold;
    }
    &__body {
        display: none;
        border: 1px solid $gray;
        padding-top: 25px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        position: relative;
        @media only screen and (min-width: $screen-lg) {
            display: block;
            border-left: none;
        }
        &:before {
            border-left: 20px solid transparent;
            border-top: 20px solid $lightGray;
            border-right: 20px solid transparent;
            width: 20px;
            content: '';
            margin-top: -6px;
            position: absolute;
            right: 0px;
            left: 0px;
            margin-left: auto;
            margin-right: auto;
            top: 0px;
        }
        @media only screen and (min-width: $screen-lg) {
            padding-left: 25px;
            flex: 1 1 0%;
            min-width: 0;
            &:before {
                content: none;
            }
        }
    }
    &__cruise-and-tags {
        display: none;
        @media only screen and (min-width: $screen-lg) {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
        }
    }
    &__header-left {
        flex: 1;
        display: flex;
        justify-content: center;
        padding-left: 20px;
        flex-direction: column;
    }
    &__age {
        margin-left: 5px;
        font-weight: bold;
    }
    &__user-icon {
        color: $primaryColor2;
    }
    &__score {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        margin-right: 23px;
        &--with-comments {
            margin-right: 0px;
        }
    }
    &__cruise {
        color: $primaryColor2;
        font-weight: bold;
        font-size: $fontSize16;
        text-align: center;
        margin-bottom: 10px;
    }
    &__desktop-only {
        display: none;
        @media only screen and (min-width: $screen-lg) {
            display: block;
        }
    }
    &__separator {
        margin-top: 7px;
        margin-bottom: 7px;
    }
}

.desktopReviews {
    background-color: white;
    padding: 10px;
}
