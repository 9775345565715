.furtherInfo {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
    line-height: 20px;
    font-size: 15px;

    @media screen and (min-width: $screen-md) {
        flex-direction: column;
        background: white;
    }
    .furtherInfoDesktop {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        div {
            flex: 1 0 auto;
        }
        img {
            display: none;
            padding: 10px;
            flex: 0 1 auto;
            @media screen and (min-width: $screen-md) {
                display: block;
            }
        }
    }

    .hideOnDesktop {
        @media screen and (min-width: $screen-md) {
            display: none;
        }
    }

    .toggle {
        font-size: 14px;
        color: #06aaee;
        display: none;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
        cursor: pointer;

        @media screen and (min-width: $screen-md) {
            display: flex;
        }

        svg {
            fill: #06aaee;
            margin-left: 6px;
        }
        .rotate {
            transform-box: fill-box;
            transform-origin: center;
            transform: rotate(180deg);
        }
    }
    // This is to modify html sent by content managers
    .furtherInfoDesktop {
        a {
            text-decoration: underline;
            color: $acqa;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 1.1em;
            margin-bottom: 0;
        }
    }

    li {
        margin-bottom: 8px;
        list-style-position: inside;

        &:before {
            content: '•';
            padding-right: 12px;
        }
    }
}

.furtherInfoSectionContent {
    clear: both;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
}

.furtherInfoSectionContent h2,
h3,
h4,
h5 {
    font-weight: bold;
}
