@import '../../../assets/styles/form-control.scss';
$gap: 15px;
.rf-personal-info {
    width: 100%;
    box-sizing: border-box;
    padding: 0 $gap $gap $gap;
    &__header {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 0 10px 10px;
        box-sizing: border-box;
        background: $primaryColor1;
        &__title {
            color: $white;
            font-size: 1.333rem;
            font-weight: 400;
        }
    }
    &__form {
        &__input__ccp {
            display: flex;
            &__prefix {
                display: flex;
                align-items: center;
                font-size: 18px;
                border: 1px solid $grayCC;
                color: $form-control-color;
                padding: 0 8px;
                border-right: none;
                background-color: $grayDD;
            }
            &__country-code {
                max-width: 120px;
                min-width: 100px;
                margin-right: $gap;
                display: flex;
            }
            &__phone {
                flex: 1;
            }
        }

        &__cb-newsletter {
            margin-top: $gap;
            display: flex;
            width: 100%;

            &__cb-holder {
                margin: 0 $gap;
            }
            &__lbl {
                flex: 1;
                margin: 0 $gap;
            }
        }

        &__cb-visa {
            margin-top: $gap;
            display: flex;
            width: 100%;

            &__lbl {
                flex: 1;
                margin: 0 5px;
                &__span {
                    font-size: 1.166rem;
                    font-weight: 700;
                }
            }
        }
    }
}
.form-table {
    width: 100%;
    display: table;
    padding: 0 $gap;
}

$formRow: form-row;
.#{$formRow} {
    display: table-row;

    &__cell {
        display: table-cell;
        &:last-child {
            width: 100%;
        }

        &__label-holder {
            display: flex;
            height: 100%;
            align-items: center;
            align-content: center;
            justify-content: flex-start;
            margin-right: $gap;
            &__label {
                font-size: 1.166rem;
                font-weight: 700;
                white-space: nowrap;
                margin: 0;
            }
        }

        &__control-holder {
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;

            &__control {
                width: 100%;
                height: 100%;
            }
        }
    }

    &:not(:first-child) {
        .#{$formRow}__cell__control-holder {
            margin-top: $gap;
        }
    }
}
