.reviewContainer {
    h2 {
        font-size: 22px;
        margin-top: 10px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    h4 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
    }

    @media only screen and (min-width: $screen-md) {
        background: white;
        padding: 10px;
    }

    .reviewList {
        margin-top: 30px;
        position: relative;

        h4 {
            flex: 1;
            font-weight: bold;
            font-size: 18;
            margin-bottom: 15;
        }
        .sortingRow {
            display: flex;
            align-items: center;
            > span {
                margin-right: 10px;
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 10px; // Aligning with the dropdown defaults
            }
        }
    }

    .reviewCommentContainer {
        display: flex;
        margin: 10px 0;
        svg {
            fill: black;
            flex: 0 0 auto;
            width: 20;
            height: 20;
        }
        .reviewComment {
            margin-left: 10px;
        }
    }

    .collapsibleSection {
        background: transparent;
        color: black;
        border-bottom: 0;
        padding: 3px 0 0;
        margin-bottom: 10px;
    }
    .collapsibleSectionHeader {
        justify-content: center;
        .titleSection {
            flex: 0 1 auto;
            color: $acqa;
            .collapsibleSectionTitle {
                font-size: 16px;
            }
            .collapsibleSectionInfo {
                justify-content: center;
            }
        }
        .collapsibleSectionIcon {
            fill: $acqa;
            width: 26;
            height: 20;
            padding: 0 5px;
        }
    }

    .collapsibleSectionContent {
        > div {
            margin: 0;
        }
    }
}

.summaryReview {
    display: flex;
    align-items: center;

    .ratingBoxWithArrow {
        position: relative;
        padding: 10px;
        margin-right: 20px;

        background-color: $darkAcqa;
        color: white;
        white-space: nowrap;
        z-index: 1;

        .ratingBoxArrow {
            position: absolute;
            left: 100%;
            width: 15px;
            height: 15px;
            top: 50%;
            margin-left: -8px;
            margin-top: -8px;
            transform: rotate(45deg);
            background: $darkAcqa;

            z-index: -1;
        }
    }

    .ratingInfo {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        > div {
            font-weight: bold;
            font-size: 23px;
        }
        > span {
            font-size: 15px;
        }
    }
    .labelsSection {
        display: none;
        flex-direction: column;
        flex: 0 1 auto;
        padding: 0 10px;

        font-size: 12px;

        .labels {
            display: flex;
            margin-top: 5px;

            > * {
                margin: 0 5px 5px 0;
            }
        }

        @media only screen and (min-width: $screen-md) {
            display: flex;
        }
    }
}
.ratingBarContainer {
    margin-bottom: 5px;
    font-weight: bold;

    svg {
        width: 16;
        height: 16;
        margin-left: 5px;
    }
    span {
        display: inline-flex;
        margin: 0 5px 0 0;
        align-items: center;
    }

    .ratingBar {
        background-color: $midGray;
        height: 15px;
        .ratingBarFilled {
            height: 100%;
            background-color: $darkAcqa;
        }
    }

    .ratingBarInfo {
        display: flex;
        font-size: 15px;
        justify-content: space-between;
        align-items: center;
    }
    .inlineRatingBar {
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        font-size: 12px;

        .barlabel {
            flex: 0 0 150px;
            font-size: 14px;
        }
        .barCaption {
            flex: 0 0 55px;
            justify-content: flex-end;
        }
        .ratingBar {
            flex: 1 0 auto;
        }
    }
}

.ratingSummary {
    display: flex;
    padding: 20px 0;

    > div {
        flex: 1 0 auto;
    }
    > div:nth-child(1) {
        margin-right: 10px;
    }
}

.reviewListToggle {
    font-size: 14px;
    color: #06aaee;
    display: none;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    cursor: pointer;

    @media screen and (min-width: $screen-md) {
        display: flex;
    }

    svg {
        fill: #06aaee;
        margin-left: 6px;
    }
    .rotate {
        transform-box: fill-box;
        transform-origin: center;
        transform: rotate(180deg);
    }
}

.ratingBox {
    color: $darkAcqa;
    font-size: 26px;

    z-index: 2;
    .ratingBoxScore {
        font-weight: bold;
    }
}
.ratingBoxWhite {
    color: white;
    font-size: 30px;
}
