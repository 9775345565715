#promotion-banner {
    background-color: #6fb554;
    width: 100%;

    &.cruiseaway {
        background-color: #f49b0b;
    }

    .container {
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
        align-items: center;
        justify-content: center;
    }
    .headline {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2em;
        color: #fff;
        font-weight: 700;
        @media screen and (min-width: 768px) {
            font-size: 1.7em;
        }
    }
    .subHeadline {
        display: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.2em;
        color: #fff;
        font-weight: 600;
        margin-top: 4px;

        &::before {
            content: '–';
            padding-left: 5px;
            padding-right: 5px;
        }

        @media screen and (min-width: 768px) {
            display: block;
        }
    }
    .link {
        margin-top: 4px;
        a {
            font-family: Arial, Helvetica, sans-serif;
            margin-left: 10px;
            font-size: 0.9em;
            color: #006295;
            text-decoration: underline;
            font-style: italic;
            font-weight: 600;

            @media screen and (min-width: 768px) {
                font-size: 1.2em;
            }
        }
    }
}
