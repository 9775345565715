.ships-view {
    font-size: 1.166rem;
    line-height: 1.66rem;

    &__img {
        float: right;
        margin: 0 0 15px 15px;
        max-width: 330px;
        height: auto;
        display: block;
        border-radius: 6px;
    }

    &__facts {
        ul {
            -webkit-padding-start: revert;
        }
        li {
            list-style: disc;
        }
    }
}
